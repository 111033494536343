import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { extractIdAndScreenName } from "../../services/helperService";

const NotFound = () => {
  useEffect(() => {
    window.location.href = extractIdAndScreenName();
  }, []);

  return (
    <div className="loader-center">
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default NotFound;
