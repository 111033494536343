import react, { useEffect, useState } from "react";
import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";

const SocialSecurity = ({ submit, validated }) => {
  const {
    plannerData,
    onChangeHandler,
    handleOnchangeCheckbox,
    handleBlur,
    handleKeyPress,
    handleFocus,
  } = usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);
  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="socialSecurity">
        <Form
          name="SocialSecurity"
          noValidate
          validated={validated}
          onSubmit={submitFormdata}
          className="employee-form"
        >
          <div className="text-center">
            <div className="head-title">SOCIAL SECURITY</div>
          </div>

          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <div className="mx-2 ss-recieve-currrent-text">
                  Are you currently receiving Social Security payments?{" "}
                </div>
                <div className="mx-2 d-flex">
                  <div className="mx-2 ss-recieve-currrent-text">
                    <input
                      id="currentSocialPaymentYes"
                      type="radio"
                      name="s8s2_currently_receiving_social_security"
                      className={`mt-3`}
                      checked={
                        formData.s8s2_currently_receiving_social_security ==
                        "yes"
                      }
                      onChange={(event) => {
                        handleOnchangeCheckbox(event, "yes", setformData);
                      }}
                    />{" "}
                    <label htmlFor="currentSocialPaymentYes">Yes</label>
                  </div>
                  <div className="mx-2 ss-recieve-currrent-text">
                    <input
                      id="currentSocialPaymentNo"
                      type="radio"
                      name="s8s2_currently_receiving_social_security"
                      className={`mt-3`}
                      checked={
                        formData.s8s2_currently_receiving_social_security ==
                        "no"
                      }
                      onChange={(event) => {
                        handleOnchangeCheckbox(event, "no", setformData);
                      }}
                    />{" "}
                    <label htmlFor="currentSocialPaymentNo">No</label>
                  </div>
                </div>
              </div>

              {formData.s8s2_currently_receiving_social_security == "no" && (
                <div className="d-flex align-items-center">
                  <div className="mx-2 ss-recieve-currrent-text">
                    Will you receive Social Security payments?
                  </div>
                  <div className="mx-2 d-flex">
                    <div className="mx-2 ss-recieve-currrent-text">
                      <input
                        id="securityPaymentYes"
                        type="radio"
                        name="s8_will_receive_social_sec_payment"
                        className={`mt-3`}
                        checked={
                          formData.s8_will_receive_social_sec_payment == "yes"
                        }
                        onChange={(event) => {
                          handleOnchangeCheckbox(event, "yes", setformData);
                        }}
                      />{" "}
                      <label htmlFor="securityPaymentYes">Yes</label>
                    </div>
                    <div className="mx-2 ss-recieve-currrent-text">
                      <input
                        id="securityPaymentNo"
                        type="radio"
                        name="s8_will_receive_social_sec_payment"
                        className={`mt-3`}
                        checked={
                          formData.s8_will_receive_social_sec_payment == "no"
                        }
                        onChange={(event) => {
                          handleOnchangeCheckbox(event, "no", setformData);
                        }}
                      />{" "}
                      <label htmlFor="securityPaymentNo">No</label>
                    </div>
                  </div>
                </div>
              )}

              {(formData.s8_will_receive_social_sec_payment != "no" ||
                formData.s8s2_currently_receiving_social_security == "yes") && (
                <div className="mt-2">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Income Amount"
                    className="mb-2"
                  >
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      name="s8s2_income_amount"
                      className="tsp-2-textbox required-input"
                      placeholder="Income Amount"
                      value={formData.s8s2_income_amount}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      isInvalid={validated && !formData.s8s2_income_amount}
                      required
                    />
                  </FloatingLabel>
                </div>
              )}

              {formData.s8_will_receive_social_sec_payment == "yes" &&
                formData.s8s2_currently_receiving_social_security == "no" && (
                  <>
                    <div className="mt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Age To Begin"
                        className="mb-2"
                      >
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          name="s8s2_age_to_begin"
                          className="tsp-2-textbox required-input"
                          onKeyPress={(e) => handleKeyPress(e)}
                          placeholder="Age To Begin"
                          value={formData.s8s2_age_to_begin}
                          onChange={(event) =>
                            onChangeHandler(event, setformData)
                          }
                          isInvalid={validated && !formData.s8s2_age_to_begin}
                          required
                        />
                      </FloatingLabel>
                    </div>

                    <div className="mt-2">
                      <div>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Social Security amount verified by:"
                          className="mb-2"
                        >
                          <Form.Control
                            autoComplete="off"
                            required
                            as="select"
                            name="s8s2_ss_amount_verified"
                            value={formData.s8s2_ss_amount_verified}
                            className={"required-input pt-3"}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                            isInvalid={
                              validated && !formData.s8s2_ss_amount_verified
                            }
                          >
                            <option value=""></option>
                            <option value="Recent Statement">
                              Recent Statement
                            </option>
                            <option value="SSA.gov">SSA.gov</option>
                            <option value="Estimate">Estimate</option>
                          </Form.Control>
                        </FloatingLabel>
                      </div>
                    </div>
                  </>
                )}
            </Col>
          </Row>
          <Row>
            <div>
              <div className="ss-section-heading">SOCIAL SECURITY BENEFIT</div>
            </div>
            <Col md={6}>
              <div>
                <div className="section-head">
                  FEDERAL EMPLOYEES WHO ARE COVERED
                </div>
                <div className="sub-title">
                  <p>
                    All federal employees newly hired and after December 31,
                    1983, are covered by Social Security and will pay Social
                    Security taxes. This also includes employees with previous
                    federal service (other than rehired annuitants) if their
                    break in service was a year or longer.
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <div className="section-head">COVERAGE BY SOCIAL SECURITY</div>
                <div className="sub-title">
                  <p>
                    The old-age, survivors, and disability insurance program is
                    a federal program designed to protect the worker and his
                    family against economic insecurity when the family's
                    earnings are stopped or reduced because of the worker's
                    disability, death, or retirement. The program is financed
                    through special taxes paid by workers, their employers, and
                    self-employed people, rather than through general taxation.
                    These taxes are based on earnings. Old-age, survivors, and
                    disability insurance benefits are in turn based on the
                    worker's average earnings under Social Security. Paid
                    monthly, any one of the three kinds of benefits provided
                    under the program means a significant and regular
                    replacement of lost family income. As a general rule, the
                    amount of Social Security benefits you'll qualify to receive
                    are based on a combination of your earnings which have been
                    subject to Social Security tax and whether you have earned
                    enough "credits" in you Social Security account. We
                    recommend that you check on your Social Security every 3
                    years. If there is an error you only have 3 years and 3
                    months to get it corrected.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <div className="section-head">FULLY INSURED</div>
                <div className="sub-title">
                  <p>
                    Being fully insured is a status achieved after a worker
                    accumulates a specific number of "quarters of coverage under
                    the Social Security system. You will need 40 quarters to be
                    fully insured for life. Therefore, an individual employed
                    for at least 10 years in jobs covered by Social Security can
                    normally assume that they are fully insured.
                  </p>
                </div>
              </div>
              <div>
                <div className="section-head">FULL BENEFITS</div>
                <div className="sub-title">
                  <p>
                    In Social Security language, an individual's full (100%)
                    old-age entitlement is referred to as their "Primary
                    Insurance account (PIA)". As a general rule, if you wait
                    until reaching the age of 65 before applying for old-age
                    benefits, the amount you receive is based on your full PIA
                    entitlement. Note that the age at which full retirement
                    benefits are payable will be gradually increased from age 65
                    to age 67 over the coming years. This will be accomplished
                    by increasing the age for full benefits by two months per
                    year for workers who will reach the age of 62 in the year
                    2000. So if you were born in 1938 or after you may need to
                    be 67 to receive full Social Security.
                  </p>
                </div>
              </div>
              <div>
                <div className="section-head">BASICS</div>
                <div>
                  <ul
                    style={{
                      fontSize: "14px",

                      listStyleType: "disc",
                      marginBottom: "15px",
                    }}
                  >
                    <li>
                      If you don't have a recent SS statement, you should get
                      one.
                    </li>
                    <li>
                      Refer to the contact information below for a current
                      statement of benefits or visit your local office.
                    </li>
                    <li>Know your AGE 62 BENEFIT amount.</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div>
              <div className="ss-section-heading2">
                QUOTE FROM A SOCIAL SECURITY STATEMENT
              </div>
            </div>
            <div className="ss-about-social-section">
              <div className="ss-about-title">
                ABOUT SOCIAL SECURITY'S FUTURE...
              </div>
              <div className="sub-title">
                <p>
                  Social Security is a contract between generations. Since 1935,
                  America has kept the promise of security for its workers and
                  their families. Now, however, the Social Security system is
                  facing serious financial problems, and action is needed soon
                  to make sure the system will be sound when today's younger
                  workers are ready for retirement. Without changes, in 2033 the
                  Social Security Trust Fund will be able to pay only about 75
                  cents for each dollar of scheduled benefits.* We need to
                  resolve these issues soon to make sure Social Security
                  continues to provide a foundation of protection for future
                  generations.
                </p>
              </div>
              <ul
                style={{
                  fontSize: "14px",
                  fontStyle: "italic",
                  listStyleType: "disc",
                  marginTop: "15px",
                }}
              >
                <li>
                  These estimates are based on the Intermediate assumptions from
                  the Social Security Trustees Annual Report to the Congress.
                </li>
              </ul>
            </div>
            <div className="ss-Access-gov mt-3 mb-2">
              <p className="ss-Access-gov-text">
                Access{" "}
                <a
                  style={{ textDecoration: "none", color: "rgb(51, 122, 183)" }}
                  href=""
                >
                  SSA.gov
                </a>{" "}
                and setup an account for current estimates<br></br> FOR CURRENT
                INFORMATION ABOUT SOCIAL SECURITY BENEFITS <br></br> CALL
                1-800-772-1213 SSA.gov
              </p>
            </div>
          </Row>
          {validated && (
            <div className="red-text text-center mt-2">
              *Complete required fields to continue
            </div>
          )}
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};
export default SocialSecurity;
