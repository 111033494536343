import React from "react";

const Pay2 = () => {
  return (
    <div className="article-section" data-screen="pay-s2">
      <div className="container">
        <ol start="9" type="1">
          <li>
            My starting point is always to make sure that you understand where
            your money is going and what the codes on your check mean. Do you
            have a recent paycheck for us to look at?  It doesn't have to be the
            last one, just a recent one. In my experience, this is probably the
            first time in your career that someone has done this for you. Most
            people are just concerned with one thing, what they take home, but
            understanding your check, the deductions, and benefits, are critical
            to maximizing your retirement planning.  So, let's look at your
            situation. {" "}
          </li>
        </ol>
        <div style={{ border: "1px solid #333", padding: "10px" }}>
          <span style={{ fontFamily: "inherit" }}>
            <strong style={{ color: "#09F" }}>
              Input (into the BPG) and explain the pay stub line-by-line (base
              rate, fed tax, state tax, single or married, # of exemptions,
              retirement category, union dues, insurance, TSP loan, TSP
              contribution, allotments, health ins, Medicare, social security,
              retirement total in bottom right corner and its purpose).
            </strong>
          </span>
        </div>
        <br></br>
        <p>
          <em style={{ color: "#09F" }}>
            (Note: One area to look at closely is the Federal Tax withholdings.
            Here is an example of how to handle someone who has S1 (single with
            one dependent), when they are married with children.
          </em>
        </p>
        <ol start="10" type="1">
          <li>
            "The most important part to understand here is that we need to make
            sure you're paying what you owe to the Government.  We don't want
            you paying more than you owe, and definitely not less. The easiest
            way to determine this is at the end of the year when you receive
            your Tax Refund Checks. Are you getting a lot of money back or do
            you have to pay?"
          </li>
        </ol>
        <p>
          <em>
            (Note:  This information is helpful because if they're paying a lot
            of money into their taxes and getting several thousand dollars back
            at the end of the year, they are contributing significantly more
            than necessary throughout the year. Let's say this employee gets
            $3,000 back as a refund. If we divide it by 26 Pay-Periods, you can
            show them they're overfunding their taxes by roughly $115 per Pay
            Period – money you can make far better recommendations for.)
          </em>
        </p>
        <ol start="11" type="1">
          <li>
            If you're receiving more than $1,000.00 it's an indication that you
            have substantially overpaid. Let me show you why I'm sharing this
            information with you. Grab your calculator and join me in an
            exercise.
          </li>
        </ol>
        <p>
          What is the deduction amount you see listed for your Federal Tax?
          Multiply that number by 26, what did you come up with? Good job, write
          that number down, now what's the deduction you see listed for your
          State Tax? Multiply that number by 26.  What did you come up with?
          Great now add the two together, what did you come up with?
        </p>
        <p>
          Great, so what we just did was calculated how much money you are
          giving away annually to Federal &amp; State Taxes. All we did was take
          the numbers listed on your paystub and multiply them by 26 because
          that's how many times you're paid a year.
        </p>
        <p>
          Claiming your proper number of exemptions could net married
          individuals approximately $40.00 per exemption, and single individuals
          approximately $50.00 per pay period. That's bring home pay, could you
          use some additional income during the year?
        </p>
        <p>
          You would be better off to increase the amount you're claiming as
          Deductions, and take that extra money, or any portion of it (even if
          it's only $50.00 a Pay Period) and slide that over to a Credit Union
          or some type of savings account. I can help you do this."
        </p>
        <p>This will help you in several ways:</p>
        <ol>
          <li>
            You would have <em>access </em>to your money if you had an
            emergency.
          </li>
          <li>
            You would be gaining some interest even if it's just a half a
            percentage point.
          </li>
          <li>
            You would be building your own nest egg instead of just giving your
            money to the Government to use.  Ultimately having control of your
            money.  {" "}
          </li>
        </ol>
        <p>Do you have any questions on your pay stub? </p>
        <p>
          <strong>
            <em>
              Press <span style={{ color: "#0C0" }}>CONTINUE</span> and move to
              the FEGLI Section of BPG
            </em>
          </strong>
        </p>
        <h3>&nbsp;</h3>
      </div>
    </div>
  );
};

export default Pay2;
