import React from "react";

const Fegli = () => {
  return (
    <div className="article-section" data-screen="ss">
      <div className="container">
        <p className="fw-bold">SOCIAL SECURITY</p>
        <ol start="56" type="1">
          <li>
            Social Security will be an integral part of your Retirement.  Social
            Security can be started as early as 62, deferred to Full-Retirement
            Age (typically 67), or delayed to Age 70.  In order to qualify for
            Social Security benefits, you must work and pay into Social Security
            for 10 years (40 credits).
            <p>
              In addition, Social Security Benefits are taxed in retirement.  Up
              to 85% of your Social Security Benefit will be taxed in
              retirement, based upon your retirement income.{" "}
            </p>
            <p>
              Do you know what your estimated Social Security Benefit is? (Input
              this into the Income Amount box in the BPG. If the employee does
              not know, use $1350 as the amount){" "}
            </p>
            <p>
              What age do you want to start Social Security? (type in the age
              into the BPG, then select how the Social Security amount was
              determined: Recent Statement, SSA.GOV, or simple an Estimate)
            </p>
          </li>
        </ol>
        <p>
          <strong>
            <em>
              Press <span className="green">CONTINUE</span> and move into the
              Rainy Day Fund portion of the BPG
            </em>
          </strong>{" "}
        </p>
      </div>
    </div>
  );
};

export default Fegli;
