import React from "react";

const Fers2 = () => {
  return (
    <div className="article-section">
      <div className="container">
        <ol start="40" type="1">
          <li>
            Now let's put it together. We are assuming you will have<u> </u>
            (#) years of service at retirement, and your monthly income before
            retirement should be up to about{" "}
            <em>
              $4167 <strong>(Monthly Gross Income in the</strong>{" "}
              <strong>BPG</strong>)
            </em>
            . At retirement your income will drop to{" "}
            <em>
              $X,XXX <strong>(Reduced Monthly Income in the BPG)</strong>
            </em>
            , or a loss in monthly income immediately of <em>$3229</em>. This
            also provides a monthly income to your spouse in the event you were
            to die first of <em>$XXX</em>.{" "}
          </li>
          <p>
            <em>
              As you review these numbers, the key here is to highlight that
              they will be retiring on a significantly reduced paycheck of from
              50 – 70% depending on the number of years of service
            </em>
            <em>.</em>
          </p>
          <br></br>
          <li>
            Could you live on that amount of income today? Of course not, none
            of us could. But how are you going to make it at retirement on that
            income? Unfortunately, that is why we see so many retirees in our
            communities working at places like Wal-Mart. Not because they want
            to, but because they have to.{" "}
          </li>
          <br></br>
          <li>Now in addition to your:</li>
          <ol>
            <li>
              <strong>
                FERS pension of{" "}
                <em>$X,XXX (Reduced Monthly Income in the BPG)</em>
              </strong>
              , you'll also collect your...
            </li>
            <li>
              <strong>Social Security, </strong>which is taxable and can begin
              as early as age 62.
            </li>
            <li>
              <strong>Special Retirement Supplement, </strong>which is taxable. 
              You must qualify for this based upon age and years of service.  It
              acts like Social Security and automatically ends at age 62.
            </li>
            <li>
              You'll also, collect your <strong>TSP </strong>The traditional TSP
              is taxable<strong> at 20%.</strong>
            </li>
            <li>
              You'll also have your <strong>ROTH IRA, </strong>which is what I
              will help you set up today. Your Roth IRA will be a tax free
              source of income at retirement.
            </li>
          </ol>
          <br></br>
          <li>
            One important thing to remember; almost everything you're going to
            get from the Government is taxed. Your FERS pension will be taxed,
            most or all of your Social Security check, as well as ALL or most of
            your TSP money will also be taxed. The only exception is a{" "}
            <strong>
              <em>Roth IRA, </em>
            </strong>
            this is how you'll increase your spendable income during retirement.
            The objective       here is to help you save up as much as you can
            between now and retirement in a tax-free account.{" "}
          </li>
          <br></br>
          <li>Any Questions? </li>
        </ol>
      </div>
    </div>
  );
};

export default Fers2;
