import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import userService from "../../../services/UserService";

const RetirementForm2 = ({ submit }) => {
  const { plannerData, setOnChangeStatus } = usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);

  const handleStateByField = (name, value) => {
    setOnChangeStatus(true);

    setformData({
      ...formData,
      [name]: value,
    });
  };
  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="retirement-2">
        <div className="qs-box-retirement single-screen">
          <div className="text-center">
            <div className="head-title">RETIREMENT PLANNING</div>
          </div>
          <Form
            id="form_retirement_s2"
            className="subform"
            noValidate
            onSubmit={submitFormdata}
          >
            <div className="retirement-container mb-3">
              <Row>
                <Col md={1} xs={2} sm={1} style={{ textAlign: "end" }}>
                  <div className="retirement-label">
                    {formData.retirement_s2 &&
                    formData.retirement_s2 !== "0" ? (
                      <i
                        className="fa fa-check-square-o checker"
                        onClick={() => handleStateByField("retirement_s2", 0)}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-square-o checker2"
                        onClick={() => handleStateByField("retirement_s2", 1)}
                      ></i>
                    )}
                  </div>
                </Col>
                <Col sm={11} xs={10} md={11}>
                  <label
                    className="retirement-label2"
                    onClick={() =>
                      handleStateByField(
                        "retirement_s2",
                        formData.retirement_s2 ? 0 : 1
                      )
                    }
                  >
                    By checking this box, you acknowledge the information you
                    provide is accurate to the best of your ability.
                    Deficiencies or omissions in the information provided will
                    be reflected in the results we present to you. Based solely
                    upon the information provided by you, we will prepare a
                    report that will outline the benefits available to you in
                    retirement as of a specified date in the future. While the
                    information we will provide will be accurate to the best of
                    our ability, any deviation from the parameters you’ve
                    outlined, or deficiencies or omissions in the information
                    provided by you, will result in different financial
                    outcomes. We shall not be liable for such deviations,
                    deficiencies or omissions in the information provided. You
                    are instructed and encouraged to verify information with the
                    Office of Personnel Management.
                  </label>
                </Col>
              </Row>
            </div>
            <div className="btn-wrapper">
              <div className="text-center mt-4">
                <button
                  disabled={
                    (!formData.retirement_s2 ||
                      formData.retirement_s2 == "0") &&
                    userService.getAccess().bpg_skip_req != 1
                  }
                  className="btn-next btn question-next"
                  type="submit"
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Card.Body>
    </>
  );
};

export default RetirementForm2;
