import React from "react";

const RainyDay = () => {
  return (
    <div className="article-section">
      <div className="container">
        <p className="fw-bold">RAINY DAY FUND</p>
        <ol start="57" type="1">
          <li>
            The term &quot;Emergency Fund&quot; refers to a savings account
            equal to at least three to six months of living expenses in case of
            an unexpected financial emergency, such as major house repairs or
            sudden illness. This account should be relatively liquid and used
            for large expenses that are often unforeseen.&nbsp;
            <strong>Emergency Savings</strong>&nbsp;are much larger and usually
            the end result of a successful&nbsp;<strong>Rainy Day Fund</strong>.
          </li>
          <br></br>
          <li>
            Rainy Day Funds may sound similar to Emergency Savings, but they're
            intended to pay for the occasional, more frequent smaller expenses.
            For example, let's say your refrigerator suddenly stops working, and
            you need to replace it, or your expenses related to children's
            sports, summer camp, etc.{" "}
          </li>
          <br></br>
          <li>
            A Rainy Day Fund can help limit your use of alternative methods,
            such as a Credit Card with a high-interest rate.  Remember, when
            you&nbsp;
            <em>pay</em>&nbsp;interest, you are losing money. But when you&nbsp;
            <em>earn</em>&nbsp;interest, your money is making more money all by
            itself.
          </li>
          <br></br>
          <li>
            Can you see the benefit of a Rainy Day Fund? <em>(yes or no)</em>
            Do you have a Rainy Day Fund? <em>(yes or no)</em>
            <br></br>
            Do you have a budget to track of expenses? <em>(yes or no)</em>
            <br></br>
            What areas would you like help with the most?{" "}
            <em>
              (select all that apply in the BPG: Budgeting, Saving Money,
              Reducing Debt, Rainy Day Fund)
            </em>{" "}
          </li>
        </ol>
        <p>
          <strong>
            <em>
              Press <span className="green">CONTINUE</span> and move into The
              Benefits Summary portion of the BPG
            </em>
          </strong>
        </p>
      </div>
    </div>
  );
};

export default RainyDay;
