import axios from "axios";
import { Handle401 } from "./helperService";

const axiosInstance = axios.create({
  baseURL: "https://myagentcrm.com/wp-json/bpg/",
});
axiosInstance.interceptors.request.use((request) => {
  let token = localStorage.getItem("token");
  if (token) {
    token = token.replace("Bearer ", "");
  }
  request.headers.authorization = `Bearer ${token}`;
  return request;
});

class GenericService {
  get = async (url, options = {}) =>
    await axiosInstance
      .get(url + "?timestamp=" + Date.now(), options)
      .then(({ data }) => data)
      .catch((error) => {
        Handle401(error);
        return error;
      });

  post = async (url, data) =>
    await axiosInstance
      .post(url, data)
      .then(({ data }) => data)
      .catch((error) => {
        Handle401(error);
        return error;
      });

  delete = async (url) =>
    await axiosInstance
      .delete(url)
      .then(({ data }) => data)
      .catch((error) => {
        Handle401(error);
        return error;
      });

  put = async (url, data) =>
    await axiosInstance
      .put(url, data)
      .then(({ data }) => data)
      .catch((error) => {
        Handle401(error);
        return error;
      });
}
export default GenericService;
