import React from "react";
import { Card, Form, Image } from "react-bootstrap";
import Images from "../../CRMs/crm2.0";
const RetirementForm = ({ submit }) => {
  return (
    <Form onSubmit={submit}>
      <Card.Body className="retirement subform">
        <div className="qs-box single-screen">
          <div>
            <Image className="retire-image" src={Images.retirementImage} />
          </div>
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next">CONTINUE</button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Form>
  );
};

export default RetirementForm;
