import React, { useEffect, useState } from "react";
import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";

const PensionForm = ({ submit, validated }) => {
  const {
    plannerData,
    handleBlur,
    handleRadioButton,
    onChangeHandler,
    handleOnchangeCheckbox,
    setOnChangeStatus,
  } = usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);

  const handleCheckbox = (value) => {
    let payload = formData.additional_sources_of_income_at_retire
      ? formData.additional_sources_of_income_at_retire
      : [];
    if (payload && payload.includes(value)) {
      payload = payload.filter((val) => val !== value);
    } else {
      payload.push(value);
    }
    handleStateByField("additional_sources_of_income_at_retire", payload);
  };

  const handleinputChange = (key, event, index) => {
    let payload = formData[key] ? formData[key] : {};

    payload[index] = event.target.value;

    handleStateByField(key, payload);
  };

  const handleThriftBlur = (key, index, event) => {
    let value = "";
    let payload = formData[key] ? formData[key] : [];

    if (event.target.value) {
      value = event.target.value.replaceAll(",", "");
      value = parseFloat(value);
    }

    payload[index] = value ? value : "0";

    handleState(key, payload);
  };

  const handleRadio = (key, value, index) => {
    let payload = formData[key] ? formData[key] : {};

    payload[index] = value;

    handleStateByField(key, payload);
  };

  const handleState = (name, value) => {
    setformData({
      ...formData,
      [name]: value,
    });
  };

  const handleStateByField = (name, value) => {
    setOnChangeStatus(true);

    setformData({
      ...formData,
      [name]: value,
    });
  };

  const handleStatePayload = (payload) => {
    setformData({
      ...formData,
      ...payload,
    });
  };

  const addMore = (type) => {
    setOnChangeStatus(true);
    try {
      let invest_acc = "s10_invest_acc_name_ext";
      let current_bal = "s10_inv_current_bal_ext";
      let acc_type = "s10_inv_acc_type_ext";
      let statement_available = "s10_inv_statement_available_ext";
      let timestemp = Math.round(new Date() / 1000);
      if (type === "Liquid") {
        invest_acc = "s10_liquid_invest_acc_name_ext";
        current_bal = "s10_liquid_inv_current_bal_ext";
        acc_type = "s10_liquid_inv_acc_type_ext";
        statement_available = "s10_liquid_inv_statement_available_ext";
      }
      let invest_accValue = {};
      if (formData[invest_acc]) {
        invest_accValue = { ...formData[invest_acc], [timestemp]: "" };
      } else {
        invest_accValue = { [timestemp]: "" };
      }

      let current_balValue = {};
      if (formData[current_bal]) {
        current_balValue = { ...formData[current_bal], [timestemp]: "" };
      } else {
        current_balValue = { [timestemp]: "" };
      }

      let acc_typeValue = {};
      if (formData[acc_type]) {
        acc_typeValue = { ...formData[acc_type], [timestemp]: "" };
      } else {
        acc_typeValue = { [timestemp]: "" };
      }

      let statement_availableValue = {};
      if (formData[statement_available]) {
        statement_availableValue = {
          ...formData[statement_available],
          [timestemp]: "yes",
        };
      } else {
        statement_availableValue = { [timestemp]: "yes" };
      }

      let payload = {
        [current_bal]: current_balValue,
        [acc_type]: acc_typeValue,
        [invest_acc]: invest_accValue,
        [statement_available]: statement_availableValue,
      };
      handleStatePayload(payload);
    } catch (error) {
      console.log("error=> ", error);
    }
  };

  const removeField = (data, type) => {
    setOnChangeStatus(true);
    try {
      let invest_acc = "s10_invest_acc_name_ext";
      let current_bal = "s10_inv_current_bal_ext";
      let acc_type = "s10_inv_acc_type_ext";
      let statement_available = "s10_inv_statement_available_ext";

      if (type == "Liquid") {
        invest_acc = "s10_liquid_invest_acc_name_ext";
        current_bal = "s10_liquid_inv_current_bal_ext";
        acc_type = "s10_liquid_inv_acc_type_ext";
        statement_available = "s10_liquid_inv_statement_available_ext";
      }
      const payloadData = formData;
      if (typeof payloadData[invest_acc] == "object") {
        delete payloadData[invest_acc][data];

        delete payloadData[current_bal][data];

        delete payloadData[acc_type][data];

        delete payloadData[statement_available][data];
      } else {
        payloadData[invest_acc] = "";
        payloadData[current_bal] = "";
        payloadData[acc_type] = "";
        payloadData[statement_available] = "";
      }

      const payload = {
        [current_bal]: payloadData[current_bal],
        [acc_type]: payloadData[acc_type],
        [invest_acc]: payloadData[invest_acc],
        [statement_available]: payloadData[statement_available],
      };

      handleStatePayload(payload);
    } catch (error) {
      console.log("error ==> ", error);
    }
  };

  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="pension-section">
        <div className="text-center">
          <div className="head-title">Pensions & income</div>
        </div>
        <div className="mt-3">
          <Form
            name="pensionForm"
            className="paystub-text"
            noValidate
            validated={validated}
            onSubmit={submitFormdata}
          >
            {formData["s2s3_receiving_pension"] == "yes" && (
              <FloatingLabel
                controlId="floatingInput"
                label="Monthly Military Pension Amount:"
                className="my-2 floatingInput"
              >
                <Form.Control
                  autoComplete="off"
                  className="remove-focus "
                  type="text"
                  onBlur={(e) => handleBlur(e, false, setformData)}
                  name="s10_monthly_military_pension"
                  value={formData.s10_monthly_military_pension}
                  placeholder="Monthly Military Pension Amount:"
                  onChange={(event) => onChangeHandler(event, setformData)}
                  isInvalid={
                    validated && !formData.s10_monthly_military_pension
                  }
                  required
                />
              </FloatingLabel>
            )}

            {formData["s2s3_receiving_disability_pension"] == "yes" && (
              <FloatingLabel
                controlId="floatingInput"
                label="Monthly Military Disability Amount:"
                className="my-2 floatingInput"
              >
                <Form.Control
                  autoComplete="off"
                  className="remove-focus required-input"
                  type="text"
                  onBlur={(e) => handleBlur(e, false, setformData)}
                  name="s10_monthly_disability_amount"
                  value={formData.s10_monthly_disability_amount}
                  placeholder="Monthly Military Disability Amount:"
                  onChange={(event) => onChangeHandler(event, setformData)}
                  isInvalid={
                    validated && !formData.s10_monthly_disability_amount
                  }
                  required
                />
              </FloatingLabel>
            )}

            <div>
              <div className="d-flex align-items-center">
                <div className="mx-2">Do you have any other pensions? </div>
                <div className="mx-2 d-flex">
                  <div className="mx-2">
                    <input
                      id="have_other_pensionYes"
                      type="radio"
                      name="have_other_pension"
                      className={`mt-3`}
                      checked={formData.have_other_pension == "yes"}
                      onChange={(event) => {
                        handleOnchangeCheckbox(event, "yes", setformData);
                      }}
                    />{" "}
                    <label htmlFor="have_other_pensionYes">Yes</label>
                  </div>
                  <div className="mx-2">
                    <input
                      id="have_other_pensionNo"
                      type="radio"
                      name="have_other_pension"
                      className={`mt-3`}
                      checked={formData.have_other_pension == "no"}
                      onChange={(event) => {
                        handleOnchangeCheckbox(event, "no", setformData);
                      }}
                    />{" "}
                    <label htmlFor="have_other_pensionNo">No</label>
                  </div>
                </div>
              </div>
              {formData.have_other_pension == "yes" && (
                <div className="mx-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Pension name:"
                    className="my-2 floatingInput"
                  >
                    <Form.Control
                      autoComplete="off"
                      className="remove-focus "
                      type="text"
                      name="have_other_pension_name"
                      value={formData["have_other_pension_name"]}
                      placeholder="Pension name:"
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Monthly amount:"
                    className="my-2 floatingInput"
                  >
                    <Form.Control
                      autoComplete="off"
                      className="remove-focus "
                      type="text"
                      onBlur={(e) => handleBlur(e, false, setformData)}
                      name="have_other_pension_monthly_amount"
                      value={formData.have_other_pension_monthly_amount}
                      placeholder="Monthly amount:"
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </FloatingLabel>
                  <div className="d-flex align-items-center">
                    <div className="mx-2">
                      Is this pension currently being taken?{" "}
                    </div>
                    <div className="mx-2 d-flex">
                      <div className="mx-2">
                        <input
                          id="have_other_pension_being_takenYes"
                          type="radio"
                          name="have_other_pension_being_taken"
                          className={`mt-3`}
                          checked={
                            formData.have_other_pension_being_taken == "yes"
                          }
                          onChange={(event) => {
                            handleOnchangeCheckbox(event, "yes", setformData);
                          }}
                        />{" "}
                        <label htmlFor="have_other_pension_being_takenYes">
                          Yes
                        </label>
                      </div>
                      <div className="mx-2">
                        <input
                          id="have_other_pension_being_takenNo"
                          type="radio"
                          name="have_other_pension_being_taken"
                          className={`mt-3`}
                          checked={
                            formData.have_other_pension_being_taken == "no"
                          }
                          onChange={(event) => {
                            handleOnchangeCheckbox(event, "no", setformData);
                          }}
                        />{" "}
                        <label htmlFor="have_other_pension_being_takenNo">
                          No
                        </label>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              )}
            </div>

            <div>
              <div className="mx-2">
                Check the boxes for additional sources of income you will have
                at retirement:
              </div>
            </div>
            <div>
              <div className="single-field">
                <label className="about-continue-check-text">
                  <input
                    type="checkbox"
                    name="Investment accounts"
                    onChange={(event) => {
                      handleCheckbox(event.target.name);
                    }}
                    checked={
                      formData.additional_sources_of_income_at_retire &&
                      formData.additional_sources_of_income_at_retire.includes(
                        "Investment accounts"
                      )
                    }
                  />{" "}
                  Non-liquid investment accounts
                </label>
              </div>
              {formData.additional_sources_of_income_at_retire &&
                formData.additional_sources_of_income_at_retire.includes(
                  "Investment accounts"
                ) && (
                  <div className="single-field mx-3">
                    <Row className="mt-3">
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Non-liquid investment accounts:"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="about-inputs remove-focus"
                            type="text"
                            placeholder="Non-liquid investment accounts:"
                            name="s10_invest_acc_name"
                            value={formData.s10_invest_acc_name}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Current balance:"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="remove-focus about-inputs"
                            type="number"
                            placeholder="Current balance:"
                            name="s10_inv_current_bal"
                            value={formData.s10_inv_current_bal}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <div className="textbox-wrapper   ">
                          <select
                            type="text"
                            className={formData.s10_inv_acc_type && "has-val"}
                            name="s10_inv_acc_type"
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                            value={formData.s10_inv_acc_type}
                          >
                            <option></option>
                            <option value="Qualified Account">
                              Qualified Account
                            </option>
                            <option value="Non-qualified Account">
                              Non-qualified Account
                            </option>

                            <option value="Traditional IRA" selected="">
                              Traditional IRA
                            </option>
                            <option value="Roth IRA">Roth IRA</option>
                          </select>
                          <div className="floating-placeholder">
                            Account type:
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex align-items-center">
                          <div className="mx-2">
                            Is a statement available to view?{" "}
                          </div>
                          <div className="mx-2 d-flex">
                            <div className="mx-2">
                              <input
                                id={`nonLiquidstatementAvailableYes`}
                                type="radio"
                                name={`s10_inv_statement_available`}
                                className={`mt-3`}
                                checked={
                                  formData.s10_inv_statement_available == "yes"
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  handleOnchangeCheckbox(
                                    event,
                                    "yes",
                                    setformData
                                  );
                                }}
                              />{" "}
                              <label htmlFor={`nonLiquidstatementAvailableYes`}>
                                Yes
                              </label>
                            </div>
                            <div className="mx-2">
                              <input
                                id={`nonLiquidstatementAvailableNo`}
                                type="radio"
                                name={`s10_inv_statement_available`}
                                className={`mt-3`}
                                checked={
                                  formData.s10_inv_statement_available == "no"
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  handleOnchangeCheckbox(
                                    event,
                                    "no",
                                    setformData
                                  );
                                }}
                              />{" "}
                              <label htmlFor={`nonLiquidstatementAvailableNo`}>
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {formData["s10_invest_acc_name_ext"] &&
                      Object.keys(formData["s10_invest_acc_name_ext"]) &&
                      Object.keys(formData["s10_invest_acc_name_ext"]).map(
                        (data, index) => (
                          <Row className="mt-3">
                            <Col md={12}>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Non-liquid investment accounts:"
                                className="mb-3 thrift-label"
                              >
                                <Form.Control
                                  autoComplete="off"
                                  className="about-inputs remove-focus"
                                  type="text"
                                  placeholder="Non-liquid investment accounts:"
                                  name={"s10_invest_acc_name_ext"}
                                  value={
                                    formData.s10_invest_acc_name_ext &&
                                    formData.s10_invest_acc_name_ext[data]
                                  }
                                  onChange={(event) =>
                                    handleinputChange(
                                      "s10_invest_acc_name_ext",
                                      event,
                                      data
                                    )
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                            <Col md={6}>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Current balance:"
                                className="mb-3 thrift-label"
                              >
                                <Form.Control
                                  autoComplete="off"
                                  className="remove-focus about-inputs"
                                  type="number"
                                  placeholder="Current balance:"
                                  name="s10_inv_current_bal_ext"
                                  value={
                                    formData.s10_inv_current_bal_ext &&
                                    formData.s10_inv_current_bal_ext[data]
                                  }
                                  onBlur={(e) =>
                                    handleThriftBlur(
                                      "s10_inv_current_bal_ext",
                                      data,
                                      e
                                    )
                                  }
                                  onChange={(event) =>
                                    handleinputChange(
                                      "s10_inv_current_bal_ext",
                                      event,
                                      data
                                    )
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                            <Col md={6}>
                              <div className="textbox-wrapper   ">
                                <select
                                  type="text"
                                  className={
                                    formData.s10_inv_acc_type_ext &&
                                    formData.s10_inv_acc_type_ext[data] &&
                                    "has-val"
                                  }
                                  name="s10_inv_acc_type_ext"
                                  onChange={(event) =>
                                    handleinputChange(
                                      "s10_inv_acc_type_ext",
                                      event,
                                      data
                                    )
                                  }
                                  value={
                                    formData.s10_inv_acc_type_ext &&
                                    formData.s10_inv_acc_type_ext[data]
                                  }
                                >
                                  <option></option>
                                  <option value="Qualified Account">
                                    Qualified Account
                                  </option>
                                  <option value="Non-qualified Account">
                                    Non-qualified Account
                                  </option>

                                  <option value="Traditional IRA" selected="">
                                    Traditional IRA
                                  </option>
                                  <option value="Roth IRA">Roth IRA</option>
                                </select>
                                <div className="floating-placeholder">
                                  Account type:
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex align-items-center">
                                <div className="mx-2">
                                  Is a statement available to view?{" "}
                                </div>

                                <div className="mx-2 d-flex">
                                  <div className="mx-2">
                                    <input
                                      id={`nonLiquidstatementAvailableYes${index}`}
                                      type="radio"
                                      name={data}
                                      className={`mt-3`}
                                      checked={
                                        formData.s10_inv_statement_available_ext &&
                                        formData
                                          .s10_inv_statement_available_ext[
                                          data
                                        ] == "yes"
                                      }
                                      onChange={(event) => {
                                        handleRadio(
                                          "s10_inv_statement_available_ext",
                                          "yes",
                                          data
                                        );
                                      }}
                                    />{" "}
                                    <label
                                      htmlFor={`nonLiquidstatementAvailableYes${index}`}
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      id={`nonLiquidstatementAvailableNo${index}`}
                                      type="radio"
                                      name={data}
                                      className={`mt-3`}
                                      checked={
                                        formData.s10_inv_statement_available_ext &&
                                        formData
                                          .s10_inv_statement_available_ext[
                                          data
                                        ] == "no"
                                      }
                                      onChange={(event) => {
                                        handleRadio(
                                          "s10_inv_statement_available_ext",
                                          "no",
                                          data
                                        );
                                      }}
                                    />{" "}
                                    <label
                                      htmlFor={`nonLiquidstatementAvailableNo${index}`}
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {(index || index == 0) && (
                                <div className=" remove-align">
                                  <div
                                    className="survey-remove pension-remove-text"
                                    onClick={() =>
                                      removeField(data, "nonLiquid")
                                    }
                                  >
                                    Remove
                                  </div>
                                </div>
                              )}
                            </Col>
                          </Row>
                        )
                      )}
                    <div className=" mb-4">
                      <a
                        href="javascript:void(0)"
                        onClick={() => addMore("nonLiquid")}
                        className="thrift-a remove-text"
                      >
                        +Add Additional Non-liquid investment account
                      </a>
                    </div>
                  </div>
                )}
            </div>
            <div>
              <div className="single-field">
                <label className="about-continue-check-text">
                  <input
                    type="checkbox"
                    name="Liquid Investment accounts"
                    onChange={(event) => {
                      handleCheckbox(event.target.name);
                    }}
                    checked={
                      formData.additional_sources_of_income_at_retire &&
                      formData.additional_sources_of_income_at_retire.includes(
                        "Liquid Investment accounts"
                      )
                    }
                  />{" "}
                  Liquid investment accounts
                </label>
              </div>
              {formData.additional_sources_of_income_at_retire &&
                formData.additional_sources_of_income_at_retire.includes(
                  "Liquid Investment accounts"
                ) && (
                  <div className="single-field ">
                    <Row className="mt-3">
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Liquid investment accounts:"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="remove-focus about-inputs"
                            type="text"
                            placeholder="Liquid investment accounts:"
                            name="s10_liquid_invest_acc_name"
                            value={formData.s10_liquid_invest_acc_name}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Current balance:"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="remove-focus about-inputs"
                            type="number"
                            placeholder="Current balance:"
                            name="s10_liquid_inv_current_bal"
                            value={formData.s10_liquid_inv_current_bal}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <div className="textbox-wrapper   ">
                          <select
                            type="text"
                            className={
                              formData.s10_liquid_inv_acc_type && "has-val"
                            }
                            name="s10_liquid_inv_acc_type"
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                            value={formData.s10_liquid_inv_acc_type}
                          >
                            <option></option>
                            <option value="Qualified Account">
                              Qualified Account
                            </option>
                            <option value="Non-qualified Account">
                              Non-qualified Account
                            </option>

                            <option value="Traditional IRA" selected="">
                              Traditional IRA
                            </option>
                            <option value="Roth IRA">Roth IRA</option>
                          </select>
                          <div className="floating-placeholder">
                            Account type:
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex align-items-center">
                          <div className="mx-2">
                            Is a statement available to view?{" "}
                          </div>
                          <div className="mx-2 d-flex">
                            <div className="mx-2">
                              <input
                                id={`statementAvailableYes`}
                                type="radio"
                                name={`s10_liquid_inv_statement_available`}
                                className={`mt-3`}
                                checked={
                                  formData.s10_liquid_inv_statement_available ==
                                  "yes"
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  handleOnchangeCheckbox(
                                    event,
                                    "yes",
                                    setformData
                                  );
                                }}
                              />{" "}
                              <label htmlFor={`statementAvailableYes`}>
                                Yes
                              </label>
                            </div>
                            <div className="mx-2">
                              <input
                                id={`statementAvailableNo`}
                                type="radio"
                                name={`s10_liquid_inv_statement_available`}
                                className={`mt-3`}
                                checked={
                                  formData.s10_liquid_inv_statement_available ==
                                  "no"
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  handleOnchangeCheckbox(
                                    event,
                                    "no",
                                    setformData
                                  );
                                }}
                              />{" "}
                              <label htmlFor={`statementAvailableNo`}>No</label>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {formData["s10_liquid_invest_acc_name_ext"] &&
                      Object.keys(formData["s10_liquid_invest_acc_name_ext"]) &&
                      Object.keys(
                        formData["s10_liquid_invest_acc_name_ext"]
                      ).map((data, index) => (
                        <Row className="mt-3">
                          <Col md={12}>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Liquid investment accounts:"
                              className="mb-3 thrift-label"
                            >
                              <Form.Control
                                autoComplete="off"
                                className="remove-focus about-inputs"
                                type="text"
                                placeholder="Liquid investment accounts:"
                                name={"s10_liquid_invest_acc_name_ext"}
                                value={
                                  formData.s10_liquid_invest_acc_name_ext &&
                                  formData.s10_liquid_invest_acc_name_ext[data]
                                }
                                onChange={(event) =>
                                  handleinputChange(
                                    "s10_liquid_invest_acc_name_ext",
                                    event,
                                    data
                                  )
                                }
                              />
                            </FloatingLabel>
                          </Col>
                          <Col md={6}>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Current balance:"
                              className="mb-3 thrift-label"
                            >
                              <Form.Control
                                autoComplete="off"
                                className="remove-focus about-inputs"
                                type="number"
                                placeholder="Current balance:"
                                name="s10_liquid_inv_current_bal_ext"
                                value={
                                  formData.s10_liquid_inv_current_bal_ext &&
                                  formData.s10_liquid_inv_current_bal_ext[data]
                                }
                                onBlur={(e) =>
                                  handleThriftBlur(
                                    "s10_liquid_inv_current_bal_ext",
                                    data,
                                    e
                                  )
                                }
                                onChange={(event) =>
                                  handleinputChange(
                                    "s10_liquid_inv_current_bal_ext",
                                    event,
                                    data
                                  )
                                }
                              />
                            </FloatingLabel>
                          </Col>
                          <Col md={6}>
                            <div className="textbox-wrapper   ">
                              <select
                                type="text"
                                className={
                                  formData.s10_liquid_inv_acc_type_ext &&
                                  formData.s10_liquid_inv_acc_type_ext[data] &&
                                  "has-val"
                                }
                                name="s10_liquid_inv_acc_type_ext"
                                onChange={(event) =>
                                  handleinputChange(
                                    "s10_liquid_inv_acc_type_ext",
                                    event,
                                    data
                                  )
                                }
                                value={
                                  formData.s10_liquid_inv_acc_type_ext &&
                                  formData.s10_liquid_inv_acc_type_ext[data]
                                }
                              >
                                <option></option>
                                <option value="Qualified Account">
                                  Qualified Account
                                </option>
                                <option value="Non-qualified Account">
                                  Non-qualified Account
                                </option>

                                <option value="Traditional IRA">
                                  Traditional IRA
                                </option>
                                <option value="Roth IRA">Roth IRA</option>
                              </select>
                              <div className="floating-placeholder">
                                Account type:
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="d-flex align-items-center">
                              <div className="mx-2">
                                Is a statement available to view?{" "}
                              </div>
                              <div className="mx-2 d-flex">
                                <div className="mx-2">
                                  <input
                                    id={`statementAvailableYes${index}`}
                                    type="radio"
                                    name={data}
                                    className={`mt-3`}
                                    checked={
                                      formData.s10_liquid_inv_statement_available_ext &&
                                      formData
                                        .s10_liquid_inv_statement_available_ext[
                                        data
                                      ] == "yes"
                                    }
                                    onChange={(event) => {
                                      handleRadio(
                                        "s10_liquid_inv_statement_available_ext",
                                        "yes",
                                        data
                                      );
                                    }}
                                  />{" "}
                                  <label
                                    htmlFor={`statementAvailableYes${index}`}
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="mx-2">
                                  <input
                                    id={`statementAvailableNo${index}`}
                                    type="radio"
                                    name={data}
                                    className={`mt-3`}
                                    checked={
                                      formData.s10_liquid_inv_statement_available_ext &&
                                      formData
                                        .s10_liquid_inv_statement_available_ext[
                                        data
                                      ] == "no"
                                    }
                                    onChange={(event) => {
                                      handleRadio(
                                        "s10_liquid_inv_statement_available_ext",
                                        "no",
                                        data
                                      );
                                    }}
                                  />{" "}
                                  <label
                                    htmlFor={`statementAvailableNo${index}`}
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>

                            {(index || index == 0) && (
                              <div className="remove-align">
                                <div
                                  className="survey-remove pension-remove-text"
                                  onClick={() => removeField(data, "Liquid")}
                                >
                                  Remove
                                </div>
                              </div>
                            )}
                          </Col>
                        </Row>
                      ))}

                    <div className=" mb-4 c-pointer">
                      <div
                        onClick={() => addMore("Liquid")}
                        className="thrift-a remove-text"
                      >
                        +Add Additional liquid investment account
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div>
              <div className="single-field">
                <label className="about-continue-check-text">
                  <input
                    type="checkbox"
                    name="401k"
                    onChange={(event) => {
                      handleCheckbox(event.target.name);
                    }}
                    checked={
                      formData.additional_sources_of_income_at_retire &&
                      formData.additional_sources_of_income_at_retire.includes(
                        "401k"
                      )
                    }
                  />{" "}
                  401k
                </label>
              </div>

              {formData.additional_sources_of_income_at_retire &&
                formData.additional_sources_of_income_at_retire.includes(
                  "401k"
                ) && (
                  <div className="single-field ">
                    <Row className="mt-3 mx-3">
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="401k name:"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="remove-focus about-inputs"
                            type="text"
                            placeholder="401k name:"
                            name="s10_addIncome401k_name"
                            value={formData.s10_addIncome401k_name}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Current balance:"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="remove-focus about-inputs"
                            type="text"
                            placeholder="Current balance:"
                            name="s10_addIncome401k_current_balance"
                            value={formData.s10_addIncome401k_current_balance}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <div className="textbox-wrapper   ">
                          <select
                            type="text"
                            className={
                              formData.s10_addIncome401k_accType && "has-val"
                            }
                            name="s10_addIncome401k_accType"
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                            value={formData.s10_addIncome401k_accType}
                          >
                            <option></option>
                            <option value="Qualified - Traditional">
                              Qualified - Traditional
                            </option>

                            <option value="Qualified - Roth">
                              Qualified - Roth
                            </option>
                          </select>
                          <div className="floating-placeholder">
                            Account type:
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
            </div>

            <div>
              <div className="single-field">
                <label className="about-continue-check-text">
                  <input
                    type="checkbox"
                    name="Real estate income"
                    onChange={(event) => {
                      handleCheckbox(event.target.name);
                    }}
                    checked={
                      formData.additional_sources_of_income_at_retire &&
                      formData.additional_sources_of_income_at_retire.includes(
                        "Real estate income"
                      )
                    }
                  />{" "}
                  Real estate income
                </label>
              </div>

              {formData.additional_sources_of_income_at_retire &&
                formData.additional_sources_of_income_at_retire.includes(
                  "Real estate income"
                ) && (
                  <div className="single-field ">
                    <Row className="mt-3 mx-3">
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Monthly Gross Income:"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="remove-focus about-inputs"
                            type="text"
                            placeholder="Monthly Gross Income:"
                            name="real_estate_income_monthly_income"
                            value={formData.real_estate_income_monthly_income}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <Col>
                          <div className="d-flex align-items-center">
                            <div className="mx-2">Remaining mortgage: </div>
                            <div className="mx-2 d-flex">
                              <div className="mx-2">
                                <input
                                  id={`remainmortgeYes`}
                                  type="radio"
                                  name="real_estate_income_remaining_mortgage"
                                  className={`mt-3`}
                                  checked={
                                    formData.real_estate_income_remaining_mortgage ==
                                    "yes"
                                  }
                                  onChange={(event) => {
                                    handleOnchangeCheckbox(
                                      event,
                                      "yes",
                                      setformData
                                    );
                                  }}
                                />{" "}
                                <label htmlFor={`remainmortgeYes`}>Yes</label>
                              </div>
                              <div className="mx-2">
                                <input
                                  id="remainmortgeNo"
                                  type="radio"
                                  name="real_estate_income_remaining_mortgage"
                                  className={`mt-3`}
                                  checked={
                                    formData.real_estate_income_remaining_mortgage ==
                                    "no"
                                  }
                                  onChange={(event) => {
                                    handleOnchangeCheckbox(
                                      event,
                                      "no",
                                      setformData
                                    );
                                  }}
                                />{" "}
                                <label htmlFor="remainmortgeNo">No</label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {formData.real_estate_income_remaining_mortgage ==
                          "yes" && (
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Remaining amount:"
                            className="penstion-sub-level"
                          >
                            <Form.Control
                              autoComplete="off"
                              className="remove-focus about-inputs"
                              type="text"
                              placeholder="Remaining amount:"
                              name="real_estate_income_remaining_mortgage_amount"
                              value={
                                formData.real_estate_income_remaining_mortgage_amount
                              }
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                            />
                          </FloatingLabel>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
            </div>

            <div>
              <div className="single-field">
                <label className="about-continue-check-text">
                  <input
                    type="checkbox"
                    name="Other income"
                    onChange={(event) => {
                      handleCheckbox(event.target.name);
                    }}
                    checked={
                      formData.additional_sources_of_income_at_retire &&
                      formData.additional_sources_of_income_at_retire.includes(
                        "Other income"
                      )
                    }
                  />{" "}
                  Other income
                </label>
              </div>

              {formData.additional_sources_of_income_at_retire &&
                formData.additional_sources_of_income_at_retire.includes(
                  "Other income"
                ) && (
                  <div className="single-field ">
                    <Row className="mt-3 mx-3">
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Income name:"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="remove-focus about-inputs"
                            type="text"
                            placeholder="Income name:"
                            name="additional_sources_of_income_at_retire_other_income_name"
                            value={
                              formData.additional_sources_of_income_at_retire_other_income_name
                            }
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Monthly Gross Income:"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="remove-focus about-inputs"
                            type="text"
                            placeholder="Monthly Gross Income:"
                            name="additional_sources_of_income_at_retire_other_income_amount"
                            value={
                              formData.additional_sources_of_income_at_retire_other_income_amount
                            }
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </div>
                )}

              {formData.family_status == "married" && (
                <div>
                  <div> Spouse Employment Status: </div>

                  <div className="textbox-wrapper required-input">
                    <select
                      type="text"
                      className={formData.spouse_employment_status && "has-val"}
                      name="spouse_employment_status"
                      onChange={(event) => onChangeHandler(event, setformData)}
                      value={formData.spouse_employment_status}
                      isInvalid={
                        validated && !formData.spouse_employment_status
                      }
                      required
                    >
                      <option value=""></option>
                      <option value="Primary Homemaker/Family Manager">
                        Primary Homemaker/Family Manager
                      </option>
                      <option value="Employed">Employed</option>
                      <option value="Retired">Retired</option>
                      <option value="Receiving disability payments">
                        Receiving disability payments
                      </option>
                      <option value="Self-employed">Self-employed</option>
                    </select>
                    <div className="floating-placeholder">
                      Spouse Employment Status:{" "}
                    </div>
                  </div>

                  {(formData.spouse_employment_status == "Employed" ||
                    formData.spouse_employment_status == "Self-employed") && (
                    <div>
                      <Row>
                        <Col md={12}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Annual income:"
                            className="mb-3 thrift-label"
                          >
                            <Form.Control
                              autoComplete="off"
                              className="remove-focus about-inputs"
                              type="text"
                              placeholder="Annual income:"
                              name="s2s6_spouse_employed_annual_income"
                              value={
                                formData.s2s6_spouse_employed_annual_income
                              }
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={12}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Desired retirement age:"
                            className="mb-3 thrift-label"
                          >
                            <Form.Control
                              autoComplete="off"
                              className="remove-focus about-inputs"
                              type="text"
                              placeholder="Desired retirement age:"
                              name="s2s6_spouse_employed_desired_retirement_age"
                              value={
                                formData.s2s6_spouse_employed_desired_retirement_age
                              }
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={12}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Estimated monthly pension:"
                            className="mb-3 thrift-label"
                          >
                            <Form.Control
                              autoComplete="off"
                              className="remove-focus about-inputs"
                              type="text"
                              placeholder="Estimated monthly pension:"
                              name="s2s6_spouse_employed_est_monthly_pension"
                              value={
                                formData.s2s6_spouse_employed_est_monthly_pension
                              }
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={12}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Age to start Social Security:"
                            className="mb-3 thrift-label"
                          >
                            <Form.Control
                              autoComplete="off"
                              className="remove-focus about-inputs"
                              type="text"
                              placeholder="Age to start Social Security:"
                              name="s2s6_spouse_employed_age_to_start_social_security"
                              value={
                                formData.s2s6_spouse_employed_age_to_start_social_security
                              }
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={12}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Estimated monthly Social Security income:"
                            className="mb-3 thrift-label"
                          >
                            <Form.Control
                              autoComplete="off"
                              className="remove-focus about-inputs"
                              type="text"
                              placeholder="Estimated monthly Social Security income:"
                              name="s2s6_spouse_employed_est_mon_soc_sec_inc"
                              value={
                                formData.s2s6_spouse_employed_est_mon_soc_sec_inc
                              }
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {(formData.spouse_employment_status == "Retired" ||
                    formData.spouse_employment_status ==
                      "Receiving disability payments") && (
                    <div>
                      <Row>
                        <Col md={12}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Monthly pension:"
                            className="mb-3 thrift-label"
                          >
                            <Form.Control
                              autoComplete="off"
                              className="remove-focus about-inputs"
                              type="text"
                              placeholder="Monthly pension:"
                              name="s2s6_spouse_ret_disability_monthly_payment"
                              value={
                                formData.s2s6_spouse_ret_disability_monthly_payment
                              }
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <div className="d-flex align-items-center">
                          <div className="single-field p-0">
                            <div className="mx-2 text-about">
                              Are you receiving Social Security payments?
                            </div>
                          </div>
                          <div className="mx-2 d-flex">
                            <div className="mx-2">
                              <input
                                id="aboutMilitaryDisabilityPensionYes"
                                type="radio"
                                name="s2s6_ret_disability_receiving_soc_sec_payments"
                                checked={
                                  formData.s2s6_ret_disability_receiving_soc_sec_payments ==
                                  "yes"
                                }
                                onChange={(event) => {
                                  handleRadioButton(event, "yes", setformData);
                                }}
                              />{" "}
                              <label
                                className="text-about"
                                htmlFor="aboutMilitaryDisabilityPensionYes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="mx-2 text-about">
                              <input
                                id="aboutMilitaryDisabilityPensionNo"
                                type="radio"
                                name="s2s6_ret_disability_receiving_soc_sec_payments"
                                checked={
                                  formData.s2s6_ret_disability_receiving_soc_sec_payments ==
                                  "no"
                                }
                                onChange={(event) => {
                                  handleRadioButton(event, "no", setformData);
                                }}
                              />{" "}
                              <label
                                className="text-about"
                                htmlFor="aboutMilitaryDisabilityPensionNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <Row>
                          {formData.s2s6_ret_disability_receiving_soc_sec_payments ==
                            "no" && (
                            <Col md={12}>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Age to start Social Security:"
                                className="mb-3 thrift-label"
                              >
                                <Form.Control
                                  autoComplete="off"
                                  className="remove-focus about-inputs"
                                  type="text"
                                  placeholder="Age to start Social Security:"
                                  name="s2s6_spouse_ret_dis_age_to_start_soc_sec"
                                  value={
                                    formData.s2s6_spouse_ret_dis_age_to_start_soc_sec
                                  }
                                  onChange={(event) =>
                                    onChangeHandler(event, setformData)
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                          )}
                        </Row>
                        <Col md={12}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Estimated monthly Social Security income:"
                            className="mb-3 thrift-label"
                          >
                            <Form.Control
                              autoComplete="off"
                              className="remove-focus about-inputs"
                              type="text"
                              placeholder="Estimated monthly Social Security income:"
                              name="s2s6_spouse_ret_dis_est_monthly_soc_sec_income"
                              value={
                                formData.s2s6_spouse_ret_dis_est_monthly_soc_sec_income
                              }
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              )}
            </div>
            {validated && (
              <div className="red-text text-center mt-2">
                *Complete required fields to continue
              </div>
            )}
            <div className="btn-wrapper">
              <div className="text-center mt-4">
                <button className="btn-next btn question-next" type="submit">
                  CONTINUE
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Card.Body>
    </>
  );
};

export default PensionForm;
