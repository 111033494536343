import React from "react";

const Fegli = () => {
  return (
    <div className="article-section">
      <div className="container">
        <p>
          <strong>
            <em>
              <u>SECTION III</u>
            </em>
          </strong>
          <strong>
            <em> - FEGLI</em>
          </strong>
          <br></br>
          <strong>
            <em>Transition from Paystub</em>
          </strong>
        </p>
        <ol start="12" type="1">
          <li>
            Your Federal Government Benefits are broken down into a few
            different categories, some that you have to use because you're a
            federal employee, some that can be adjusted whenever you'd like and
            some that can only be changed at specific times. If you work long
            enough, you'll become entitled to a pension, so you must make
            contributions into the retirement system. Even though you can't
            change it we'll go over that in depth because having a federal
            pension is a great thing. Some benefits, like health insurance,
            dental insurance, and vision insurance, can only be changed during
            open season, so we'll talk about those during an open season if you
            have questions. And some benefits like FEGLI and TSP, when set up
            right can make a huge difference in your income and peace of mind –
            now and in retirement.  So, we'll go over your pension and your TSP,
            but we'll start with FEGLI.
          </li>
        </ol>
        <ol start="13" type="1">
          <li>FEGLI stands for Federal Employees Group Life Insurance.</li>
        </ol>
        <p>
          <strong>
            <u>Explain Free Benefits: Basic Life and Extra Benefit</u>
          </strong>{" "}
        </p>
        <p>
          <strong>
            <em>Basic Life</em>
          </strong>
        </p>
        <ol start="14" type="1">
          <li>
            The first part I'm going to go over is called Basic Life.  Career
            Postal Employees get this for free, however employees who work for
            any other government agency pay for a portion of it. The way your
            Basic Life insurance benefit is calculated is by taking your annual
            base pay not including overtime, which is $50,276<em>(Line 1)</em>,
            and rounding it up to the next thousand. That would make $51,000.
            Then they add an additional $2,000 to that, bringing the total Basic
            Life benefit to $53,000 <em>(Line 4)</em>.
          </li>
        </ol>
        <p>
          <strong>
            <u>
              Extra Benefit
              <em>
                – only discussed if the employee is less than 45 years old
              </em>
            </u>
          </strong>
        </p>
        <ol start="15" type="1">
          <li>
            Also, included with your basic life is an extra benefit. The amount
            of the benefit is based on your age. This benefit covers all
            employees up to the age of 45. The amount of coverage is calculated
            as a percentage of your basic life insurance benefit based on your
            age. So, according to the table at your age of 36 the factor would
            be .9, or an additional 90% of your basic life benefit. So, in
            addition to your basic life benefit of $53,000 you're receiving an
            extra benefit of $47,700<em>(Line 7)</em>; which means that your
            basic life and extra benefit would provide $100,700 to your
            beneficiaries if you passed away.
          </li>
        </ol>
        <ol start="16" type="1">
          <li>
            The Extra benefit only applies to you until you reach the age of 45.
            Between 36 and 45 it decreases a little bit each year until it goes
            away completely when you turn 45. No one likes to have anything
            taken away – I get that. But it is free, and it does provide some
            additional benefit until you turn 45.
          </li>
        </ol>
        <ol start="17" type="1">
          <li>
            Do you have any questions about your basic life or extra benefit?
          </li>
        </ol>
        <p>
          Perfect – next we'll take a look at the other portions of FEGLI that
          you've elected for yourself but are paying for separately. The other
          portions of FEGLI are called Options A, B and C.{" "}
        </p>
        <ol start="18" type="1">
          <li>
            <strong>
              <em>(Note: Reference the IN Code at the top of the page)</em>
            </strong>{" "}
            Remember the IN2Z5 code on your pay stub? This code tells us the
            options that you've selected to pay for above what you get for free.
          </li>
        </ol>
        <li style={{ marginLeft: "30px" }}>
          The IN tells me you've got some additional FEGLI coverage
        </li>
        <li style={{ marginLeft: "30px" }}>
          The number 2 tells me your age and price bracket.  We know that you're
          between the age of 35 and 39, your age is used to determine what you
          pay.
        </li>
        <li style={{ marginLeft: "30px" }}>
          You also have the letter Z after the IN2. This gives us another string
          of codes.{" "}
        </li>
        <ol start="19" type="1">
          <li>
            Now I'll explain what all of this means starting with this long Code
            for the letter Z…
            <strong>
              <em>(click on the FEGLI code chart, to determine coverages)</em>
            </strong>{" "}
            Code Z tells us you have Basic Life which we just talked about, 
            Option A, Option B with a multiple of 5 Option C, which is your
            Family coverage, the 5 at the end of the code tells me you have the
            5 multiples of family coverage. You have all three of the available
            FEGLI Options. Let's look at what you are getting for your money.
          </li>
        </ol>
        <p>
          <strong>
            <u>Explain Options A,B,C</u>
          </strong>
          <br></br>
          <strong>
            <em>Option A</em>
          </strong>
        </p>
        <ol start="20" type="1">
          <li>
            The first one is Option A and is very simple. It provides you with a
            standard amount of protection of $10,000.{" "}
          </li>
        </ol>
        <p>
          <strong>
            <em>Option B</em>
          </strong>
        </p>
        <ol start="21" type="1">
          <li>
            Next, we are going to look at what is typically the largest benefit
            through FEGLI. This is called Option B and we see from your pay stub
            that you have 5 times Option B.  Most people say this is 5 times
            your base pay which is almost correct. It's actually 5 times your
            rounded up base pay. The government offers from 1 – 5 times under
            this option. In your case we take your rounded base of $51,000 and
            multiply it by 5 for a total of $255,000(<em>Line 10</em>). Did you
            know you had that much coverage?{" "}
            <strong>
              <em>
                (Listen to what they say about whether or not they knew they had
                that much coverage – they'll usually tell you why they have it.)
              </em>
            </strong>
          </li>
          <br></br>
          <li>
            The main problem with Option B is that it gets more expensive every
            five years starting at age 40. You might have heard some of your
            co-workers talk about how expensive this coverage gets.
            Unfortunately, they're right – it does get more expensive. Right
            now, you're paying $7.65 Per Check for this coverage. As you can
            see, the problem with this (
            <strong>
              <em>
                Note: point to the chart in the upper-right corner of the FEGLI
                page){" "}
              </em>
            </strong>
            is that Option B is based on your age and continues to go up in
            price every five years. Now the rate you're paying today isn't
            necessarily a bad rate, the problem is that it continues to
            increase.
          </li>
          <br></br>
          <li>
            Most people are seeing a price chart for FEGLI for the first time
            when I send them their workbook and it really surprises most people
            just how expensive it gets. – So years later when you're in your
            50's, 60's, and 70's, the cost is significantly more than it is
            today and hardly anyone can afford it.
          </li>
          <br></br>
          <li>
            By the time you're Age 65, your rate would be ($137/pp, $298.35/mo,
            $3580/yr). Obviously at that point Option B won't be something
            you'll want to pay for, especially since the premium continues to go
            up even more. By the time you get to Age 70 it's $244.80/pp,
            $530.40/mo, or $6364/yr.
          </li>
          <br></br>
          <li>
            Do you think this is something you could continue to pay for in your
            retirement?{" "}
          </li>
          <br></br>
          <li>
            When we meet with someone who has had Option B for a long time, we
            often see that they've paid $20,000 - $40,000 for it over the years
            and they won't even have it for the long run.
          </li>
          <br></br>
          <li>
            Part of what we do for Federal Employees, is help them "stop their
            rate from changing." And make sure they have the right amount of
            coverage when they need it. So basically "we're stopping the
            bleeding" and affording you the ability of holding on to that
            coverage for the length of time that makes sense for you and your
            family.{" "}
          </li>
          <br></br>
          <li>
            Do you have any questions?{" "}
            <strong>
              <em>
                (Note: Proceed directly to this question, do not linger on the
                Option B section. It's important to not let them take control of
                your presentation, and definitely not show your cards at this
                point. Acknowledge the question(s), validate the concern, and
                keep going. Example: "That's an excellent question – we can
                address that later.)
              </em>
            </strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>Option C – Family</em>
          </strong>
        </p>
        <ol start="29" type="1">
          <li>
            Option C doesn't provide any coverage for you, but it does provide
            coverage for a spouse and any children under the age of 22 or
            dependent children of any age who are disabled. It provides $5,000
            per multiple for a spouse and $2,500 per multiple for children.
            Since you have elected 5 multiples you have $25,000 coverage on your
            spouse in the event something happens to him/her and $12,500 on each
            child until they reach the age of 22.<strong></strong>
          </li>
          <br></br>
          <li>
            Let's take a quick look at Option C. Just like Option B, the cost of
            Option C also goes up every 5 years. While it's not as dramatic of a
            change as Option B, since the amount of coverage is much less,
            paying more than you need to for something or paying for something
            you don't need at all definitely doesn't make sense.
          </li>
          <br></br>
          <li>
            Do you have any questions about any of the coverage you currently
            have? Do you need that much coverage?<strong></strong>
          </li>
          <li>
            At retirement or age 65, whichever is later, things start changing.
            At this point your Basic Life -$53,000 + Option A -$10,000, will
            drop by 75% in value over a couple of years. This means your Basic
            coverage goes from $53,000 down to $13,250 and your Option A
            coverage down from $10,000 to $2,500.<strong></strong>
          </li>
          <br></br>
          <li>
            Keep in mind this is still a free benefit and the fact that it drops
            down to $13,250 isn't so terrible, the real problem is that most of
            you don't find out about it until it's too late to do anything about
            it. The other issue is that at age 90, $13,250 will not feel like it
            does today. Most people want to make sure that no one in their
            family will have to worry about paying for their final expenses.
            With everything getting more expensive all the time, $13,250
            probably won't be anywhere near enough to take care of things when
            you're 90.
            <strong></strong>
          </li>
          <br></br>
          <li>
            Now let's look at your $255,000 of Option B coverage. We've already
            determined there will come a time when you can't afford to keep this
            coverage in place. So basically, you pay on it, and pay on it, and
            then when it gets too expensive you get rid of it – but you don't
            get any of your money back that you paid into it.. The question
            becomes; how long do you keep paying into it before you finally
            determine this isn't something you should keep.<strong></strong>
          </li>
          <br></br>
          <li>
            Now I'm going to add up your Total Life Insurance coverage to give
            you some idea of where you're at. You have a Total of $355,000 of
            coverage today. I did include this Extra Benefit of $37,100 of
            coverage you get for free, but remember what we talked about, this
            is on a sliding scale, and by the time you reach Age 45, it's gone.{" "}
            <strong></strong>
          </li>
          <br></br>
          <li>
            Do you have any questions?<strong></strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>
              Press <span style={{ color: "#0C0" }}>CONTINUE</span> and move to
              the FERS Section of the BPG.
            </em>
          </strong>
        </p>
      </div>
    </div>
  );
};

export default Fegli;
