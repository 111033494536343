import { Button, Modal } from "react-bootstrap";

const SaveAlert = ({ showModal, setShowModal, handleStatus }) => {
  return (
    <Modal
      size="sm"
      onHide={() => handleStatus("discard")}
      show={showModal}
      dialogClassName="SaveAlertModal"
    >
      <Modal.Header
        style={{
          background: "#555",
          color: "white",
          padding: "15px",
        }}
      >
        {" "}
        <div style={{ fontSize: "18px" }}>
          {" "}
          You've made changes. Save or discard changes:
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex ">
        <Button
          className=" mx-1 success-button"
          onClick={() => handleStatus("save")}
        >
          Save Changes
        </Button>
        <Button
          className=" mx-1 discard-button"
          onClick={() => handleStatus("discard")}
        >
          Discard Changes
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SaveAlert;
