import React from "react";

const Fers = () => {
  return (
    <div className="article-section">
      <div className="container">
        <p className="fw-bold">
          <u>SECTION IV</u> - Retirement Review<br></br>FERS
        </p>
        <p>
          Now let's take a look at your pension. You're covered by FERS – the
          Federal Employee Retirement System. Remember this part on your pay
          stub?{" "}
          <em>
            (Reference the pay stub "RETIRE" line item and "USPS Retirement").
          </em>
        </p>
        <ol start="37" type="1">
          <li>
            Your income at retirement is based on two factors. One is your High
            Three Average. This is the average of your three consecutive highest
            years of salary while working. For most people it will be their last
            three years before retirement. The second factor is your years of
            creditable service, the actual number of years of service that you
            have with the government as a career employee.{" "}
          </li>
          <p>
            What age do you want to retire?{" "}
            <em>
              (Input their response into the BPG.  <strong>BUT</strong>, make
              sure they meet the requirements for Retirement)
            </em>
          </p>
          <br></br>
          <li>
            Let's determine what you could expect to earn when you retire as a
            FERS employee at Age ______.  We'll use your current rate of pay,
            from your pay stub to calculate what you could expect to earn in
            Retirement.{" "}
          </li>
          <br></br>
          <li>
            Notice that of the three numbers I chose the smaller one{" "}
            <em>
              (No Survivor, 25% Survivor and 50% Survivor in the BPG. Note, if
              they are married, the default is the 50% Survivor Benefit)
            </em>
            . The difference in the three numbers is what you would give up for
            the benefit of continuing part of your retirement check to your
            spouse if you die before they do during retirement. For most
            families this is not something they can live without.  When you get
            close to retirement, we'll go over these options with you in more
            detail.
          </li>
        </ol>
        <p>
          <strong>
            <em>
              Press <span className="green">CONTINUE</span> and move into page 2
              of the FERS portion of the BPG
            </em>
          </strong>
        </p>
      </div>
    </div>
  );
};

export default Fers;
