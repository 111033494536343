import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { Form, Card, FloatingLabel, Row, Col } from "react-bootstrap";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  convertValue,
  formatCalculatedValue,
  showValue,
} from "../../../services/helperService";
import { usePlannerContext } from "../../../contexts/planner.contexts";

const FerAnnuityForm = ({ validated, submit }) => {
  const { plannerData, handleOnchangeCheckbox, setOnChangeStatus } =
    usePlannerContext();

  const [formData, setformData] = useState({});
  const [error, setError] = useState({ fers: false });
  const [fersBasicAnnuitySta, setFersBasicAnnuitySta] = useState();

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      const fersAnnuityArr = [
        plannerData["fersBasicAnnuity"],
        plannerData["fersBasicAnnuity95"],
        plannerData["fersBasicAnnuity90"],
      ];
      const valueToUse = plannerData["valueToUse"]
        ? plannerData["valueToUse"] - 1
        : 0;
      setFersBasicAnnuitySta(fersAnnuityArr[valueToUse]);

      if (plannerData && plannerData["retirement_date_unknown"] == 1) {
        handleFersCheckbox(true, plannerData);
      } else {
        handleFersCheckbox(false, plannerData);
      }
    }
  }, [plannerData]);

  const handleFersCheckbox = (isChecked, data = formData) => {
    if (JSON.stringify(data) === JSON.stringify(formData)) {
      setOnChangeStatus(true);
    }
    setError({ fers: false });
    let payload = {
      ...data,
      retirement_date_unknown: isChecked ? 1 : 0,
    };
    let servicePayload = {};

    const retiermentYears = data["retirementYears"]
      ? data["retirementYears"]
      : 0;
    const retirementMonths = data["retirementMonths"]
      ? data["retirementMonths"]
      : 0;
    let s2s5_age_to_start_taking_income = "";
    if (data["retirementMonths"] == "0") {
      s2s5_age_to_start_taking_income = retiermentYears;
    } else {
      s2s5_age_to_start_taking_income =
        retiermentYears + " & " + retirementMonths + " mos. ";
    }

    if (isChecked) {
      let newMRA = parseFloat(data["retirementYears"]);
      newMRA += data["retirementMonths"]
        ? parseFloat(data["retirementMonths"]) / 12
        : 0;

      payload = {
        ...payload,
        retiermentYears,

        MRA: newMRA,
        s2s5_age_to_start_taking_income,
        desired_retirement_date: data.retirementDate
          ? moment(data.retirementDate)
          : null,
        retirementMonths: data["retirementMonths"],
        retirementYears: data["retirementYears"],
      };
    }

    let service = getServices(payload);
    service = service && service > 0 ? convertValue(service) : 0;

    servicePayload = getServicePayload(service, payload);

    let diffMonth = moment(payload["desired_retirement_date"]).diff(
      moment(),
      "months"
    );

    if (diffMonth < 0) {
      diffMonth = 0;
    }

    let srsyears = service;

    if (
      plannerData.have_military_service == "yes" &&
      plannerData.s2s3_receiving_pension == "no"
    ) {
      let month = convertValue(plannerData.military_service_years) * 12;
      month = month + convertValue(plannerData.military_service_months);
      let year = month / 12;
      srsyears = srsyears - year;
    }
    const Srs =
      convertValue(plannerData["age_62_ss_benefit"]) * (srsyears / 40);

    setformData({
      ...payload,
      ...servicePayload,
      diffMonth: diffMonth ? diffMonth : 0,
      yearsOfService: service ? service.toFixed(2) : 0,
      yearsOfServiceat62: formatCalculatedValue(srsyears),
      Srs: formatCalculatedValue(Srs),
    });
  };

  const handleFersInput = (event, type) => {
    setOnChangeStatus(true);
    let dobdate = moment(formData["dob"]);
    let newMRA = 0;
    setError({ fers: false });
    if (dobdate.isValid()) {
      let payload = { ...formData };
      if (type == "date") {
        let selectedDate = moment(event);

        let diff = moment(formData["retirementDate"]).diff(
          selectedDate,
          "days"
        );

        if (diff > 0) {
          setError({ fers: true });
        }
        let years = selectedDate.diff(dobdate, "year");
        dobdate.add(years, "years");

        let months = selectedDate.diff(dobdate, "months");
        dobdate.add(months, "months");
        const s2s5_age_to_start_taking_income =
          months == "0" ? years : years + " & " + months + " mos. ";
        newMRA = parseFloat(years);
        newMRA += months ? parseFloat(months) / 12 : 0;

        payload = {
          ...payload,
          desired_retirement_date: selectedDate ? moment(selectedDate) : null,
          s2s5_age_to_start_taking_income,

          years,
        };
      }

      if (type == "income") {
        let value = "";
        let years = 0;
        let months = 0;
        if (event.target.value) {
          value = event.target.value.toString().replace("&amp;", "");
          value = event.target.value.toString().replace("mos.", "");
          value = value.split(" ").filter((num) => !isNaN(num) && Number(num));
          years = value[0] ? value[0] : 0;
          months = value[1] ? value[1] : 0;
        }
        let date = moment(formData["dob"]).add(years, "years");
        date = moment(date).add(months, "months");

        const totalMonths = parseFloat(years) * 12 + parseFloat(months);

        const decimalMonths = parseFloat(totalMonths) / 12;

        if (formData.MRA > decimalMonths) {
          setError({ fers: true });
        }
        newMRA = parseFloat(years);
        newMRA += months ? parseFloat(months) / 12 : 0;

        payload = {
          ...payload,
          MRA: newMRA,
          s2s5_age_to_start_taking_income: event.target.value,
          desired_retirement_date: date ? moment(date) : null,
          years: years,
        };
      }
      let service = getServices(payload);

      service = service && service > 0 ? convertValue(service) : 0;
      const ServicePayload = getServicePayload(service, payload);
      let diffMonth = moment(payload["desired_retirement_date"]).diff(
        moment(),
        "months"
      );

      if (diffMonth < 0) {
        diffMonth = 0;
      }

      if (
        (newMRA >= 56 && service >= 30) ||
        (newMRA >= 60 && service >= 20) ||
        (newMRA >= 62 && service >= 5)
      ) {
        payload = { ...payload, MRA: newMRA };
      } else {
        payload = { ...payload, MRA: payload["baseMRA"] };
      }
      let srsyears = service;

      if (
        plannerData.have_military_service == "yes" &&
        plannerData.s2s3_receiving_pension == "no"
      ) {
        let month = convertValue(plannerData.military_service_years) * 12;
        month = month + convertValue(plannerData.military_service_months);
        let year = month / 12;
        srsyears = srsyears - year;
      }
      const Srs = convertValue(formData["age_62_ss_benefit"]) * (srsyears / 40);

      payload = {
        ...payload,
        ...ServicePayload,
        yearsOfService: service ? service.toFixed(2) : 0,
        diffMonth: diffMonth ? diffMonth : 0,
        yearsOfServiceat62: formatCalculatedValue(srsyears),
        Srs: formatCalculatedValue(Srs),
      };
      setformData(payload);
    }
  };

  const getServices = (payload) => {
    let militaryYears = 0;
    if (
      payload &&
      payload["have_military_service"] == "yes" &&
      payload["s2s3_receiving_pension"] == "no"
    ) {
      let serviceYears = moment.duration({
        years: parseInt(payload["military_service_years"]),
        months: parseInt(payload["military_service_months"]),
      });
      militaryYears = serviceYears.asYears();
    }

    let service = "";
    if (militaryYears) {
      let startDate = moment(payload["lite_pay_start_year"])
        .subtract(militaryYears, "years")
        .format("YYYY-MM-DD");
      if (payload["desired_retirement_date"]) {
        service = moment(payload["desired_retirement_date"]).diff(
          startDate,
          "years",
          true
        );
      }
    } else {
      if (payload["desired_retirement_date"]) {
        service = moment(payload["desired_retirement_date"]).diff(
          payload["lite_pay_start_year"],
          "years",
          true
        );
      }
    }
    return convertValue(service);
  };

  const getServicePayload = (service, data = formData) => {
    let payload = {};

    if (data["special_provisions"] == "1") {
      let yearsOfService1 = "";
      let yearsOfService2 = "";
      if (service > 20) {
        yearsOfService1 = 20;
        yearsOfService2 = service - 20;
      } else {
        yearsOfService1 = service;
        yearsOfService2 = 0;
      }

      let finalValue1 = "";
      let finalValue2 = "";
      if (yearsOfService1) {
        let multiplier = convertValue(data["high3Avg"]);

        finalValue1 = (yearsOfService1 * multiplier * 1.7) / 100 / 12;
      }

      if (yearsOfService2) {
        let multiplier = convertValue(data["high3Avg"]);

        finalValue2 = (yearsOfService2 * multiplier * 1) / 100 / 12;
      }

      let fersBasicAnnuity = finalValue1 + finalValue2;
      const fersBasicAnnuity25 = fersBasicAnnuity * 0.25;
      const fersBasicAnnuity95 = fersBasicAnnuity * 0.95;
      const fersBasicAnnuity50 = fersBasicAnnuity * 0.5;
      const fersBasicAnnuity90 = fersBasicAnnuity * 0.9;

      const fersAnnuityArr = [
        fersBasicAnnuity,
        fersBasicAnnuity * 0.95,
        fersBasicAnnuity * 0.9,
      ];
      const valueToUse = plannerData["valueToUse"]
        ? plannerData["valueToUse"] - 1
        : 0;
      setFersBasicAnnuitySta(fersAnnuityArr[valueToUse]);

      payload = {
        yearsOfService1: formatCalculatedValue(yearsOfService1),
        yearsOfService2: formatCalculatedValue(yearsOfService2),
        fersBasicAnnuity1: formatCalculatedValue(finalValue1),
        fersBasicAnnuity2: formatCalculatedValue(finalValue2),
        fersBasicAnnuity: formatCalculatedValue(fersBasicAnnuity),
        fersBasicAnnuity25: formatCalculatedValue(fersBasicAnnuity25),
        fersBasicAnnuity95: formatCalculatedValue(fersBasicAnnuity95),
        fersBasicAnnuity50: formatCalculatedValue(fersBasicAnnuity50),
        fersBasicAnnuity90: formatCalculatedValue(fersBasicAnnuity90),
      };
    } /*else if (service >= 20 && data["MRA"] >= 62) {
      payload = handlefersCalculations(
        service,
        "yearsOfService",

        true,
        data
      );
    } */ else {
      payload = handlefersCalculations(
        service,
        "yearsOfService",

        true,
        data
      );
    }

    return payload;
  };

  const handlefersCalculations = (
    value,
    name,
    isJustcalculations = false,
    values = formData
  ) => {
    if (!isJustcalculations) {
      setOnChangeStatus(true);
    }
    let precentage = 1;
    if (value >= 20 && values["MRA"] >= 62) {
      precentage = 1.1;
    }

    console.log(value);

    if (name == "yearsOfService" || name == "high3Avg") {
      let data = convertValue(value);
      let multiplier =
        name == "yearsOfService"
          ? convertValue(values["high3Avg"])
          : convertValue(values["yearsOfService"]);

      const finalValue = (data * multiplier * precentage) / 100 / 12;
      const fersBasicAnnuity25 = finalValue * 0.25;
      const fersBasicAnnuity95 = finalValue * 0.95;
      const fersBasicAnnuity50 = finalValue * 0.5;
      const fersBasicAnnuity90 = finalValue * 0.9;

      const fersAnnuityArr = [finalValue, finalValue * 0.95, finalValue * 0.9];
      const valueToUse = plannerData["valueToUse"]
        ? plannerData["valueToUse"] - 1
        : 0;
      setFersBasicAnnuitySta(fersAnnuityArr[valueToUse]);

      let payload = {
        [name]: value,
        fersBasicAnnuity: formatCalculatedValue(finalValue),
        fersBasicAnnuity25: formatCalculatedValue(fersBasicAnnuity25),
        fersBasicAnnuity95: formatCalculatedValue(fersBasicAnnuity95),
        fersBasicAnnuity50: formatCalculatedValue(fersBasicAnnuity50),
        fersBasicAnnuity90: formatCalculatedValue(fersBasicAnnuity90),
      };
      if (isJustcalculations) {
        return payload;
      } else {
        setformData({
          ...values,
          ...payload,
        });
      }
    }
  };

  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="client-survey-section">
        <div className="qs-box-survey single-screen">
          <h1 className="question-survey">FERS ANNUITY</h1>
          <Form
            id="form_client_survey"
            name="fersAnnuity"
            className="subform"
            noValidate
            onSubmit={submitFormdata}
          >
            <div className="answers-fers-Ann">
              <div className="sub-section-fers-Ann">
                <h1 className="question-fers-Ann">Retirement Date</h1>
                <div className="single-field-ann">
                  <div>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="What age do you want to retire"
                      className="my-3"
                    >
                      <Form.Control
                        autoComplete="off"
                        className="fers-input required-input"
                        type="text"
                        name="s2s5_age_to_start_taking_income"
                        placeholder="What age do you want to retire"
                        onChange={(e) => handleFersInput(e, "income")}
                        value={formData.s2s5_age_to_start_taking_income}
                        isInvalid={
                          validated && !formData.s2s5_age_to_start_taking_income
                        }
                        disabled={formData.retirement_date_unknown == "1"}
                        required
                      />
                    </FloatingLabel>
                  </div>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Desired retirement date"
                        value={
                          formData["desired_retirement_date"]
                            ? formData["desired_retirement_date"]
                            : null
                        }
                        minDate={
                          formData.retirementDate
                            ? moment(formData.retirementDate)
                            : moment()
                        }
                        disabled={formData.retirement_date_unknown == "1"}
                        className={`required-input custome-picker w-100 floatingInput ${
                          (validated && !formData.desired_retirement_date) ||
                          (validated &&
                            moment(
                              formData.desired_retirement_date
                                ? formData.desired_retirement_date
                                : moment()
                            ) &&
                            moment(
                              formData.desired_retirement_date
                                ? formData.desired_retirement_date
                                : moment()
                            ).isBefore(moment().subtract(1, "days")))
                            ? "is-invalid"
                            : ""
                        } `}
                        onChange={(date) => handleFersInput(date, "date")}
                        slotProps={{
                          textField: {
                            required: true,
                          },
                        }}
                      />
                    </LocalizationProvider>

                    {error && error["fers"] && (
                      <>
                        <p className="field-bottom-title">
                          <b>Disclaimer : </b>The date you have chosen has past.
                          Please choose a future date as your retirement date.
                        </p>
                        <p className="field-bottom-title">
                          <b>Disclaimer : </b>Date is before the MRA date of{" "}
                          {moment(formData["retirementDate"]).format(
                            "MM/DD/YYYY"
                          )}{" "}
                          please enter a date on or after this date
                        </p>
                      </>
                    )}
                    {/* </FloatingLabel> */}
                  </div>
                  <div className="text-align-start">
                    <div className="d-flex align-items-center  mt-3">
                      <div>
                        <Form.Check
                          id="retirement_date_unknown"
                          className="px-2"
                          name="retirement_date_unknown"
                          checked={
                            formData["retirement_date_unknown"] == "1"
                              ? true
                              : false
                          }
                          onChange={(e) => handleFersCheckbox(e.target.checked)}
                        />{" "}
                      </div>

                      <div className="d-flex align-items-center checkbox-text">
                        <label htmlFor="retirement_date_unknown">
                          Check if the retirement date is unknown
                        </label>
                      </div>
                    </div>
                  </div>
                  {formData["s2s5_age_to_start_taking_income"] &&
                    formData["desired_retirement_date"] &&
                    formData["MRA"] > 62 && (
                      <div className="d-flex align-items-center">
                        <div className="mb-2 mx-4">
                          <div className="mx-2 text-about">
                            Have you obtained your retirement materials and
                            Annuity estimate?
                          </div>
                        </div>
                        <div className="mx-2 d-flex">
                          <div className="mx-2 d-flex align-items-center  justify-content-center">
                            <input
                              id="s2s5_have_you_obtainedYes"
                              type="radio"
                              className="mx-1"
                              name="s2s5_have_you_obtained"
                              checked={formData.s2s5_have_you_obtained == "yes"}
                              onChange={(event) => {
                                handleOnchangeCheckbox(
                                  event,
                                  "yes",
                                  setformData
                                );
                              }}
                            />{" "}
                            <label
                              className="text-about"
                              htmlFor="s2s5_have_you_obtainedYes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="mx-2 d-flex align-items-center  justify-content-center">
                            <input
                              id="s2s5_have_you_obtainedNo"
                              type="radio"
                              className="mx-1"
                              name="s2s5_have_you_obtained"
                              checked={formData.s2s5_have_you_obtained == "no"}
                              onChange={(event) => {
                                handleOnchangeCheckbox(
                                  event,
                                  "no",
                                  setformData
                                );
                              }}
                            />{" "}
                            <label
                              className="text-about"
                              htmlFor="s2s5_have_you_obtainedNo"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="sub-section-fers-Ann">
              <h1 className="question-fers-Ann">Retirement Date</h1>
              <div className="single-field-ann">
                <Row>
                  <Col xs={12} md={3} sm={6} className="panel-container">
                    <div className="panel panel-primary">
                      <div className="panel-heading-fers">
                        <strong>Your Retirement Date</strong>
                      </div>
                      <div className="panel-body">
                        <h4 className="text-center">
                          <strong className="fers_annuity_your_date">
                            {formData["desired_retirement_date"] &&
                            moment(
                              formData["desired_retirement_date"]
                            ).isValid()
                              ? moment(
                                  formData["desired_retirement_date"]
                                ).format("MM-DD-YYYY")
                              : "-"}
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={3} sm={6} className="panel-container">
                    <div className="panel panel-primary">
                      <div className="panel-heading-fers">
                        <strong>Months to Retirement</strong>
                      </div>
                      <div className="panel-body">
                        <h4 className="text-center">
                          <strong className="fers_annuity_months_of_retirement">
                            {formData.diffMonth}
                            {/* Months to Retirement */}
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={3} sm={6} className="panel-container">
                    <div className="panel panel-primary">
                      <div className="panel-heading-fers">
                        <strong>Years of Service</strong>
                      </div>
                      <div className="panel-body">
                        <h4 className="text-center">
                          <strong className="fers_annuity_years_of_service">
                            {formatCalculatedValue(formData["yearsOfService"])}
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={3} sm={6} className="panel-container">
                    <div className="panel panel-primary">
                      <div className="panel-heading-fers">
                        <strong>Age at Retirement</strong>
                      </div>
                      <div className="panel-body">
                        <h4 className="text-center">
                          <strong className="fers_annuity_age_to_retirement">
                            {formData["s2s5_age_to_start_taking_income"]
                              ? formData["s2s5_age_to_start_taking_income"]
                              : "-"}
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="sub-section-fers-Ann">
              <h1 className="question-fers-Ann">
                Special Retirement Supplement
              </h1>
              <div className="single-field-ann">
                <Row>
                  <Col xs={12} md={3} sm={6} className="panel-container">
                    <div className="panel panel-primary-ann">
                      <div className="panel-heading-fers">
                        <strong>SRS ELIGIBLE</strong>
                      </div>
                      <div className="panel-body">
                        <h4 className="text-center">
                          <strong className="srsAmount">
                            {formData["MRA"] < 62 &&
                            ((formData["MRA"] >= 60 &&
                              formData["yearsOfService"] >= 20) ||
                              (formData["MRA"] < 60 &&
                                formData["yearsOfService"] >= 30)) ? (
                              <span>${formData["Srs"]}</span>
                            ) : formData["MRA"] < 62 &&
                              formData["special_provisions"] == "1" &&
                              ((formData["MRA"] >= 50 &&
                                formData["yearsOfService"] >= 20) ||
                                formData["yearsOfService"] >= 30) ? (
                              <span>${formData["Srs"]}</span>
                            ) : (
                              <span className="fers-span">
                                Not eligible for SRS.
                              </span>
                            )}
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={3} sm={6} className="panel-container">
                    <div
                      className="panel panel-primary-ann"
                      style={{
                        borderColor: "#8fc97c !important",
                      }}
                    >
                      <div className="panel-heading-fers">
                        <strong>Monthly Annuity</strong>
                      </div>
                      <div className="panel-body">
                        <h4 className="text-center">
                          <strong className="srsAmount">
                            {showValue(fersBasicAnnuitySta)}
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="btn-wrapper">
              <div className="text-center mt-4">
                <button className="btn-next btn question-next" type="submit">
                  CONTINUE
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Card.Body>
    </>
  );
};

export default FerAnnuityForm;
