export const salesType = [
  { label: "Annuity", value: "annuity" },
  { label: "Life", value: "life" },
];

export const presentationType = [
  { label: "In-Person", value: "In-Person" },
  { label: "Virtual", value: "Virtual" },
];

export const annuityProduct = [
  {
    label: "Prosperity Elite - Fidelity Guarantee Life",
    value: "Prosperity Elite - Fidelity Guarantee Life",
  },
  { label: "FlexMark - Ameritas", value: "FlexMark - Ameritas" },
  {
    label: "Personal Income Annuity (PIA) - Nassau",
    value: "Personal Income Annuity (PIA) - Nassau",
  },
  {
    label: "Personal Protection Choice (PPC) - Nassau",
    value: "Personal Protection Choice (PPC) - Nassau",
  },
  {
    label: "Nassau Growth Annuity (NGA) - Nassau",
    value: "Nassau Growth Annuity (NGA) - Nassau",
  },
  {
    label: "Nassau Bonus Annuity (NBA)",
    value: "Nassau Bonus Annuity (NBA)",
  },
];

export const lifeProducts = [
  { label: "Capital Legacy", value: "Capital Legacy" },
  { label: "Capital Legacy + OBA", value: "Capital Legacy + OBA" },
  { label: "Financial Lifeline", value: "Financial Lifeline" },
  { label: "OBA", value: "OBA" },
  { label: "Senior Choice", value: "Senior Choice" },
];

export const applicationStatus = [
  { label: "Pending IGO", value: "Pending IGO" },
  { label: "Pending NIGO", value: "Pending NIGO" },
  { label: "Issued", value: "Issued" },
  { label: "Canceled", value: "Canceled" },
  { label: "Declined", value: "Declined" },
  { label: "Replaced", value: "Replaced" },
];

export const AboutFormList = [
  "When can I retire?",
  "Can I afford to retire?",
  "How much will I get from my retirement?",
  "What about healthcare in retirement?",
  "How long will my money last?",
  "Make sure my survivors are taken care of if I die",
  "Other",
];

export const ageRelatedScreens = [
  {
    code: "retirement",
    completed: 0,
    title: "RETIREMENT",
    main: "",
  },
  {
    code: "retirement-s2",
    completed: 0,
    title: "RETIREMENT",
    main: "retirement",
  },
  {
    code: "more-about-you",
    completed: 0,
    title: "MORE ABOUT YOU",
    main: "",
  },
  {
    code: "more-about-you-s2",
    completed: 0,
    title: "MORE ABOUT YOU (continued)",
    main: "more-about-you",
  },
  {
    code: "fers-annuity",
    completed: 0,
    title: "FERS ANNUITY",
    main: "",
  },
  {
    code: "tsp-ret",
    completed: 0,
    title: "THRIFT SAVINGS PLAN",
    main: "",
  },
  {
    code: "pensions-and-income",
    completed: 0,
    title: "PENSIONS & INCOME",
    main: "",
  },
  {
    code: "expenses",
    completed: 0,
    title: "EXPENSE ANALYSIS",
    main: "",
  },
  {
    code: "es-ret",
    completed: 0,
    title: "EMERGENCY SAVINGS",
    main: "",
  },
  {
    code: "tsp-options",
    completed: 0,
    title: "TSP W/D OPTIONS",
    main: "",
  },
  {
    code: "conclusion",
    completed: 0,
    title: "CONCLUSION",
    main: "",
  },
];

export const commonScreen1 = [
  {
    code: "pay",
    completed: 0,
    title: "PAY",
    main: "",
  },
  {
    code: "pay-s2",
    completed: 0,
    title: "PAY (continued)",
    main: "pay",
  },
  {
    code: "fegli",
    completed: 0,
    title: "FEGLI",
    main: "",
  },
  {
    code: "fers",
    completed: 0,
    title: "FERS",
    main: "",
  },
  {
    code: "fers-s2",
    completed: 0,
    title: "FERS (continued)",
    main: "fers",
  },
  {
    code: "tsp",
    completed: 0,
    title: "TSP",
    main: "",
  },
  {
    code: "ss",
    completed: 0,
    title: "SOCIAL SECURITY",
    main: "",
  },
  {
    code: "es",
    completed: 0,
    title: "RAINY DAY FUND",
    main: "",
  },
  {
    code: "gap",
    completed: 0,
    title: "FILLING THE GAP",
    main: "",
  },
  {
    code: "summary",
    completed: 0,
    title: "SUMMARY",
    main: "",
  },
  {
    code: "benefits",
    completed: 0,
    title: "BENEFITS",
    main: "",
  },
];

export const commonScreen2 = [
  {
    code: "client-survey",
    completed: 0,
    title: "CLIENT SURVEY",
    main: "",
  },
  {
    code: "protection",
    completed: 0,
    title: "PROTECTION & ROTH IRA",
    main: "",
  },
  {
    code: "email-report",
    completed: 0,
    title: "REPORTS & DOCS",
    main: "",
  },
];

export const commonScreenSection = [...commonScreen1, ...commonScreen2];

export const initialAvailableScreens = [
  ...commonScreen1,
  ...ageRelatedScreens,
  ...commonScreen2,
];

export const basicTabs = ["welcome", "welcome2"];

export const intitialTabs = [
  ...basicTabs,
  "pay",
  "pay-s2",
  "fegli",
  "fers",
  "fers-s2",
  "tsp",
  "ss",
  "es",
  "gap",
  "summary",
  "benefits",
  "retirement",
  "retirement-s2",
  "more-about-you",
  "more-about-you-s2",
  "fers-annuity",
  "tsp-ret",
  "pensions-and-income",
  "expenses",
  "es-ret",
  "tsp-options",
  "conclusion",
  "protection",
  "client-survey",
  "email-report",
  "congrats",
  "benefits-overview",
];
