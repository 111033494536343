import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, FloatingLabel } from "react-bootstrap";
import {
  convertValue,
  formatCalculatedValue,
} from "../../../services/helperService";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { usePlannerContext } from "../../../contexts/planner.contexts";

const Fers = ({ validated, submit }) => {
  const {
    plannerData,
    onChangeHandler,
    handleOnchangeCheckbox,
    handleBlur,
    handleKeyPress,
    setOnChangeStatus,
    handleFocus,
  } = usePlannerContext();

  const [formData, setformData] = useState({});
  const [error, setError] = useState({ fers: false });

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      if (plannerData && plannerData["retirement_date_unknown"] == 1) {
        handleFersCheckbox(true, plannerData);
      } else {
        handleFersCheckbox(false, plannerData);
      }
    }
    console.log(plannerData);
  }, [plannerData]);

  const calculateMRAandShowIt = (planData, isUpdateMRA = false) => {
    const dobDate = moment(planData["dob"]);
    const age = moment().diff(dobDate, "years", true);

    let militaryYears = 0;

    if (
      planData &&
      planData["have_military_service"] == "yes" &&
      planData["s2s3_receiving_pension"] == "no"
    ) {
      let serviceYears = moment.duration({
        years: parseInt(planData["military_service_years"]),
        months: parseInt(planData["military_service_months"]),
      });
      militaryYears = serviceYears.asYears();
    }

    let service = "";
    if (militaryYears) {
      let startDate = moment(planData["lite_pay_start_year"])
        .subtract(militaryYears, "years")
        .format("YYYY-MM-DD");

      service = moment(startDate).diff(dobDate, "years", true);
    } else {
      service = moment(planData["lite_pay_start_year"]).diff(
        dobDate,
        "years",
        true
      );
    }

    const bornYear = dobDate.year();

    let { MRA, yos } = getMRA(bornYear, service, planData);

    MRA = MRA / 12;
    yos = yos / 12;

    let retirementDate = "";
    if (MRA) {
      let remainYears = MRA - age;
      retirementDate = moment(dobDate)
        .add(remainYears, "years")
        .format("YYYY-MM-DD");
      if (remainYears > 0) {
        retirementDate = moment(dobDate).add(MRA, "years").format("YYYY-MM-DD");
        if (MRA % parseInt(MRA) == 0) {
          let retDate = moment(retirementDate);
          let db = moment(dobDate);
          retirementDate = retDate.format("YYYY") + "-" + db.format("MM-DD");
        } else {
          let retDate = moment(retirementDate);
          let db = moment(planData["lite_pay_start_year"]);
          retirementDate = retDate.format("YYYY") + "-" + db.format("MM-DD");
        }
      } else {
        let dateDifference = moment().diff(retirementDate, "years");
        if (dateDifference > 0) {
          retirementDate = moment(dobDate)
            .add(age, "years")
            .format("YYYY-MM-DD");
          retirementDate = moment().format("YYYY-MM-DD");
          MRA = age;
        }
      }
    }

    let startAge = moment(retirementDate).set({
      hour: 0,
      minute: 0,
      second: 0,
    });

    if (planData["retirementDate"]) {
      let diff = moment(planData["retirementDate"]).diff(startAge, "days");

      if (diff > 0) {
        // setError({ fers: true });
      }
    }

    let years = startAge.diff(dobDate, "year");
    dobDate.add(years, "years");

    let months = startAge.diff(dobDate, "months");

    dobDate.add(months, "months");
    let s2s5_age_to_start_taking_income = "";
    if (months == "0") {
      s2s5_age_to_start_taking_income = years ? years : 0;
    } else {
      s2s5_age_to_start_taking_income =
        (years ? years : 0) + " & " + (months ? months : 0) + " mos. ";
    }

    let payload = {
      yearsOfService: planData["yearsOfService"]
        ? convertValue(planData["yearsOfService"])
        : 0,
      s2s5_age_to_start_taking_income: isUpdateMRA
        ? s2s5_age_to_start_taking_income
        : planData["s2s5_age_to_start_taking_income"]
        ? planData["s2s5_age_to_start_taking_income"]
        : "",
      desired_retirement_date: planData["desired_retirement_date"]
        ? moment(planData["desired_retirement_date"])
        : null,
    };

    if (planData["retirement_date_unknown"] == "1") {
      let s2s5_age_to_start_taking_income = "";
      if (months == "0") {
        s2s5_age_to_start_taking_income = years ? years : 0;
      } else {
        s2s5_age_to_start_taking_income =
          (years ? years : 0) + " & " + (months ? months : 0) + " mos. ";
      }
      let MRA = parseFloat(years);
      MRA += months ? parseFloat(months) / 12 : 0;
      payload = {
        s2s5_age_to_start_taking_income,
        MRA,
        desired_retirement_date: retirementDate ? moment(retirementDate) : null,
        yearsOfService: yos ? formatCalculatedValue(yos) : 0,
        years: years,
      };
    }

    let diffMonth = moment(payload["desired_retirement_date"]).diff(
      moment(),
      "months"
    );

    if (diffMonth < 0) {
      diffMonth = 0;
    }
    let servicePayload = getServicePayload(service, payload);

    let srsyears = payload["yearsOfService"]
      ? convertValue(payload["yearsOfService"])
      : 0;

    if (
      planData.have_military_service == "yes" &&
      planData.s2s3_receiving_pension == "no"
    ) {
      let month = convertValue(plannerData.military_service_years) * 12;
      month = month + convertValue(plannerData.military_service_months);
      let year = month / 12;
      srsyears = srsyears - year;
    }
    let age_62_ss_benefit = planData["age_62_ss_benefit"]
      ? convertValue(planData["age_62_ss_benefit"])
      : 1300;
    const Srs = convertValue(age_62_ss_benefit) * (srsyears / 40);

    return {
      yearsOfService: yos ? yos : formatCalculatedValue(yos),
      yos: convertValue(yos),
      retirementDate,
      diffMonth: diffMonth ? diffMonth : 0,
      MRA,
      baseMRA: MRA,
      Srs: formatCalculatedValue(Srs),
      age_62_ss_benefit: formatCalculatedValue(age_62_ss_benefit),
      yearsOfServiceat62: formatCalculatedValue(srsyears),
      retirementYears: years,
      retirementMonths: months,
      ...servicePayload,
      ...payload,
    };
  };

  const getMRA = (bornYear, CareereYear, planData) => {
    let startDate = CareereYear ? CareereYear : 1;

    startDate = startDate * 12;

    startDate = Math.round(startDate);
    let countedAge = startDate;
    let yos = 0;

    let mraAge = 0;

    if (bornYear < 1948) {
      mraAge = 662;
    }
    if (bornYear == 1949) {
      mraAge = 664;
    }
    if (bornYear == 1950) {
      mraAge = 666;
    }
    if (bornYear == 1951) {
      mraAge = 668;
    }
    if (bornYear >= 1953 && bornYear <= 1964) {
      mraAge = 672;
    }
    if (bornYear == 1965) {
      mraAge = 674;
    }
    if (bornYear == 1966) {
      mraAge = 676;
    }
    if (bornYear == 1967) {
      mraAge = 678;
    }
    if (bornYear == 1968) {
      mraAge = 680;
    }
    if (bornYear == 1969) {
      mraAge = 682;
    }
    if (bornYear >= 1970) {
      mraAge = 684;
    }

    if (countedAge) {
      while (true) {
        if (planData["special_provisions"] == "1") {
          if ((countedAge >= 600 && yos >= 240) || yos >= 300) break;
        } else {
          if (
            (countedAge >= 744 && yos >= 60) ||
            (countedAge >= 720 && yos >= 240) ||
            (countedAge >= mraAge && yos >= 360)
          )
            break;
        }

        countedAge++;
        yos++;
      }
    }

    if (
      planData["desired_retirement_date"] &&
      planData["retirement_date_unknown"] == 0
    ) {
      let dobdate = moment(planData["dob"]);
      let selectedDate = moment(planData["desired_retirement_date"]);

      let years = selectedDate.diff(dobdate, "year");
      dobdate.add(years, "years");

      let months = selectedDate.diff(dobdate, "months");
      countedAge = parseFloat(years * 12);
      countedAge += months ? parseFloat(months) : 0;

      let joinDate = moment(planData["lite_pay_start_year"]);

      let yosyears = selectedDate.diff(joinDate, "year");

      let yosmonths = selectedDate.diff(joinDate, "months");
      yos = parseFloat(yosyears * 12);
      yos += months ? parseFloat(yosmonths) : 0;
    }

    return { MRA: countedAge, yos };
  };

  const handleFersCheckbox = (isChecked, data = formData) => {
    if (JSON.stringify(data) === JSON.stringify(formData)) {
      setOnChangeStatus(true);
    }
    setError({ fers: false });
    let payload = {
      ...data,
      retirement_date_unknown: isChecked ? 1 : 0,
    };
    let servicePayload = {};

    data = calculateMRAandShowIt(payload);

    const retiermentYears = data["retirementYears"]
      ? data["retirementYears"]
      : 0;
    const retirementMonths = data["retirementMonths"]
      ? data["retirementMonths"]
      : 0;
    let s2s5_age_to_start_taking_income = "";
    if (data["retirementMonths"] == "0") {
      s2s5_age_to_start_taking_income = retiermentYears;
    } else {
      s2s5_age_to_start_taking_income =
        retiermentYears + " & " + retirementMonths + " mos. ";
    }

    if (isChecked) {
      let newMRA = parseFloat(data["retirementYears"]);
      newMRA += data["retirementMonths"]
        ? parseFloat(data["retirementMonths"]) / 12
        : 0;

      payload = {
        ...payload,
        retiermentYears,

        MRA: newMRA,
        s2s5_age_to_start_taking_income,
        desired_retirement_date: data.retirementDate
          ? moment(data.retirementDate)
          : null,
        retirementMonths: data["retirementMonths"],
        retirementYears: data["retirementYears"],
      };
    }

    let service = getServices(payload);
    service = service && service > 0 ? convertValue(service) : 0;
    servicePayload = getServicePayload(service, payload);
    let diffMonth = moment(payload["desired_retirement_date"]).diff(
      moment(),
      "months"
    );

    if (diffMonth < 0) {
      diffMonth = 0;
    }

    let srsyears = service;

    if (
      plannerData.have_military_service == "yes" &&
      plannerData.s2s3_receiving_pension == "no"
    ) {
      let month = convertValue(plannerData.military_service_years) * 12;
      month = month + convertValue(plannerData.military_service_months);
      let year = month / 12;
      srsyears = srsyears - year;
    }
    const Srs =
      convertValue(plannerData["age_62_ss_benefit"]) * (srsyears / 40);

    setformData({
      ...payload,
      ...servicePayload,
      diffMonth: diffMonth ? diffMonth : 0,
      yearsOfService: service ? service.toFixed(2) : 0,
      yearsOfServiceat62: formatCalculatedValue(srsyears),
      Srs: formatCalculatedValue(Srs),
    });
  };

  const handleProvisionCalculations = (
    value,
    name,
    year,
    isJustcalculations = false
  ) => {
    if (!isJustcalculations) {
      setOnChangeStatus(true);
    }
    let precentage = parseFloat(year);
    let finalValue1 = 0;
    let finalValue2 = 0;

    if (
      name == "yearsOfService1" ||
      name == "yearsOfService2" ||
      name == "high3Avg"
    ) {
      let data = convertValue(value);
      let multiplier = "";
      if (name == "yearsOfService1") {
        multiplier =
          name == "yearsOfService1"
            ? convertValue(formData["high3Avg"])
            : convertValue(formData["yearsOfService1"]);

        finalValue1 = (data * multiplier * precentage) / 100 / 12;
      } else {
        finalValue1 =
          (convertValue(formData["high3Avg"]) *
            convertValue(formData["yearsOfService1"]) *
            1.7) /
          100 /
          12;
      }
      if (name == "yearsOfService2") {
        multiplier =
          name == "yearsOfService2"
            ? convertValue(formData["high3Avg"])
            : convertValue(formData["yearsOfService2"]);

        finalValue2 = (data * multiplier * precentage) / 100 / 12;
      } else {
        finalValue2 =
          (convertValue(formData["high3Avg"]) *
            convertValue(formData["yearsOfService2"]) *
            1) /
          100 /
          12;
      }
      let fersBasicAnnuity = finalValue1 + finalValue2;
      const fersBasicAnnuity25 = fersBasicAnnuity * 0.25;
      const fersBasicAnnuity95 = fersBasicAnnuity * 0.95;
      const fersBasicAnnuity50 = fersBasicAnnuity * 0.5;
      const fersBasicAnnuity90 = fersBasicAnnuity * 0.9;
      const payload = {
        [name]: value,
        fersBasicAnnuity1: formatCalculatedValue(finalValue1),
        fersBasicAnnuity2: formatCalculatedValue(finalValue2),
        fersBasicAnnuity: formatCalculatedValue(fersBasicAnnuity),
        fersBasicAnnuity25: formatCalculatedValue(fersBasicAnnuity25),
        fersBasicAnnuity95: formatCalculatedValue(fersBasicAnnuity95),
        fersBasicAnnuity50: formatCalculatedValue(fersBasicAnnuity50),
        fersBasicAnnuity90: formatCalculatedValue(fersBasicAnnuity90),
      };

      if (isJustcalculations) {
        return payload;
      } else {
        setformData({
          ...formData,
          ...payload,
        });
      }
    }
  };

  const getServices = (payload) => {
    let militaryYears = 0;
    if (
      payload &&
      payload["have_military_service"] == "yes" &&
      payload["s2s3_receiving_pension"] == "no"
    ) {
      let serviceYears = moment.duration({
        years: parseInt(payload["military_service_years"]),
        months: parseInt(payload["military_service_months"]),
      });
      militaryYears = serviceYears.asYears();
    }

    let service = "";
    if (militaryYears) {
      let startDate = moment(payload["lite_pay_start_year"])
        .subtract(militaryYears, "years")
        .format("YYYY-MM-DD");
      if (payload["desired_retirement_date"]) {
        service = moment(payload["desired_retirement_date"]).diff(
          startDate,
          "years",
          true
        );
      }
    } else {
      if (payload["desired_retirement_date"]) {
        service = moment(payload["desired_retirement_date"]).diff(
          payload["lite_pay_start_year"],
          "years",
          true
        );
      }
    }
    return convertValue(service);
  };

  const getServicePayload = (service, data = formData) => {
    let payload = {};

    if (data["special_provisions"] == "1") {
      let yearsOfService1 = "";
      let yearsOfService2 = "";
      if (service > 20) {
        yearsOfService1 = 20;
        yearsOfService2 = service - 20;
      } else {
        yearsOfService1 = service;
        yearsOfService2 = 0;
      }

      let finalValue1 = "";
      let finalValue2 = "";
      if (yearsOfService1) {
        let multiplier = convertValue(data["high3Avg"]);

        finalValue1 = (yearsOfService1 * multiplier * 1.7) / 100 / 12;
      }

      if (yearsOfService2) {
        let multiplier = convertValue(data["high3Avg"]);

        finalValue2 = (yearsOfService2 * multiplier * 1) / 100 / 12;
      }

      let fersBasicAnnuity = finalValue1 + finalValue2;
      const fersBasicAnnuity25 = fersBasicAnnuity * 0.25;
      const fersBasicAnnuity95 = fersBasicAnnuity * 0.95;
      const fersBasicAnnuity50 = fersBasicAnnuity * 0.5;
      const fersBasicAnnuity90 = fersBasicAnnuity * 0.9;

      payload = {
        yearsOfService1: formatCalculatedValue(yearsOfService1),
        yearsOfService2: formatCalculatedValue(yearsOfService2),
        fersBasicAnnuity1: formatCalculatedValue(finalValue1),
        fersBasicAnnuity2: formatCalculatedValue(finalValue2),
        fersBasicAnnuity: formatCalculatedValue(fersBasicAnnuity),
        fersBasicAnnuity25: formatCalculatedValue(fersBasicAnnuity25),
        fersBasicAnnuity95: formatCalculatedValue(fersBasicAnnuity95),
        fersBasicAnnuity50: formatCalculatedValue(fersBasicAnnuity50),
        fersBasicAnnuity90: formatCalculatedValue(fersBasicAnnuity90),
      };
    } else if (service >= 20 && data["MRA"] >= 62) {
      payload = handlefersCalculations(
        service,
        "yearsOfService",

        true,
        data
      );
    } else {
      payload = handlefersCalculations(
        service,
        "yearsOfService",

        true,
        data
      );
    }

    return payload;
  };

  const handleFersInput = (event, type) => {
    setOnChangeStatus(true);
    let dobdate = moment(formData["dob"]);
    let newMRA = 0;
    setError({ fers: false });

    if (dobdate.isValid()) {
      let payload = { ...formData, ...calculateMRAandShowIt(formData) };
      if (type == "date") {
        let selectedDate = moment(event);

        let diff = moment(payload["retirementDate"]).diff(selectedDate, "days");

        if (diff > 0) {
          setError({ fers: true });
        }
        let years = selectedDate.diff(dobdate, "year");
        dobdate.add(years, "years");

        let months = selectedDate.diff(dobdate, "months");
        dobdate.add(months, "months");
        const s2s5_age_to_start_taking_income =
          months == "0" ? years : years + " & " + months + " mos. ";
        newMRA = parseFloat(years);
        newMRA += months ? parseFloat(months) / 12 : 0;
        payload = {
          ...payload,
          desired_retirement_date: selectedDate ? moment(selectedDate) : null,
          s2s5_age_to_start_taking_income,
          MRA: newMRA,
          years,
        };
      }

      if (type == "income") {
        let value = "";
        let years = 0;
        let months = 0;
        if (event.target.value) {
          value = event.target.value.toString().replace("&amp;", "");
          value = value.replace("mos.", "");
          value = value.split(" ").filter((num) => !isNaN(num) && Number(num));
          years = value[0] ? value[0] : 0;
          months = value[1] ? value[1] : 0;
        }
        let date = moment(payload["dob"]).add(years, "years");
        date = moment(date).add(months, "months");

        const totalMonths = parseFloat(years) * 12 + parseFloat(months);

        const decimalMonths = parseFloat(totalMonths) / 12;

        if (payload.MRA > decimalMonths) {
          setError({ fers: true });
        }
        newMRA = parseFloat(years);
        newMRA += months ? parseFloat(months) / 12 : 0;

        payload = {
          ...payload,
          s2s5_age_to_start_taking_income: event.target.value,
          desired_retirement_date: date ? moment(date) : null,
          years: years,
        };
      }
      let service = getServices(payload);

      service = service && service > 0 ? convertValue(service) : 0;

      let diffMonth = moment(payload["desired_retirement_date"]).diff(
        moment(),
        "months"
      );

      if (diffMonth < 0) {
        diffMonth = 0;
      }

      if (
        (newMRA >= 56 && service >= 30) ||
        (newMRA >= 60 && service >= 20) ||
        (newMRA >= 62 && service >= 5)
      ) {
        payload = { ...payload, MRA: newMRA };
      } else {
        payload = { ...payload, MRA: payload["baseMRA"] };
      }
      let srsyears = service;

      if (
        plannerData.have_military_service == "yes" &&
        plannerData.s2s3_receiving_pension == "no"
      ) {
        let month = convertValue(plannerData.military_service_years) * 12;
        month = month + convertValue(plannerData.military_service_months);
        let year = month / 12;
        srsyears = srsyears - year;
      }
      const Srs = convertValue(formData["age_62_ss_benefit"]) * (srsyears / 40);
      const ServicePayload = getServicePayload(service, payload);
      payload = {
        ...payload,
        ...ServicePayload,
        yearsOfService: service ? service.toFixed(2) : 0,
        diffMonth: diffMonth ? diffMonth : 0,
        yearsOfServiceat62: formatCalculatedValue(srsyears),
        Srs: formatCalculatedValue(Srs),
      };
      setformData(payload);
    }
  };

  const handleFersCheckBoxNew = (e) => {
    setformData({
      ...formData,
      valueToUse: e.target.value,
    });

    setOnChangeStatus(true);
  };

  const handlefersCalculations = (
    value,
    name,
    isJustcalculations = false,
    values = formData
  ) => {
    if (!isJustcalculations) {
      setOnChangeStatus(true);
    }
    let precentage = 1;

    if (
      name == "yearsOfService" &&
      value >= 20 &&
      values["MRA"] >= 62 &&
      isJustcalculations
    ) {
      precentage = 1.1;
    }
    if (values["yearsOfService"] >= 20 && values["MRA"] >= 62) {
      precentage = 1.1;
    }
    if (name == "yearsOfService" || name == "high3Avg") {
      let data = convertValue(value);
      let multiplier =
        name == "yearsOfService"
          ? convertValue(values["high3Avg"])
          : convertValue(values["yearsOfService"]);

      const finalValue = (data * multiplier * precentage) / 100 / 12;
      const fersBasicAnnuity25 = finalValue * 0.25;
      const fersBasicAnnuity95 = finalValue * 0.95;
      const fersBasicAnnuity50 = finalValue * 0.5;
      const fersBasicAnnuity90 = finalValue * 0.9;

      let payload = {
        [name]: value,
        fersBasicAnnuity: formatCalculatedValue(finalValue),
        fersBasicAnnuity25: formatCalculatedValue(fersBasicAnnuity25),
        fersBasicAnnuity95: formatCalculatedValue(fersBasicAnnuity95),
        fersBasicAnnuity50: formatCalculatedValue(fersBasicAnnuity50),
        fersBasicAnnuity90: formatCalculatedValue(fersBasicAnnuity90),
      };
      if (isJustcalculations) {
        return payload;
      } else {
        setformData({
          ...values,
          ...payload,
        });
      }
    }
  };

  const submitFormdata = (event) => {
    submit(event, formData);
  };
  return (
    <>
      <Card.Body className="fers-section">
        <Form
          name="FersForm"
          noValidate
          validated={validated}
          onSubmit={submitFormdata}
          className="employee-form"
        >
          <div className="text-center">
            <div className="head-title">
              (FERS) Federal Employee Retirement System
            </div>
            <div className="fers-sub-heanding mb-2">
              <p>
                Your high-three salary and length of credible service are used
                to compute your Federal Employee Retirement System (FERS)
                annuity, also known as your pension.
              </p>
            </div>
          </div>
          <div className="border border-dark">
            <div className="p-3">
              <div>
                <FloatingLabel
                  controlId="floatingInput"
                  label="What age do you want to retire"
                  className="my-3 "
                >
                  <Form.Control
                    autoComplete="off"
                    className="fers-input required-input"
                    type="text"
                    name="s2s5_age_to_start_taking_income"
                    placeholder="What age do you want to retire"
                    onChange={(e) => handleFersInput(e, "income")}
                    value={
                      formData.s2s5_age_to_start_taking_income &&
                      formData.s2s5_age_to_start_taking_income
                        .toString()
                        .replace(/amp;/g, "&")
                        .replace(/&+/g, "&")
                    }
                    isInvalid={
                      validated && !formData.s2s5_age_to_start_taking_income
                    }
                    disabled={formData.retirement_date_unknown == "1"}
                    required
                  />
                </FloatingLabel>
              </div>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Desired retirement date"
                  value={
                    formData["desired_retirement_date"]
                      ? formData["desired_retirement_date"]
                      : null
                  }
                  minDate={
                    formData.retirementDate
                      ? moment(formData.retirementDate)
                      : moment()
                  }
                  disabled={formData.retirement_date_unknown == "1"}
                  className={`required-input custome-picker w-100 floatingInput ${
                    (validated && !formData.desired_retirement_date) ||
                    (validated &&
                      moment(
                        formData.desired_retirement_date
                          ? formData.desired_retirement_date
                          : moment()
                      ) &&
                      moment(
                        formData.desired_retirement_date
                          ? formData.desired_retirement_date
                          : moment()
                      ).isBefore(moment().subtract(1, "days")))
                      ? "is-invalid"
                      : ""
                  } `}
                  onChange={(date) => handleFersInput(date, "date")}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                />
              </LocalizationProvider>

              {error && error["fers"] && (
                <p className="field-bottom-title">
                  <b>Disclaimer : </b>Date is before the MRA date of{" "}
                  {moment(formData["retirementDate"]).format("MM/DD/YYYY")}{" "}
                  please enter a date on or after this date
                </p>
              )}
              <div className="text-align-start">
                <div className="d-flex align-items-center  mt-3">
                  <div>
                    <Form.Check
                      id="retirement_date_unknown"
                      className="px-2"
                      name="retirement_date_unknown"
                      checked={
                        formData["retirement_date_unknown"] == "1"
                          ? true
                          : false
                      }
                      onChange={(e) => handleFersCheckbox(e.target.checked)}
                    />{" "}
                  </div>

                  <div className="d-flex align-items-center checkbox-text">
                    <label htmlFor="retirement_date_unknown">
                      Check if the retirement date is unknown
                    </label>
                  </div>
                </div>
              </div>

              {formData["s2s5_age_to_start_taking_income"] &&
                formData["desired_retirement_date"] &&
                formData["MRA"] > 62 && (
                  <div className="d-flex align-items-center">
                    <div className="mb-2 ">
                      <div className="mx-2 text-about">
                        Have you obtained your retirement materials and Annuity
                        estimate?
                      </div>
                    </div>
                    <div className="mx-2 d-flex ">
                      <div className="mx-2 d-flex align-items-center  justify-content-center">
                        <input
                          id="s2s5_have_you_obtainedYes"
                          type="radio"
                          className="mx-1"
                          name="s2s5_have_you_obtained"
                          checked={formData.s2s5_have_you_obtained == "yes"}
                          onChange={(event) => {
                            handleOnchangeCheckbox(event, "yes", setformData);
                          }}
                        />{" "}
                        <label
                          className="text-about"
                          htmlFor="s2s5_have_you_obtainedYes"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="mx-2 d-flex align-items-center  justify-content-center">
                        <input
                          id="s2s5_have_you_obtainedNo"
                          type="radio"
                          className="mx-1"
                          name="s2s5_have_you_obtained"
                          checked={formData.s2s5_have_you_obtained == "no"}
                          onChange={(event) => {
                            handleOnchangeCheckbox(event, "no", setformData);
                          }}
                        />{" "}
                        <label
                          className="text-about"
                          htmlFor="s2s5_have_you_obtainedNo"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                )}

              {formData["special_provisions"] == "1" ? (
                <>
                  {" "}
                  <>
                    <div className="section-head  mt-3">
                      {" "}
                      FERS Annuity Income Estimate{" "}
                    </div>

                    <Row className="mt-2 mb-3">
                      <Col md={1} className="container-Fers-Btags">
                        <b className="Fers-Btags">1.7%</b>{" "}
                        <b className="Fers-Btags">X</b>
                      </Col>

                      <Col md={3}>
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              autoComplete="off"
                              className="input-box fers-input-large"
                              type="number"
                              max="20"
                              min="0"
                              name="yearsOfService1"
                              onChange={(e) =>
                                handleProvisionCalculations(
                                  e.target.value,
                                  "yearsOfService1",
                                  "1.7"
                                )
                              }
                              onKeyPress={(e) => handleKeyPress(e)}
                              value={formData.yearsOfService1}
                            />
                            <p align="center">Total Years of Service</p>
                          </div>
                        </div>
                      </Col>

                      <Col md={1} className="container-Fers-Btags">
                        X
                      </Col>

                      <Col md={3}>
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              autoComplete="off"
                              className="input-box fers-input-large"
                              type="text"
                              name="high3Avg"
                              onChange={(e) =>
                                handleProvisionCalculations(
                                  e.target.value,
                                  "high3Avg",
                                  "1.7"
                                )
                              }
                              onBlur={(e) => handleBlur(e, true, setformData)}
                              onKeyPress={(e) => handleKeyPress(e)}
                              onFocus={(event) =>
                                handleFocus(event, setformData)
                              }
                              value={formData.high3Avg}
                            />
                            <p align="center">High-Three</p>
                          </div>
                        </div>
                      </Col>

                      <Col md={1} className="container-Fers-Btags">
                        =
                      </Col>

                      <Col md={3}>
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              autoComplete="off"
                              className="input-box fers-input-large"
                              type="text"
                              name="fersBasicAnnuity1"
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                              onBlur={(e) => handleBlur(e, true, setformData)}
                              onKeyPress={(e) => handleKeyPress(e)}
                              onFocus={(event) =>
                                handleFocus(event, setformData)
                              }
                              value={formData.fersBasicAnnuity1}
                            />
                            <p className="field-bottom-title" align="center">
                              No Survivor Benefit
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2 mb-3">
                      {/* <Col>
                        <b className="Fers-Btags">1%</b>{" "}
                      </Col>

                      <Col>
                        <b className="Fers-Btags">X</b>
                      </Col> */}

                      <Col md={1} className="container-Fers-Btags">
                        <b className="Fers-Btags">1%</b>{" "}
                        <b className="Fers-Btags">X</b>
                      </Col>

                      <Col md={3}>
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              autoComplete="off"
                              className="input-box fers-input-large"
                              type="text"
                              name="yearsOfService2"
                              onBlur={(e) => handleBlur(e, true, setformData)}
                              onChange={(e) =>
                                handleProvisionCalculations(
                                  e.target.value,
                                  "yearsOfService2",
                                  "1"
                                )
                              }
                              onFocus={(event) =>
                                handleFocus(event, setformData)
                              }
                              onKeyPress={(e) => handleKeyPress(e)}
                              value={formData.yearsOfService2}
                            />
                            <p align="center">Total Years of Service</p>
                          </div>
                        </div>
                      </Col>

                      <Col md={1} className="container-Fers-Btags">
                        X
                      </Col>

                      <Col md={3}>
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              autoComplete="off"
                              className="input-box fers-input-large"
                              type="text"
                              name="high3Avg"
                              onChange={(e) =>
                                handleProvisionCalculations(
                                  e.target.value,
                                  "high3Avg",
                                  "1"
                                )
                              }
                              onBlur={(e) => handleBlur(e, true, setformData)}
                              onKeyPress={(e) => handleKeyPress(e)}
                              onFocus={(event) =>
                                handleFocus(event, setformData)
                              }
                              value={formData.high3Avg}
                            />
                            <p align="center">High-Three</p>
                          </div>
                        </div>
                      </Col>

                      <Col md={1} className="container-Fers-Btags">
                        =
                      </Col>

                      <Col md={3}>
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              autoComplete="off"
                              className="input-box fers-input-large"
                              type="text"
                              name="fersBasicAnnuity2"
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                              onBlur={(e) => handleBlur(e, true, setformData)}
                              onKeyPress={(e) => handleKeyPress(e)}
                              onFocus={(event) =>
                                handleFocus(event, setformData)
                              }
                              value={formData.fersBasicAnnuity2}
                            />
                            <p className="field-bottom-title" align="center">
                              No Survivor Benefit
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                  <>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={9}>
                        <Row>
                          <Col md={8}>
                            <div className="fersAnnuity">
                              Total Monthly FERS Annuity
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="input-wrapper mt-1">
                              <b>$</b>
                              <div className="text-wrapper w-100">
                                <Form.Control
                                  autoComplete="off"
                                  className="input-box fers-input-large"
                                  type="text"
                                  name="fersBasicAnnuity"
                                  onChange={(event) =>
                                    onChangeHandler(event, setformData)
                                  }
                                  onBlur={(e) =>
                                    handleBlur(e, true, setformData)
                                  }
                                  onKeyPress={(e) => handleKeyPress(e)}
                                  onFocus={(event) =>
                                    handleFocus(event, setformData)
                                  }
                                  value={formData.fersBasicAnnuity}
                                />
                                <p
                                  className="field-bottom-title"
                                  align="center"
                                >
                                  No Survivor Benefit
                                </p>
                                <label className="text-center d-block">
                                  <input
                                    type="radio"
                                    name="valueToUse"
                                    onChange={handleFersCheckBoxNew}
                                    checked={
                                      formData.valueToUse == "" ||
                                      formData.valueToUse == "1"
                                    }
                                    value="1"
                                    id="valueToUse"
                                  />{" "}
                                  Select to use
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </>
              ) : (
                <>
                  {convertValue(formData["yearsOfService"]) >= 20 &&
                  convertValue(formData["MRA"]) >= 62 ? (
                    <>
                      <div className="section-head  mt-3">
                        {" "}
                        FERS Annuity Income Estimate{" "}
                      </div>

                      <Row className="mt-2 mb-3">
                        {/* <Col>
                          <b className="Fers-Btags">1.1%</b>{" "}
                        </Col>

                        <Col>
                          <b className="Fers-Btags">X</b>
                        </Col> */}

                        <Col md={1} className="container-Fers-Btags">
                          <b className="Fers-Btags">1.1%</b>{" "}
                          <b className="Fers-Btags">X</b>
                        </Col>

                        <Col md={3}>
                          <div className="input-wrapper mt-1">
                            <b>$</b>
                            <div className="text-wrapper w-100">
                              <Form.Control
                                autoComplete="off"
                                className="input-box fers-input-large"
                                type="text"
                                name="yearsOfService"
                                onChange={(e) =>
                                  handlefersCalculations(
                                    e.target.value,
                                    "yearsOfService"
                                  )
                                }
                                onKeyPress={(e) => handleKeyPress(e)}
                                value={formData.yearsOfService}
                              />
                              <p align="center">Total Years of Service</p>
                            </div>
                          </div>
                        </Col>

                        <Col md={1} className="container-Fers-Btags">
                          X
                        </Col>

                        <Col md={3}>
                          <div className="input-wrapper mt-1">
                            <b>$</b>
                            <div className="text-wrapper w-100">
                              <Form.Control
                                autoComplete="off"
                                className="input-box fers-input-large"
                                type="text"
                                name="high3Avg"
                                onChange={(e) =>
                                  handlefersCalculations(
                                    e.target.value,
                                    "high3Avg"
                                  )
                                }
                                onBlur={(e) => handleBlur(e, true, setformData)}
                                onKeyPress={(e) => handleKeyPress(e)}
                                onFocus={(event) =>
                                  handleFocus(event, setformData)
                                }
                                value={formData.high3Avg}
                              />
                              <p align="center">High-Three</p>
                            </div>
                          </div>
                        </Col>

                        <Col md={1} className="container-Fers-Btags">
                          =
                        </Col>

                        <Col md={3}>
                          <div className="input-wrapper mt-1">
                            <b>$</b>
                            <div className="text-wrapper w-100">
                              <Form.Control
                                autoComplete="off"
                                className="input-box fers-input-large"
                                type="text"
                                name="fersBasicAnnuity"
                                onChange={(event) =>
                                  onChangeHandler(event, setformData)
                                }
                                onBlur={(e) => handleBlur(e, true, setformData)}
                                onKeyPress={(e) => handleKeyPress(e)}
                                onFocus={(event) =>
                                  handleFocus(event, setformData)
                                }
                                value={formData.fersBasicAnnuity}
                              />
                              <p className="field-bottom-title" align="center">
                                No Survivor Benefit
                              </p>
                              <label className="text-center d-block">
                                <input
                                  type="radio"
                                  name="valueToUse"
                                  onChange={handleFersCheckBoxNew}
                                  checked={
                                    formData.valueToUse == "" ||
                                    formData.valueToUse == "1"
                                  }
                                  value="1"
                                  id="valueToUse"
                                />{" "}
                                Select to use
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <div className="section-head  mt-3">
                        {" "}
                        FERS Annuity Income Estimate{" "}
                      </div>

                      <Row className="mt-2 mb-3">
                        <Col md={1} className="container-Fers-Btags">
                          <b className="Fers-Btags">1%</b>{" "}
                          <b className="Fers-Btags">X</b>
                        </Col>

                        <Col md={3}>
                          <div className="input-wrapper mt-1">
                            <b>$</b>
                            <Form.Control
                              autoComplete="off"
                              className="input-box fers-input-large"
                              type="text"
                              name="yearsOfService"
                              onChange={(e) =>
                                handlefersCalculations(
                                  e.target.value,
                                  "yearsOfService"
                                )
                              }
                              onBlur={(e) => handleBlur(e, true, setformData)}
                              onKeyPress={(e) => handleKeyPress(e)}
                              onFocus={(event) =>
                                handleFocus(event, setformData)
                              }
                              value={
                                formData.yearsOfService
                                  ? formData.yearsOfService
                                  : "0"
                              }
                            />
                          </div>
                          <p align="center">Total Years of Service</p>
                        </Col>

                        <Col md={1} className="container-Fers-Btags">
                          X
                        </Col>

                        <Col md={3}>
                          <div className="input-wrapper mt-1">
                            <b>$</b>
                            <Form.Control
                              autoComplete="off"
                              className="input-box fers-input-large"
                              type="text"
                              name="high3Avg"
                              onChange={(e) =>
                                handlefersCalculations(
                                  e.target.value,
                                  "high3Avg"
                                )
                              }
                              onBlur={(e) => handleBlur(e, true, setformData)}
                              onKeyPress={(e) => handleKeyPress(e)}
                              onFocus={(event) =>
                                handleFocus(event, setformData)
                              }
                              value={formData.high3Avg}
                            />
                          </div>
                          <p align="center">High-Three</p>
                        </Col>

                        <Col md={1} className="container-Fers-Btags">
                          =
                        </Col>

                        <Col md={3}>
                          <div className="input-wrapper mt-1">
                            <b>$</b>
                            <Form.Control
                              autoComplete="off"
                              className="input-box fers-input-large"
                              type="text"
                              name="fersBasicAnnuity"
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                              onBlur={(e) => handleBlur(e, true, setformData)}
                              onKeyPress={(e) => handleKeyPress(e)}
                              onFocus={(event) =>
                                handleFocus(event, setformData)
                              }
                              value={formData.fersBasicAnnuity}
                            />
                          </div>
                          <p className="field-bottom-title" align="center">
                            No Survivor Benefit
                          </p>
                          <label className="text-center d-block">
                            <input
                              type="radio"
                              name="valueToUse"
                              onChange={handleFersCheckBoxNew}
                              checked={
                                formData.valueToUse == "" ||
                                formData.valueToUse == "1"
                              }
                              value="1"
                              id="valueToUse"
                            />{" "}
                            Select to use
                          </label>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}

              <Row>
                {/* <Row> */}
                <Col md={5}>
                  <div className="section-head ">
                    FERS Annuity Income Estimate
                  </div>
                </Col>

                <Col md={3}>
                  {" "}
                  <h6
                    className="fers-h6 d-flex justify-content-center"
                    align="center"
                  >
                    SurvivorShip Benefit
                  </h6>
                </Col>

                <Col md={{ size: 3, offset: 1 }}>
                  {" "}
                  <h6
                    className="fers-h6 d-flex justify-content-center"
                    align="center"
                  >
                    Reduce Annuity
                  </h6>
                </Col>
                {/* </Row> */}

                {/* <Row> */}
                <Col md={5}>
                  <p className="fers-p">
                    if the FERS Survivor Annuity with 25% survivor benefit
                    option is elected upon the death of the Annuitant designated
                    survivor beneficiary will receive:
                  </p>
                </Col>
                <Col md={3}>
                  <div className="input-wrapper mt-1">
                    <b>$</b>

                    <Form.Control
                      autoComplete="off"
                      className="input-box mb-3 fers-input-large"
                      type="text"
                      name="fersBasicAnnuity25"
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      value={formData.fersBasicAnnuity25}
                    />
                  </div>
                </Col>
                <Col md={{ size: 3, offset: 1 }}>
                  <div className="input-wrapper mt-1">
                    <b>$</b>

                    <Form.Control
                      autoComplete="off"
                      className="input-box fers-input-large"
                      type="text"
                      name="fersBasicAnnuity95"
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      value={formData.fersBasicAnnuity95}
                    />
                  </div>
                  <p className="field-bottom-title" align="center">
                    with 25% Survivor Benefit{" "}
                  </p>
                  <label className="text-center d-block">
                    <input
                      type="radio"
                      name="valueToUse"
                      onChange={handleFersCheckBoxNew}
                      checked={formData.valueToUse == "2"}
                      value="2"
                      id="valueToUse"
                    />{" "}
                    Select to use
                  </label>
                </Col>
                {/* </Row> */}

                {/* <Row className="mt-2"> */}
                <Col md={5}>
                  <p className="fers-p">
                    *if the FERS Survivor Annuity with 50% survivor benefit
                    option is elected upon the death of the Annuitant designated
                    survivor beneficiary will receive:
                  </p>
                </Col>
                <Col md={3}>
                  <div className="input-wrapper mt-1">
                    <b>$</b>

                    <Form.Control
                      autoComplete="off"
                      className="input-box mb-3 fers-input-large"
                      type="text"
                      name="fersBasicAnnuity50"
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      value={formData.fersBasicAnnuity50}
                    />
                  </div>
                </Col>
                <Col md={{ size: 3, offset: 1 }}>
                  <div className="input-wrapper mt-1">
                    <b>$</b>

                    <Form.Control
                      autoComplete="off"
                      className="input-box fers-input-large"
                      type="text"
                      name="fersBasicAnnuity90"
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      value={formData.fersBasicAnnuity90}
                    />
                  </div>
                  <p className="field-bottom-title" align="center">
                    with 50% Survivor Benefit{" "}
                  </p>
                  <label className="text-center d-block">
                    <input
                      type="radio"
                      name="valueToUse"
                      onChange={handleFersCheckBoxNew}
                      checked={formData.valueToUse == "3"}
                      value="3"
                      id="valueToUse"
                    />{" "}
                    Select to use
                  </label>
                </Col>
                {/* </Row> */}

                {/* <Row className="mt-3"> */}
                <Col md={12}>
                  <p className="fers-p">*Automatic option if you are married</p>
                </Col>
                {/* </Row> */}
              </Row>
            </div>
          </div>
          {validated && (
            <div className="red-text text-center mt-2">
              *Complete required fields to continue
            </div>
          )}
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button
                className="btn-next btn question-next"
                type="submit"
                disabled={error && error["fers"]}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default Fers;
