import React, { useEffect } from "react";
import { useState } from "react";
import { Card, Col, FloatingLabel, Form, Image, Row } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import { convertValue } from "../../../services/helperService";
import Images from "../../CRMs/crm2.0";
import userService from "../../../services/UserService";

const ThriftSavingForm = ({ submit, validated }) => {
  const {
    plannerData,
    onChangeHandler,
    handleOnchangeCheckbox,
    handleBlur,
    setOnChangeStatus,
  } = usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);

  const [error, setError] = useState({});

  const numberList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const handleinputChangeThrift = (key, event, index) => {
    let payload =
      formData[key] && Array.isArray(formData[key]) ? formData[key] : [];

    payload[index] = event.target.value;

    handleStateByField(key, payload);
  };

  const handleThriftBlur = (key, index, event) => {
    let value = "";
    let payload = formData[key] ? formData[key] : [];

    if (event.target.value) {
      value = event.target.value.replaceAll(",", "");
      value = parseFloat(value);
    }

    payload[index] = value ? value : "0";

    handleState(key, payload);
  };

  const handleStateByField = (name, value) => {
    setOnChangeStatus(true);

    setformData({
      ...formData,
      [name]: value,
    });
  };

  const handleState = (name, value) => {
    setformData({
      ...formData,
      [name]: value,
    });
  };

  const RiskOptions = ({ state }) => (
    <>
      {numberList &&
        numberList.map((value) => (
          <div
            onClick={() => handleStateByField(state, value)}
            className="radio-inline thrift-aggressive-label"
          >
            {formData[state] == value ? (
              <i className="fa fa-check-square-o thrift-checker"></i>
            ) : (
              <i className="fa fa-square-o thrift-checker2"></i>
            )}
            {value}
          </div>
        ))}
    </>
  );

  const addMore = () => {
    const screen12_fund_type =
      formData.screen12_fund_type &&
      Array.isArray(formData.screen12_fund_type) &&
      formData.screen12_fund_type.length > 0
        ? formData.screen12_fund_type
        : [""];
    const screen12_fund_type_percentage =
      formData.screen12_fund_type_percentage &&
      Array.isArray(formData.screen12_fund_type_percentage) &&
      formData.screen12_fund_type_percentage.length > 0
        ? formData.screen12_fund_type_percentage
        : [""];

    screen12_fund_type.push("");
    screen12_fund_type_percentage.push("");
    handleStateByField("screen12_fund_type", screen12_fund_type);
    handleStateByField(
      "screen12_fund_type_percentage",
      screen12_fund_type_percentage
    );
  };

  const removeField = (index) => {
    const screen12_fund_type = formData.screen12_fund_type;
    const screen12_fund_type_percentage =
      formData.screen12_fund_type_percentage;

    if (
      screen12_fund_type &&
      Array.isArray(screen12_fund_type) &&
      screen12_fund_type.length > 0
    ) {
      screen12_fund_type.splice(index, 1);
    }
    if (
      screen12_fund_type_percentage &&
      Array.isArray(screen12_fund_type_percentage) &&
      screen12_fund_type_percentage.length > 0
    ) {
      screen12_fund_type_percentage.splice(index, 1);
    }
    handleStateByField("screen12_fund_type", screen12_fund_type);
    handleStateByField(
      "screen12_fund_type_percentage",
      screen12_fund_type_percentage
    );
  };

  const handleSubmit = (event) => {
    setError({});
    let errorStatus = "";
    event.preventDefault();
    event.stopPropagation();
    if (!formData["tsp_ret_field3"]) {
      errorStatus = { ...errorStatus, tsp_ret_field3: true };
    }
    if (!formData["tsp_ret_field6"]) {
      errorStatus = { ...errorStatus, tsp_ret_field6: true };
    }

    if (
      formData["family_status"] == "married" &&
      !formData["spouse_risk_tol_level"] &&
      typeof formData["spouse_risk_tol_level"] !== "boolean"
    ) {
      errorStatus = { ...errorStatus, spouse_risk_tol_level: true };
    }

    if (
      formData["screen12_fund_type_percentage"] &&
      Array.isArray(formData["screen12_fund_type_percentage"]) &&
      formData["screen12_fund_type_percentage"].length > 0
    ) {
      let sum = formData["screen12_fund_type_percentage"]
        .map((elt) => {
          return convertValue(elt);
        })
        .reduce((a, b) => {
          return a + b;
        });
      if (sum !== 100) {
        errorStatus = { ...errorStatus, percentage: true };
      }
    }
    if (!formData["screen12_balance_of_traditional_tsp"]) {
      errorStatus = {
        ...errorStatus,
        screen12_balance_of_traditional_tsp: true,
      };
    }
    if (!formData["screen12_balance_of_roth_tsp"]) {
      errorStatus = { ...errorStatus, screen12_balance_of_roth_tsp: true };
    }
    if (!formData["screen12_fund_type_percentage"]) {
      errorStatus = { ...errorStatus, screen12_fund_type_percentage: true };
    }
    if (errorStatus && userService.getAccess().bpg_skip_req != 1) {
      setError(errorStatus);
      return;
    }
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="client-survey-section">
        <div className="qs-box-survey single-screen">
          <h1 className="question-survey">Thrift Savings at Retirement</h1>
          <Form
            id="form_client_survey"
            name="thriftSavingForm"
            className="subform"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className="answers-fers-Ann">
              <Row>
                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Balance of Traditional TSP:"
                    className="mb-3  border-0"
                  >
                    <Form.Control
                      autoComplete="off"
                      className="thrift-input thrift-input-focus rounded-0 required-input"
                      type="number"
                      name="screen12_balance_of_traditional_tsp"
                      placeholder="Balance of Traditional TSP:"
                      value={formData.screen12_balance_of_traditional_tsp}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onBlur={(e) => handleBlur(e, false, setformData)}
                      isInvalid={error["screen12_balance_of_traditional_tsp"]}
                      required
                    />
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Balance of Roth TSP:"
                    className="mb-3 border-0"
                  >
                    <Form.Control
                      autoComplete="off"
                      className="thrift-input thrift-input-focus rounded-0 required-input"
                      type="number"
                      name="screen12_balance_of_roth_tsp"
                      value={formData.screen12_balance_of_roth_tsp}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onBlur={(e) => handleBlur(e, false, setformData)}
                      placeholder="Balance of Roth TSP"
                      isInvalid={error["screen12_balance_of_roth_tsp"]}
                      required
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <div className="mt-2 mb-4">
                <div className="textbox-wrapper">
                  <select
                    name="s12s2_tsp_bal_verified_by"
                    value={formData.s12s2_tsp_bal_verified_by}
                    className={formData.s12s2_tsp_bal_verified_by && "has-val"}
                    onChange={(event) => onChangeHandler(event, setformData)}
                  >
                    <option key="blankChoice"> </option>
                    <option value="Recent Statement">Recent Statement</option>
                    <option value="TSP.gov">TSP.gov</option>
                    <option value="Estimate">Estimate</option>
                  </select>
                  <div className="floating-placeholder">
                    TSP balance & fund information verified by:
                  </div>
                </div>
              </div>
              {formData.screen12_fund_type_percentage &&
              Array.isArray(formData["screen12_fund_type_percentage"]) &&
              formData.screen12_fund_type_percentage.length > 0 ? (
                formData.screen12_fund_type_percentage.map((data, index) => (
                  <>
                    {" "}
                    <Row className="mb-1">
                      <Col md={6}>
                        <div className="textbox-wrapper required-input">
                          <select
                            type="text"
                            className={
                              formData.screen12_fund_type &&
                              formData.screen12_fund_type[index] &&
                              "has-val"
                            }
                            name="screen12_fund_type"
                            onChange={(event) =>
                              handleinputChangeThrift(
                                "screen12_fund_type",
                                event,
                                index
                              )
                            }
                            value={
                              (formData.screen12_fund_type &&
                                formData.screen12_fund_type[index]) ||
                              ""
                            }
                          >
                            <option value="">&nbsp;</option>
                            <option value="G Fund">G Fund</option>
                            <option value="F Fund">F Fund</option>
                            <option value="C Fund">C Fund</option>
                            <option value="S Fund">S Fund</option>
                            <option value="I Fund">I Fund</option>
                            <option value="L Income Fund">L Income Fund</option>
                            <option value="L 2025 Fund">L 2025 Fund</option>
                            <option value="L 2030 Fund">L 2030 Fund</option>
                            <option value="L 2035 Fund">L 2035 Fund</option>
                            <option value="L 2040 Fund">L 2040 Fund</option>
                            <option value="L 2045 Fund">L 2045 Fund</option>
                            <option value="L 2050 Fund">L 2050 Fund</option>
                          </select>
                          <div className="floating-placeholder">
                            Select fund type:
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Percentage:"
                          className="mb-3 textbox-wrapper required-input border-0"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="thrift-input thrift-input-focus rounded-0 "
                            type="text"
                            name="screen12_fund_type_percentage"
                            onBlur={(e) =>
                              handleThriftBlur(
                                "screen12_fund_type_percentage",
                                index,
                                e
                              )
                            }
                            placeholder="Percentage"
                            onChange={(event) =>
                              handleinputChangeThrift(
                                "screen12_fund_type_percentage",
                                event,
                                index
                              )
                            }
                            value={
                              formData.screen12_fund_type_percentage[index] ||
                              ""
                            }
                            isInvalid={error["screen12_fund_type_percentage"]}
                            required
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    {index > 0 && (
                      <div className=" remove-align mb-2">
                        <div
                          className="survey-remove remove-text"
                          onClick={() => removeField(index)}
                        >
                          Remove
                        </div>
                      </div>
                    )}
                  </>
                ))
              ) : (
                <Row className="mb-1">
                  <Col md={6}>
                    <div className="textbox-wrapper ">
                      <select
                        type="text"
                        className={
                          formData.screen12_fund_type &&
                          formData.screen12_fund_type[0] &&
                          "has-val"
                        }
                        name="screen12_fund_type"
                        onChange={(event) =>
                          handleinputChangeThrift(
                            "screen12_fund_type",
                            event,
                            0
                          )
                        }
                        value={
                          formData.screen12_fund_type &&
                          formData.screen12_fund_type[0]
                        }
                      >
                        <option value="">&nbsp;</option>
                        <option value="G Fund">G Fund</option>
                        <option value="F Fund">F Fund</option>
                        <option value="C Fund">C Fund</option>
                        <option value="S Fund">S Fund</option>
                        <option value="I Fund">I Fund</option>
                        <option value="L Income Fund">L Income Fund</option>
                        <option value="L 2025 Fund">L 2025 Fund</option>
                        <option value="L 2030 Fund">L 2030 Fund</option>
                        <option value="L 2035 Fund">L 2035 Fund</option>
                        <option value="L 2040 Fund">L 2040 Fund</option>
                        <option value="L 2045 Fund">L 2045 Fund</option>
                        <option value="L 2050 Fund">L 2050 Fund</option>
                      </select>
                      <div className="floating-placeholder">
                        Select fund type:
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Percentage:"
                      className="mb-3  border-0"
                    >
                      <Form.Control
                        autoComplete="off"
                        className="thrift-input thrift-input-focus rounded-0 required-input"
                        type="number"
                        name="screen12_fund_type_percentage"
                        placeholder="Percentage:"
                        onBlur={(e) =>
                          handleThriftBlur(
                            "screen12_fund_type_percentage",
                            0,
                            e
                          )
                        }
                        onChange={(event) =>
                          handleinputChangeThrift(
                            "screen12_fund_type_percentage",
                            event,
                            0
                          )
                        }
                        value={
                          formData.screen12_fund_type_percentage &&
                          formData.screen12_fund_type_percentage[0]
                        }
                        isInvalid={error["screen12_fund_type_percentage"]}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={3} className=" mb-4">
                  <a
                    href="javascript:void(0)"
                    onClick={addMore}
                    className="thrift-a"
                  >
                    + Add Fund Type
                  </a>
                </Col>
              </Row>
              <div className="d-flex align-items-center mb-2">
                <div className="mx-2 thrift-text">Outstanding TSP Loan </div>
                <div className="mx-2 d-flex">
                  <div className="mx-2">
                    <input
                      id="outStandingTspYes"
                      type="radio"
                      name="outstanding_tsp_loan"
                      className={`mt-3`}
                      checked={formData.outstanding_tsp_loan == "yes"}
                      onChange={(event) => {
                        handleOnchangeCheckbox(event, "yes", setformData);
                      }}
                    />{" "}
                    <label htmlFor="outStandingTspYes" className=" thrift-text">
                      Yes
                    </label>
                  </div>
                  <div className="mx-2">
                    <input
                      id="outStandingTspNo"
                      type="radio"
                      name="outstanding_tsp_loan"
                      className={`mt-3`}
                      checked={formData.outstanding_tsp_loan == "no"}
                      onChange={(event) => {
                        handleOnchangeCheckbox(event, "no", setformData);
                      }}
                    />{" "}
                    <label htmlFor="outStandingTspNo" className="thrift-text">
                      No
                    </label>
                  </div>
                </div>
              </div>
              {formData.outstanding_tsp_loan &&
                formData.outstanding_tsp_loan == "yes" && (
                  <div className="mx-4">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Amount"
                      className="mb-3 floatingInput "
                    >
                      <Form.Control
                        autoComplete="off"
                        className="thrift-input thrift-input-focus rounded-0 required-input"
                        name="outstanding_tsp_loan_amount"
                        type="number"
                        value={formData.outstanding_tsp_loan_amount}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, false, setformData)}
                        placeholder="How Many?"
                        isInvalid={
                          validated && !formData.outstanding_tsp_loan_amount
                        }
                        required
                      />
                    </FloatingLabel>
                    <div className="textbox-wrapper required-input">
                      <select
                        type="text"
                        name="traditional_fixed_purpose"
                        value={formData.traditional_fixed_purpose}
                        className={
                          formData.traditional_fixed_purpose && "has-val"
                        }
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        isInvalid={
                          validated && !formData.traditional_fixed_purpose
                        }
                        required
                      >
                        <option key="blankChoice"> </option>
                        <option value="Residential">Recedential Purpose</option>
                        <option value="General Purpose">General Purpose</option>
                      </select>
                      <div className="floating-placeholder">Purpose</div>
                    </div>

                    <div className="textbox-wrapper required-input">
                      <select
                        type="text"
                        name="traditional_fixed_purpose_remaining_years_to_payoff"
                        value={
                          formData.traditional_fixed_purpose_remaining_years_to_payoff
                        }
                        className={
                          formData.traditional_fixed_purpose_remaining_years_to_payoff &&
                          "has-val "
                        }
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        isInvalid={
                          validated &&
                          !formData.traditional_fixed_purpose_remaining_years_to_payoff
                        }
                        required
                      >
                        <option key="blankChoice"> </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                      </select>
                      <div className="floating-placeholder">
                        Remaining Years of Payoff
                      </div>
                    </div>
                  </div>
                )}
              <div className="mb-4">
                <div className="mx-2 thrift-text ">
                  Do you have any plans for a major expenditure/purchase as you
                  go into retirement?{" "}
                </div>
                <div className="mx-2 d-flex thrift-text-yes">
                  <div className="mx-2">
                    <input
                      id="expenditureYes"
                      type="radio"
                      name="s13_q1"
                      className={`mt-3`}
                      checked={formData.s13_q1 == "yes"}
                      onChange={(event) => {
                        handleOnchangeCheckbox(event, "yes", setformData);
                      }}
                    />{" "}
                    <label htmlFor="expenditureYes" className=" thrift-text">
                      Yes
                    </label>
                  </div>
                  <div className="mx-2">
                    <input
                      id="expenditureNo"
                      type="radio"
                      name="s13_q1"
                      className={`mt-3`}
                      checked={formData.s13_q1 == "no"}
                      onChange={(event) => {
                        handleOnchangeCheckbox(event, "no", setformData);
                      }}
                    />{" "}
                    <label htmlFor="expenditureNo" className="thrift-text">
                      No
                    </label>
                  </div>
                </div>
              </div>
              {formData.s13_q1 && formData.s13_q1 == "yes" && (
                <div className="mx-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Purchase Description:"
                    className="mb-3 floatingInput"
                  >
                    <Form.Control
                      autoComplete="off"
                      className="thrift-input thrift-input-focus rounded-0 required-input"
                      name="s13_purchase_description"
                      value={formData.s13_purchase_description}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      type="text"
                      isInvalid={
                        validated && !formData.s13_purchase_description
                      }
                      required
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total amount needed:"
                    className="mb-3 floatingInput"
                  >
                    <Form.Control
                      autoComplete="off"
                      className="thrift-input thrift-input-focus rounded-0 required-input"
                      name="s13_total_amount_needed"
                      type="number"
                      value={formData.s13_total_amount_needed}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onBlur={(e) => handleBlur(e, false, setformData)}
                      placeholder="How Many?"
                      isInvalid={validated && !formData.s13_total_amount_needed}
                      required
                    />
                  </FloatingLabel>
                </div>
              )}
              <div className="single-field-thrift mt-5 mb-3">
                <div className="panel-heading p-2">
                  <i className="fa-solid fa-star start-icon"></i>
                  MFB TIP: Asset Allocation
                </div>
                <p className="section-desc">
                  The way in which you distribute your money among the TSP funds
                  should reflect your time horizon and your risk tolerance. The
                  closer you are to retirement, the shorter your time horizon.
                  As a result, your primary focus might shift from growth and
                  accumulation to safety and preservation. Even if your risk
                  tolerance is very high, you may not have time to recover from
                  severe drops in the market if a large portion of your account
                  is allocated to stock funds.
                  <br></br>
                  <br></br>
                  If you are heavily invested in the stock funds, now is the
                  time to consider shifting to a more conservative allocation,
                  especially if you do not have other retirement funds safely
                  invested.
                  <a className="thrift-a" href="//www.tsp.gov" target="_blank">
                    www.tsp.gov
                  </a>
                </p>
              </div>
              <div className="single-field-thrift">
                <div className="thrift-image-container">
                  Since Inception (1988 - 2021) <br></br>
                  Updated from TSP.gov as of 06/01/2022
                </div>
                <div className="thrift-img-sub">
                  <Image className="thrift-image" src={Images.thriftGraph} />
                </div>
              </div>
              <div className="single-field-thrift">
                <div className="thrift-section">
                  <p className="thrift-section-p">
                    <strong>RISK ANALYSIS</strong>
                  </p>
                </div>
                <div className="thrift-section-2">
                  <ol style={{ fontSize: "20px" }}>
                    <li>
                      <Row>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            fontSize: "19px",
                            flexWrap: "nowrap",
                          }}
                        >
                          <p className="thript-risk-section-p">
                            Do you have less than 5 years until retirement?
                          </p>
                          <div className="d-flex px-2">
                            <div
                              className="radio-inline"
                              onClick={() =>
                                handleState("tsp_ret_field3", "yes")
                              }
                            >
                              Yes
                              {formData.tsp_ret_field3 == "yes" ? (
                                <i
                                  htmlFor="tsp_ret_field3"
                                  className="fa fa-check-square-o thrift-checker"
                                ></i>
                              ) : (
                                <i
                                  htmlFor="tsp_ret_field3"
                                  className="fa fa-square-o thrift-checker2"
                                ></i>
                              )}
                            </div>
                            <div
                              className="radio-inline padding-left"
                              onClick={() =>
                                handleState("tsp_ret_field3", "no")
                              }
                            >
                              No
                              {formData.tsp_ret_field3 == "no" ? (
                                <i className="fa fa-check-square-o thrift-checker"></i>
                              ) : (
                                <i className="fa fa-square-o thrift-checker2"></i>
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="thrift-risk-section-space  responsive-riskanalysis">
                              <p className="thript-risk-section-p">
                                If yes, how many?
                              </p>
                              <div className="section-input-wrapper">
                                <input
                                  style={{ width: "50px" }}
                                  autoComplete="off"
                                  type="text"
                                  name="tsp_ret_field4"
                                  value={formData.tsp_ret_field4}
                                  className="numOnly section-input-text editable update-on-change valid"
                                  onChange={(event) =>
                                    onChangeHandler(event, setformData)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                      {error["tsp_ret_field3"] && (
                        <p className="error-text">
                          Please complete this field before continuing.
                        </p>
                      )}
                    </li>

                    <li>
                      <p style={{ marginBottom: "12px" }}>
                        On a scale of 1-10 - What is your tolerance for exposing
                        your retirement money to market risk?
                      </p>
                      <div className="thrift-risk-section-2">
                        <div className="thrift-risk-section-2-sub">
                          <strong>Conservative (Safe)</strong>
                        </div>
                        <div className="thrift-risk-section-2-sub2">
                          <div style={{ width: "20px" }}>
                            <i className="fa fa-caret-left"></i>
                            <i className="fa fa-caret-right"></i>
                          </div>
                        </div>
                        <div className="thrift-risk-section-2-sub3">
                          <strong>Aggressive (Risky)</strong>
                        </div>
                      </div>

                      <div className="thrift-aggressive-check">
                        <RiskOptions state={"tsp_ret_field5"} />
                      </div>

                      {error["tsp_ret_field5"] && (
                        <p className="error-text">
                          Please complete this field before continuing.
                        </p>
                      )}
                    </li>
                    {formData["family_status"] == "married" && (
                      <li>
                        <p style={{ marginBottom: "12px" }}>
                          What is your spouses tolerance to market risk ?
                        </p>
                        <div className="thrift-risk-section-2">
                          <div className="thrift-risk-section-2-sub">
                            <strong>Conservative (Safe)</strong>
                          </div>
                          <div className="thrift-risk-section-2-sub2">
                            <div style={{ width: "20px" }}>
                              <i className="fa fa-caret-left"></i>
                              <i className="fa fa-caret-right"></i>
                            </div>
                          </div>
                          <div className="thrift-risk-section-2-sub3">
                            <strong>Aggressive (Risky)</strong>
                          </div>
                        </div>

                        <div className="thrift-aggressive-check">
                          <RiskOptions state={"spouse_risk_tol_level"} />
                        </div>

                        {error["spouse_risk_tol_level"] && (
                          <p className="error-text">
                            Please complete this field before continuing.
                          </p>
                        )}
                      </li>
                    )}
                    <li>
                      <div className="thrift-capitalizing">
                        <div>
                          Are you capitalizing on the federal government's
                          matching contributions?
                        </div>
                        <div className="d-flex">
                          <div>
                            <div
                              className="radio-inline d-flex align-items-baseline"
                              onClick={() =>
                                handleState("tsp_ret_field6", "yes")
                              }
                            >
                              Yes
                              {formData.tsp_ret_field6 == "yes" ? (
                                <i className="fa fa-check-square-o thrift-checker"></i>
                              ) : (
                                <i className="fa fa-square-o thrift-checker2"></i>
                              )}
                            </div>
                          </div>
                          <div>
                            <div
                              className="radio-inline d-flex align-items-baseline"
                              onClick={() =>
                                handleState("tsp_ret_field6", "no")
                              }
                            >
                              <div>No</div>
                              {formData.tsp_ret_field6 == "no" ? (
                                <i className="fa fa-check-square-o thrift-checker"></i>
                              ) : (
                                <i className="fa fa-square-o thrift-checker2"></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {error["tsp_ret_field6"] && (
                        <p className="error-text">
                          Please complete this field before continuing.
                        </p>
                      )}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="btn-wrapper">
                {error["percentage"] && (
                  <p className="red text-center">
                    The percentage entered do not equal 100%. Please revise
                    before continuing
                  </p>
                )}

                {(validated || Object.keys(error).length != 0) && (
                  <p className="red text-center">
                    *Complete required fields to continue.
                  </p>
                )}
                <div className="text-center mt-4">
                  <button className="btn-next btn question-next" type="submit">
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Card.Body>
    </>
  );
};

export default ThriftSavingForm;
