import React, { createContext, useContext, useState } from "react";
import moment from "moment";

const PlannerContext = createContext();

const PlannerContextProvider = ({ children }) => {
  const [plannerData, setPlannerData] = useState({});
  const [Tab, setTab] = useState();
  const [onChangeStatus, setOnChangeStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userDetails, setUserdetails] = useState(false);

  const handleDateChange = (date, name, setState) => {
    const validateDate = moment(date);
    if (validateDate && validateDate.isValid()) {
      setOnChangeStatus(true);
      setState((prevState) => ({
        ...prevState,
        [name]: moment(validateDate),
      }));
    }
  };

  const handleRadioButton = (event, value, setState) => {
    setOnChangeStatus(true);
    event.persist();

    const { name } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnchangeCheckbox = (event, value, setState) => {
    setOnChangeStatus(true);
    event.persist();
    const { name } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeHandler = (event, setState) => {
    setOnChangeStatus(true);
    event.persist();
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBlur = (event, isFormat, setState) => {
    event.persist();
    let { name, value } = event.target;
    if (isFormat) {
      if (value) {
        value = value.replaceAll(",", "");
        value = parseFloat(value);
      }
      let formattedPrice = "";
      if (value) {
        formattedPrice = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        })
          .format(value)
          .replace("$", "");
      }
      setState((prevState) => ({
        ...prevState,
        [name]: formattedPrice ? formattedPrice : "0.00",
      }));
    } else {
      if (value) {
        value = value.replaceAll(",", "");
        value = parseFloat(value);
      }
      setState((prevState) => ({
        ...prevState,
        [name]: value ? value : "0",
      }));
    }
  };

  const handleFocus = (event, setState) => {
    event.persist();
    let { name, value } = event.target;
    if (value == "0.00" || value == "0.0" || value == "0") {
      setState((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    } else {
      if (value) {
        const stringWithoutComma = value && value.replaceAll(",", "");
        setState((prevState) => ({
          ...prevState,
          [name]: stringWithoutComma ? stringWithoutComma : value,
        }));
      }
    }
  };

  const handleKeyPress = (event) => {
    if (!/^[\d,.]+$/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <PlannerContext.Provider
      value={{
        plannerData,
        setPlannerData,
        handleDateChange,
        handleRadioButton,
        handleOnchangeCheckbox,
        onChangeHandler,
        onChangeStatus,
        handleBlur,
        handleKeyPress,
        setOnChangeStatus,
        handleFocus,
        Tab,
        loader,
        setLoader,
        setTab,
        userDetails,
        setUserdetails,
      }}
    >
      {children}
    </PlannerContext.Provider>
  );
};

const usePlannerContext = () => useContext(PlannerContext);

export { PlannerContextProvider, usePlannerContext };
