import React, { useEffect } from "react";
import { useState } from "react";
import { Card, Form, Row, Col, Image, FloatingLabel } from "react-bootstrap";
import moment from "moment";
import Images from "../../CRMs/crm2.0";
import {
  convertValue,
  formatCalculatedValue,
  formatValue,
  getMonthlyPayment,
  integerValue,
  showValue,
} from "../../../services/helperService";
import { AboutFormList } from "../../../constants";
import { usePlannerContext } from "../../../contexts/planner.contexts";

const ConclusionForm = ({ submit, validated }) => {
  const { plannerData, handleBlur, onChangeHandler, handleFocus } =
    usePlannerContext();

  const [formData, setformData] = useState({});

  const [fersBasicAnnuity, setFersBasicAnnuity] = useState();

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      const FEHB =
        convertValue(plannerData["pay_s2_32"]) +
        "." +
        convertValue(plannerData["pay_s2_33"]);
      const tsp_rate =
        convertValue(plannerData["pay_tsp_r_1"]) +
        "." +
        convertValue(plannerData["pay_tsp_r_2"]);
      const stateTax =
        convertValue(plannerData["pay_s2_8"]) +
        "." +
        convertValue(plannerData["pay_s2_9"]);
      const fefTax =
        convertValue(plannerData["pay_s2_5"]) +
        "." +
        convertValue(plannerData["pay_s2_6"]);
      const TAX = convertValue(fefTax) + convertValue(stateTax);

      const BASIC_LIFE = convertValue(plannerData["fegli_line4"]) * 0.25;
      const OPTION_A = convertValue(plannerData["option_a"]) * 0.25;
      const PROTECTION_TOTAl = OPTION_A + BASIC_LIFE;
      const monthlyGross = convertValue(plannerData["pay_s2_1"]) / 12;

      const fersAnnuityArr = [
        plannerData["fersBasicAnnuity"],
        plannerData["fersBasicAnnuity95"],
        plannerData["fersBasicAnnuity90"],
      ];
      const valueToUse = plannerData["valueToUse"]
        ? plannerData["valueToUse"] - 1
        : 0;
      setFersBasicAnnuity(fersAnnuityArr[valueToUse]);
      const Loss = convertValue(plannerData["loss_in_monthly_income"]);
      let srsyears = plannerData["yearsOfService"];
      if (
        plannerData.have_military_service == "yes" &&
        plannerData.s2s3_receiving_pension == "no"
      ) {
        let month = convertValue(plannerData.military_service_years) * 12;
        month = month + convertValue(plannerData.military_service_months);
        let year = month / 12;
        srsyears = srsyears - year;
      }

      const estimatedsrs =
        convertValue(plannerData["age_62_ss_benefit"]) * (srsyears / 40);
      const SURVIVOR = convertValue(fersBasicAnnuity) * 0.1;
      const TAXDEDUCTION = convertValue(fersBasicAnnuity) * 0.2;

      const FEHBINSURANCE =
        convertValue(plannerData["pay_s2_32"]) +
        "." +
        convertValue(plannerData["pay_s2_33"]);
      const TRADITIONALINSURANCE =
        convertValue(plannerData["pay_s2_26"]) +
        "." +
        convertValue(plannerData["pay_s2_27"]);
      const ROTHINSRANCE =
        convertValue(plannerData["pay_tsp_r_1"]) +
        "." +
        convertValue(plannerData["pay_tsp_r_2"]);
      const serviceYears =
        plannerData["special_provisions"] == "0"
          ? plannerData["yearsOfService"]
          : convertValue(plannerData["yearsOfService1"]) +
            convertValue(plannerData["yearsOfService2"]);

      let totalMonthIncomeRIP = 0;
      totalMonthIncomeRIP = convertValue(plannerData["fersBasicAnnuity"]);
      if (plannerData["item_srs"] && plannerData["age"] < 62) {
        totalMonthIncomeRIP += convertValue(plannerData["item_srs"]);
      } else {
        totalMonthIncomeRIP += convertValue(plannerData["age_62_ss_benefit"]);
      }

      let TspStatus = "";
      const tspPayload = getMonthlyPayment(
        plannerData["tsp_opt_amount"],
        plannerData["MRA"]
      );
      let tspAmount = 0;
      if (plannerData["lifeAnnuity"] == "tsp_opt_full_or_partial_wid") {
        TspStatus = "Full or Partial Withdrawal";
        tspAmount = 0;
      } else if (plannerData["lifeAnnuity"] == "tsp_opt_tsp_monthly_pay") {
        TspStatus = "TSP Monthly Payments";
        tspAmount = 0;
      } else if (
        plannerData["lifeAnnuity"] == "tsp_opt_single_life_annuity_tsp"
      ) {
        TspStatus = "Single Life Annuity TSP";
        tspAmount = convertValue(tspPayload["SLA TSP"]);
      } else if (
        plannerData["lifeAnnuity"] ==
        "tsp_opt_single_life_annuity_private_sector"
      ) {
        tspAmount = convertValue(tspPayload["SLA PS"]);
        TspStatus = "Single Life Annuity Private Sector";
      } else if (
        plannerData["lifeAnnuity"] == "tsp_opt_joint_life_annuity_tsp"
      ) {
        TspStatus = "Joint Life Annuity TSP";
        tspAmount = convertValue(tspPayload["JLA TSP"]);
      } else if (
        plannerData["lifeAnnuity"] ==
        "tsp_opt_joint_life_annuity_private_sector"
      ) {
        TspStatus = "Joint Life Annuity Private Sector";
        tspAmount = convertValue(tspPayload["JLA PS"]);
      }
      totalMonthIncomeRIP += tspAmount;

      if (plannerData["pdf"]) {
        totalMonthIncomeRIP += convertValue(
          plannerData["conclusion_ret_inc_pro_other"]
        );
      }

      let currentEmergencyCash = 0;
      if (plannerData["bpg_ret_f2"] == "NO") {
        currentEmergencyCash = convertValue(plannerData["bpg_ret_f3"]);
      }
      const totalInstitutionalBalance =
        plannerData["s14_balance"] &&
        Array.isArray(plannerData["s14_balance"]) &&
        plannerData["s14_balance"].reduce((amount, amount2) => {
          return parseFloat(amount) + parseFloat(amount2);
        }, 0);
      const emergencyCashNeededatRetirement = 25000;
      const emergencyCashNeeded =
        emergencyCashNeededatRetirement - currentEmergencyCash;
      const remainYears = moment(plannerData["desired_retirement_date"]).diff(
        moment(),
        "year"
      );
      const total =
        formData.yearsOfServiceat62 && formData.age_62_ss_benefit
          ? (convertValue(formData.yearsOfServiceat62) *
              convertValue(formData.age_62_ss_benefit)) /
            40
          : "0.00";
      let payload = {};
      setformData({
        ...plannerData,
        age_62_ss_benefit_final: formatCalculatedValue(total),
        FEHB: formatCalculatedValue(FEHB),
        tsp_rate: formatCalculatedValue(tsp_rate),
        TAX: formatCalculatedValue(TAX),
        BASIC_LIFE: formatCalculatedValue(BASIC_LIFE),
        PROTECTION_TOTAl: formatCalculatedValue(PROTECTION_TOTAl),
        Loss: formatCalculatedValue(Loss),
        remainYears: formatCalculatedValue(remainYears),
        monthlyGross: formatCalculatedValue(monthlyGross),
        estimatedsrs: formatCalculatedValue(estimatedsrs),
        TAXDEDUCTION: formatCalculatedValue(TAXDEDUCTION),
        SURVIVOR: formatCalculatedValue(SURVIVOR),
        FEHBINSURANCE: formatCalculatedValue(FEHBINSURANCE),
        ROTHINSRANCE: formatCalculatedValue(ROTHINSRANCE),
        TRADITIONALINSURANCE: formatCalculatedValue(TRADITIONALINSURANCE),
        serviceYears: serviceYears,
        emergencyCashNeeded: formatCalculatedValue(emergencyCashNeeded),
        emergencyCashNeededatRetirement: formatCalculatedValue(
          emergencyCashNeededatRetirement
        ),
        totalInstitutionalBalance: formatCalculatedValue(
          totalInstitutionalBalance
        ),
        tspAmount: formatCalculatedValue(tspAmount),
        TspStatus,
        totalMonthIncomeRIP: formatCalculatedValue(totalMonthIncomeRIP),
        OPTION_A: formatCalculatedValue(OPTION_A),
        yearsOfServiceat62: formatCalculatedValue(srsyears),
        ...payload,
      });
    }
  }, [plannerData]);

  const submitFormdata = (event) => {
    submit(event, formData);
  };
  return (
    <>
      <Card.Body className="conclusion">
        <Form
          name="conclusionForm"
          onSubmit={submitFormdata}
          className="employee-form"
          noValidate
          validated={validated}
        >
          <div className="text-center">
            <div className="head-title">CONCLUSION</div>
          </div>

          <section>
            <div className="box-wrapper">
              <div className="box-body">
                <p className="heading">Employment, Pay & Benefits</p>

                <div className="body-text-wrapper">
                  <div className="text-space ">
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4} className="text-bold">
                        Name:
                      </Col>
                      <Col md={4}>{formData["name"]}</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4} className="text-bold">
                        Date:
                      </Col>
                      <Col md={4}>{moment().format("MM/DD/YYYY")}</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4} className="text-bold text-nowrap">
                        Retirement Eligibility:
                      </Col>
                      <Col md={4}>
                        Age {integerValue(formData.MRA)} w/{" "}
                        {showValue(formData.yearsOfService, false)} years of
                        service
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4} className="text-bold">
                        Married:
                      </Col>
                      <Col md={4} className="text-capitalize">
                        {formData["family_status"] == "married" ? "Y" : "N"}
                      </Col>
                    </Row>
                    {formData["do_you_have_children"] == "yes" && (
                      <Row>
                        <Col md={1}></Col>
                        <Col md={4} className="text-bold">
                          Dependent Children:
                        </Col>
                        <Col md={4} className="text-capitalize">
                          {formData["dependent_children"]}
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
                <div className="body-text-wrapper mt-3 ">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p">
                      <strong>PAY</strong> <span className="sub-p">(USPS)</span>
                    </p>
                  </div>

                  <Row className="mt-4">
                    <Col md={1}></Col>
                    <Col md={4}>Base Rate of Pay</Col>
                    <Col md={4}>{showValue(formData["pay_s2_1"])}</Col>
                  </Row>
                  <Row>
                    <Col md={1}></Col>
                    <Col md={4}>Bi-weekly Pay Gross Pay</Col>
                    <Col md={4}>{showValue(formData["pay_s2_2"])}</Col>
                  </Row>
                  <Row>
                    <Col md={1}></Col>
                    <Col md={4}>FEHB</Col>
                    <Col md={4}> {showValue(formData["FEHB"])}</Col>
                  </Row>
                  <Row>
                    <Col md={1}></Col>
                    <Col md={4}>FEGLI</Col>
                    <Col md={4}> {showValue(formData["fegli_line4"])}</Col>
                  </Row>
                  <Row>
                    <Col md={1}></Col>
                    <Col md={4}>
                      TSP
                      {formData["pay_s2_25"] == "$"
                        ? "(Fixed)"
                        : formData["pay_s2_25"] == "%"
                        ? "(" + formData["pay_s2_tsp_percent"] + "%)"
                        : "()"}
                    </Col>
                    <Col md={4}>
                      {" "}
                      {formData["pay_s2_26"]
                        ? showValue(formData["pay_s2_26"])
                        : "$0"}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}></Col>
                    <Col md={4}>
                      TSP R
                      {formData["pay_s2_100"] == "$"
                        ? "(Fixed)"
                        : formData["pay_s2_100"] == "%"
                        ? "(" + formData["pay_s2_tsp_r_percent"] + "%)"
                        : "()"}
                    </Col>
                    <Col md={4}>
                      {" "}
                      {formData["tsp_rate"]
                        ? showValue(formData["tsp_rate"])
                        : "$0"}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}></Col>
                    <Col md={4}>Fed and State Withholdings</Col>
                    <Col md={4}> {showValue(formData["TAX"])}</Col>
                  </Row>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-3">
            <div className="box-wrapper">
              <div className="box-body">
                <p className="heading">
                  FEGLI - Federal Employee Group Life Insurance
                </p>

                <div className="body-text-wrapper">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p">
                      <strong>Short-Term Protection </strong>
                      <span className="sub-p-normal">(Today to Age 60)</span>
                    </p>
                  </div>
                  <div className="text-space">
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Basic Life</Col>
                      <Col md={3}>{showValue(formData["fegli_line4"])}</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Extra Benefit</Col>
                      <Col md={2}> {showValue(formData["fegli_line7"])}</Col>
                      <Col md={4}>
                        <div className="error-text">* Eliminated at Age 45</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Option A</Col>
                      <Col md={2}>{showValue(formData["option_a"])}</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Option B</Col>
                      <Col md={2}> {showValue(formData["fegli_line10"])}</Col>
                      <Col md={4}>
                        <div className="error-text">** Increase in premium</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Option C</Col>
                      <Col md={2}></Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4} className="col-spacing">
                        Spouse
                      </Col>
                      <Col md={2}> {showValue(formData["optionCSpouce"])}</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4} className="table-border col-spacing">
                        Child (Under age 22 or disabled)
                      </Col>
                      <Col md={2} className="table-border">
                        {showValue(formData["optionCChildren"])}
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md={1}></Col>
                      <Col md={4} className="total-text">
                        Total
                      </Col>
                      <Col md={2} className="total-text">
                        {showValue(formData["total_life_insurance"])}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="body-text-wrapper mt-3">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p">
                      <strong>Permanent Protection </strong>
                      <span className="sub-p-normal">(Age 60+)</span>
                    </p>
                  </div>
                  <div className="text-space ">
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Basic Life</Col>
                      <Col md={2}>{showValue(formData["BASIC_LIFE"])}</Col>
                      <Col md={4}>
                        <div className="error-text">
                          * 75% reduction at Age 65
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Extra Benefit</Col>
                      <Col md={2}>N/A</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Option A</Col>
                      <Col md={2}> {showValue(formData["OPTION_A"])}</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Option B</Col>
                      <Col md={2}>N/A</Col>
                      <Col md={4}>
                        <div className="error-text">
                          * Unaffordable @ {showValue(formData["age65OptionB"])}{" "}
                          mo.
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Option C</Col>
                      <Col md={3}></Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4} className="col-spacing">
                        Spouse
                      </Col>
                      <Col md={2}>N/A</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4} className="table-border col-spacing">
                        Child (Under age 22 or disabled)
                      </Col>
                      <Col md={2} className="table-border">
                        N/A
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md={1}></Col>
                      <Col md={4} className="total-text">
                        Total
                      </Col>
                      <Col md={2} className="total-text">
                        {showValue(formData["PROTECTION_TOTAl"])}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="body-text-wrapper mt-3">
                  <div className="heading-gray-wrapper">
                    <p className="heading-gray">FEGLI - ITEMS OF IMPORTANCE</p>
                  </div>
                  <ul className="heading-gray-ul">
                    <li>
                      * <strong>Extra Benefit</strong> eliminated for all
                      Federal Employees at 45.
                    </li>
                    <li>
                      ** <strong>Option B</strong> Coverage increases
                      automatically every 5 yrs.
                    </li>
                    <li>
                      ** Personal health and cost to fix FEGLI will be
                      jeopardized as you get older.
                    </li>
                    <li>** Permanent Protection not sufficient at Age 90</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-3">
            <div className="box-wrapper">
              <div className="box-body">
                <p className="heading">Retirement Planning</p>

                {AboutFormList && AboutFormList.length > 0 && (
                  <div className="body-text-wrapper">
                    <div className="p-text-wrapper mb-2">
                      <p className="main-p">
                        <strong> Retirement Concerns</strong>
                      </p>
                    </div>
                    <div className="text-space  text-bold">
                      {AboutFormList.map((item) => (
                        <>
                          {formData.s1q1 && formData.s1q1.includes(item) && (
                            <>
                              {item == "Other" &&
                              formData.s1q1 &&
                              formData.s1q1.includes("Other") ? (
                                <Row>
                                  <Col md={1}></Col>
                                  <Col md={11}>
                                    Other: {formData.screen1OtherValue}
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col md={1}></Col>
                                  <Col md={11}>{item}</Col>
                                </Row>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )}
                <div className="body-text-wrapper mt-3">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p">
                      <strong>Personal Information</strong>
                    </p>
                  </div>
                  <div className="text-space">
                    <Row>
                      <Col md={1}></Col>
                      <Col md={3} className="fw-bold">
                        Family Status:
                      </Col>
                      <Col md={2} className="text-capitalize">
                        {formData["family_status"]}
                      </Col>
                    </Row>
                    {formData["family_status"] == "married" && (
                      <>
                        <Row>
                          <Col md={1}></Col>
                          <Col md={3} className="">
                            Spouse Name:
                          </Col>
                          <Col md={2}>
                            {formData["spouse_firstname"] +
                              " " +
                              formData["spouse_lastname"]}
                          </Col>
                          <Col md={4} style={{ position: "relative" }}>
                            <div className="left-side-message error-text p-2">
                              {formData["family_status"] != "single" && (
                                <>
                                  {" "}
                                  **If Married and/or Children. Verify{" "}
                                  <b>beneficiary designations</b> are correct
                                  and survivorship options are discussed.
                                  Including TSP Annuity.
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={1}></Col>
                          <Col md={3} className="">
                            Spouse Age:
                          </Col>
                          <Col md={2}>
                            {formData["spouse_dob"] &&
                              moment().diff(
                                moment(formData["spouse_dob"]),
                                "years"
                              )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={1}></Col>
                          <Col md={3} className="">
                            Employment:
                          </Col>
                          <Col md={2}>{formData["spouse_employment"]}</Col>
                        </Row>
                      </>
                    )}

                    {formData["family_status"] != "single" && (
                      <>
                        <Row>
                          <Col md={1}></Col>
                          <Col md={4} className="">
                            Children:
                          </Col>
                          <Col md={4}></Col>
                        </Row>
                        <Row>
                          <Col md={1}></Col>
                          <Col md={4} className="col-spacing">
                            Dependent Children:
                          </Col>
                          <Col md={3}>{formData["dependent_children"]}</Col>
                        </Row>
                      </>
                    )}

                    {formData["have_military_service"] == "yes" && (
                      <>
                        <Row>
                          <Col md={1}></Col>
                          <Col md={4} className="text-bold">
                            Military Service
                          </Col>
                          <Col md={4}></Col>
                        </Row>
                        <Row>
                          <Col md={2}></Col>
                          <Col md={2} className="text-bold">
                            Buy-Back Process:
                          </Col>
                          <Col md={2}>
                            {formData["deposit_made"] == "yes"
                              ? "(Completed)"
                              : "(Not Started)"}
                          </Col>
                          <Col md={5} className="error-text">
                            {formData["deposit_made"] != "yes" && (
                              <>
                                *Verify <b>Military Time</b> is credited.
                              </>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </div>
                <div className="body-text-wrapper mt-3">
                  <div className="heading-gray-wrapper">
                    <p className="heading-gray">
                      Retirement Planning - ITEMS OF IMPORTANCE
                    </p>
                  </div>
                  <ul className="heading-gray-ul">
                    {formData["s2s3_receiving_pension"] != "yes" &&
                      formData["deposit_made"] != "yes" && (
                        <li>
                          *Verify<strong> Military Time</strong> is credited.
                        </li>
                      )}
                    {formData["family_status"] != "single" && (
                      <li>
                        **If Married and/or Children. Verify{" "}
                        <strong>beneficiary designations</strong> are correct
                        and survivorship options are discussed. Including TSP
                        Annuity..
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-3">
            <div className="box-wrapper">
              <div className="box-body">
                <p className="heading">
                  FERS - Federal Employee Retirement System
                </p>

                <div className="body-text-wrapper">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p">
                      <strong>FERS </strong>{" "}
                      <span className="sub-p">FERS Annuity Estimate</span>
                    </p>
                  </div>
                  <div>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={5}>Current Age</Col>
                      <Col md={3}>{integerValue(formData["age"])}</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={5} className="text-nowrap text-bold">
                        Desired Retirement Date (MRA)
                      </Col>
                      <Col md={3}>
                        {moment(formData["desired_retirement_date"]) &&
                          moment(formData["desired_retirement_date"]).format(
                            "MM/DD/yyyy"
                          ) + ` Age ${integerValue(formData["MRA"])}`}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={5} className="text-nowrap">
                        Minimum Retirement Age (MRA)
                      </Col>
                      <Col md={3}>Age {integerValue(formData["MRA"])}</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={5}>Years of Service (MRA)</Col>
                      <Col md={3}>
                        {" "}
                        {formatValue(formData["yearsOfService"])} years of
                        service
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={5} className="text-nowrap">
                        High Three Salary (pre-retirement)
                      </Col>
                      <Col md={3}>${formData["high3Avg"]}</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={5}>Pre Retirement Income</Col>
                      <Col md={3}>
                        {showValue(formData["monthlyGross"])} Gross Monthly
                      </Col>
                      <Col md={3}>
                        <div className="error-text">
                          *({showValue(formData["Loss"])}) Loss of Income
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={5} className="conclusion-green">
                        Monthly Annuity
                      </Col>
                      <Col md={3} className="conclusion-green">
                        {showValue(fersBasicAnnuity)}{" "}
                        <span className="conclusion-span">(w/o survivor)</span>
                      </Col>
                      <Col md={3} className="error-text">
                        ** (Taxable Income)
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="body-text-wrapper mt-3">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p">
                      <strong>SRS</strong>
                      <span className="sub-p">
                        {" "}
                        Special Retirement Supplement (to Age 62)
                      </span>
                    </p>
                  </div>
                  {formData["MRA"] < 62 &&
                  ((formData["MRA"] >= 60 &&
                    formData["yearsOfService"] >= 20) ||
                    (formData["MRA"] < 60 &&
                      formData["yearsOfService"] >= 30)) ? (
                    <div className="text-space">
                      <Row>
                        <Col md={1}></Col>
                        <Col md={5} className="text-nowrap">
                          Estimated Age 62 Social Security:
                        </Col>
                        <Col md={3}>${formData["age_62_ss_benefit"]}</Col>
                      </Row>
                      <Row>
                        <Col md={1}></Col>
                        <Col md={5}>Years of Service @MRA:</Col>
                        <Col md={3}>
                          {" "}
                          {formatValue(formData["yearsOfServiceat62"])} years
                        </Col>
                        {/* estYearService */}
                      </Row>
                      <Row>
                        <Col md={1}></Col>
                        <Col md={5} className="conclusion-green text-bold">
                          Estimated SRS Income (until 62)
                        </Col>
                        <Col md={3} className="conclusion-green text-bold">
                          {showValue(formData["estimatedsrs"])}{" "}
                          <span className="conclusion-span">
                            (until Age 62)
                          </span>
                        </Col>
                        <Col md={3} className="error-text text-bold">
                          ** (Taxable Income)
                        </Col>
                      </Row>
                    </div>
                  ) : formData["MRA"] < 62 &&
                    formData["special_provisions"] == "1" &&
                    ((formData["MRA"] >= 50 &&
                      formData["yearsOfService"] >= 20) ||
                      formData["yearsOfService"] >= 30) ? (
                    <div className="text-space">
                      <Row>
                        <Col md={1}></Col>
                        <Col md={5} className="text-nowrap">
                          Estimated Age 62 Social Security:
                        </Col>
                        <Col md={3}>${formData["age_62_ss_benefit"]}</Col>
                      </Row>
                      <Row>
                        <Col md={1}></Col>
                        <Col md={5}>Years of Service @ MRA:</Col>
                        <Col md={3}>
                          {" "}
                          {formatValue(formData["yearsOfServiceat62"])} years
                        </Col>
                        {/* estYearService */}
                      </Row>
                      <Row>
                        <Col md={1}></Col>
                        <Col md={5} className="conclusion-green text-bold">
                          Estimated SRS Income (until 62)
                        </Col>
                        <Col md={3} className="conclusion-green text-bold">
                          {showValue(formData["estimatedsrs"])}{" "}
                          <span className="conclusion-span">
                            (until Age 62)
                          </span>
                        </Col>
                        <Col md={3} className="error-text text-bold">
                          ** (Taxable Income)
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="text-space">
                      <Row>
                        <Col md={1}></Col>
                        <Col md={5} className="text-nowrap">
                          Will Not Qualify for SRS
                        </Col>
                        <Col md={3}></Col>
                      </Row>
                    </div>
                  )}
                </div>
                <div className="body-text-wrapper mt-3">
                  <div className="heading-gray-wrapper">
                    <p className="heading-gray">
                      FERS SRS - ITEMS OF IMPORTANCE
                    </p>
                  </div>
                  <ul className="heading-gray-ul">
                    <li>
                      *Loss of Income Per Month after Retirement (
                      {showValue(formData["Loss"])})
                    </li>
                    <li>
                      *Survivor Deduction ({showValue(formData["SURVIVOR"])})
                    </li>
                    <li>
                      **Estimated 20% Fed and State Tax Deduction (
                      {showValue(formData["TAXDEDUCTION"])})
                    </li>
                    <li>**FERS and SRS payments are fully taxable</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-3">
            <div className="box-wrapper ">
              <div className="box-body">
                <p className="heading">TSP - Thrift Savings Plan</p>
                {(formData["pay_s2_26"] || formData["pay_tsp_r_1"]) && (
                  <>
                    {(formData["pay_s2_25"] == "%" ||
                      formData["pay_s2_25"] == "$") && (
                      <>
                        <div className="body-text-wrapper  mt-3">
                          <div className="p-text-wrapper mb-2">
                            <p className="main-p">
                              <strong>TSP </strong>
                              <span className="sub-p">
                                {" "}
                                Current Contributions on Paycheck
                              </span>
                            </p>
                          </div>
                          <div className="text-space">
                            {formData["pay_s2_100"] == "%" ? (
                              <Row>
                                <Col md={1}></Col>
                                <Col md={2}>Roth %</Col>
                                <Col md={4}>
                                  {showValue(formData["tsp_rate"])}{" "}
                                  {formData["pay_s2_tsp_r_percent"]
                                    ? "(" +
                                      formData["pay_s2_tsp_r_percent"] +
                                      "%" +
                                      ")"
                                    : ""}
                                </Col>
                                <Col md={5}></Col>
                              </Row>
                            ) : (
                              formData["pay_s2_100"] == "$" && (
                                <Row>
                                  <Col md={1}></Col>
                                  <Col md={2}>Roth Fixed</Col>
                                  <Col md={4}>
                                    {showValue(formData["tsp_rate"])}{" "}
                                  </Col>
                                  <Col md={5}></Col>
                                </Row>
                              )
                            )}

                            {formData["pay_s2_25"] == "%" ? (
                              <Row>
                                <Col md={1}></Col>
                                <Col md={2}>Traditional %</Col>
                                <Col md={4}>
                                  {showValue(formData["TRADITIONALINSURANCE"])}{" "}
                                  {formData["pay_s2_tsp_percent"]
                                    ? "(" +
                                      formData["pay_s2_tsp_percent"] +
                                      "%" +
                                      ")"
                                    : ""}
                                </Col>
                                <Col md={5}></Col>
                              </Row>
                            ) : (
                              formData["pay_s2_25"] == "$" && (
                                <Row>
                                  <Col md={1}></Col>
                                  <Col md={2}>Traditional Fixed</Col>
                                  <Col md={4}>
                                    {showValue(
                                      formData["TRADITIONALINSURANCE"]
                                    )}{" "}
                                  </Col>
                                  <Col md={5}></Col>
                                </Row>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                <div className="body-text-wrapper  mt-3">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p">
                      <strong>TSP </strong>
                      <span className="sub-p">Balance Fund Contributions</span>
                    </p>
                  </div>
                  <div className="text-space">
                    <Row>
                      <Col md={1}></Col>
                      <Col md={3}>Estimated Balance:</Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={3} className="col-spacing">
                        Traditional:
                      </Col>

                      <Col md={4} className="">
                        {showValue(
                          formData["screen12_balance_of_traditional_tsp"]
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={3} className="col-spacing">
                        Roth:
                      </Col>
                      <Col md={4} className="">
                        {showValue(formData["screen12_balance_of_roth_tsp"])}
                      </Col>
                    </Row>
                    {formData["screen12_fund_type"] &&
                      Array.isArray(formData["screen12_fund_type"]) &&
                      formData["screen12_fund_type"].map((type, index) => (
                        <Row>
                          <Col md={1}></Col>
                          <Col md={3}>{type}</Col>
                          <Col md={4} className="text-bold-medium">
                            {formData["screen12_fund_type_percentage"] &&
                              formData["screen12_fund_type_percentage"][index]}
                            %
                          </Col>
                        </Row>
                      ))}
                  </div>
                </div>
                {formData["outstanding_tsp_loan"] == "yes" && (
                  <div className=" body-text-wrapper  mt-3 section ">
                    <div className="p-text-wrapper mb-2">
                      <p className="main-p">
                        <strong>Outstanding TSP Loan</strong>
                      </p>
                    </div>

                    <Row>
                      <Col md={1}></Col>
                      <Col md={2}>Amount</Col>
                      <Col md={4}>
                        {showValue(formData["outstanding_tsp_loan_amount"])}
                      </Col>
                      <Col md={5}></Col>
                    </Row>

                    <Row>
                      <Col md={1}></Col>
                      <Col md={2}>Purpose</Col>
                      <Col md={4}>
                        {" "}
                        {formData["traditional_fixed_purpose"]
                          ? formData["traditional_fixed_purpose"]
                          : ""}{" "}
                      </Col>
                      <Col md={5}></Col>
                    </Row>

                    <Row>
                      <Col md={1}></Col>
                      <Col md={2}>Remaining Years to Payoff</Col>
                      <Col md={4}>
                        {" "}
                        {formData[
                          "traditional_fixed_purpose_remaining_years_to_payoff"
                        ]
                          ? convertValue(
                              formData[
                                "traditional_fixed_purpose_remaining_years_to_payoff"
                              ]
                            )
                          : ""}{" "}
                      </Col>
                      <Col md={5}></Col>
                    </Row>
                  </div>
                )}

                {formData["s13_q1"] == "yes" && (
                  <div className="section  body-text-wrapper  mt-3">
                    <div className="p-text-wrapper mb-2">
                      <p className="main-p">
                        <strong>Planned expenditures/purchases </strong>
                      </p>
                    </div>

                    <Row>
                      <Col md={1}></Col>
                      <Col md={2}>Purchase description:</Col>
                      <Col md={4}>
                        {showValue(formData["s13_purchase_description"])}
                      </Col>
                      <Col md={5}></Col>
                    </Row>

                    <Row>
                      <Col md={1}></Col>
                      <Col md={2}>Total amount needed:</Col>
                      <Col md={4}>
                        {showValue(formData["s13_total_amount_needed"])}
                      </Col>
                      <Col md={5}></Col>
                    </Row>
                  </div>
                )}

                <div className="body-text-wrapper   mt-3">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p">
                      <strong>Risk Tolerance</strong>
                    </p>
                  </div>
                  <div className="text-space">
                    {() => {
                      if (
                        formData["remainYears"] &&
                        formData["remainYears"] < 5 &&
                        formData["remainYears"] >= 0
                      ) {
                        return (
                          <Row>
                            <Col md={1}></Col>
                            <Col md={4}>Less than five years to retirement</Col>
                            <Col md={4} className="fw-bold">
                              (Pre-Retirement)
                            </Col>
                          </Row>
                        );
                      }
                    }}

                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Years until retirement:</Col>
                      <Col md={4} className="fw-bold">
                        {moment(formData["desired_retirement_date"]).diff(
                          moment(),
                          "year"
                        )}{" "}
                        Years
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Current Risk Tolerance:</Col>
                      <Col md={4} className="fw-bold">
                        LEVEL {formData["tsp_ret_field5"]} (
                        {formData["tsp_ret_field5"] >= 6 &&
                        formData["tsp_ret_field5"] <= 10
                          ? "Aggressive"
                          : "Conservative"}
                        )
                      </Col>
                    </Row>

                    {formData["family_status"] == "married" && (
                      <Row>
                        <Col md={1}></Col>
                        <Col md={4}>Current Risk Tolerance (Spouse):</Col>
                        <Col md={4} className="fw-bold">
                          LEVEL {formData["spouse_risk_tol_level"]} (
                          {formData["spouse_risk_tol_level"] >= 6 &&
                          formData["spouse_risk_tol_level"] <= 10
                            ? "Aggressive"
                            : "Conservative"}
                          )
                        </Col>
                      </Row>
                    )}

                    {formData["tsp_ret_field1"] == "yes" && (
                      <Row>
                        <Col md={1}></Col>
                        <Col md={4} className="error-text">
                          **TSP fund allocations need to be adjusted to reflect{" "}
                          <strong>Retirement Time Horizon</strong>.
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
                {/* {(formData["tsp_opt_single_life_annuity"] == "1" ||
                    formData["tsp_opt_joint_life_annuity"] == "1") && ( */}
                <div className="body-text-wrapper mt-3">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p">
                      <strong>Withdrawal Options </strong>
                    </p>
                  </div>

                  <Row>
                    <Col md={1}></Col>
                    <Col md={4}>
                      What amount from your TSP account do you want to use for
                      monthly income?
                    </Col>
                    <Col md={5} className="col-4">
                      {showValue(formData["tsp_opt_amount"])}
                    </Col>
                  </Row>

                  {formData["tsp_opt_full_or_partial_wid"] == "1" && (
                    <Row className="mt-2">
                      <Col md={1}></Col>
                      <Col md={11}>
                        Selected Full or Partial Withdrawal on the TSP
                        Withdrawal Options screen
                      </Col>
                    </Row>
                  )}

                  {formData["tsp_opt_tsp_monthly_pay"] == "1" && (
                    <Row>
                      <Col md={1}></Col>
                      <Col md={3}>
                        Selected TSP Monthly Payments on the TSP Withdrawal
                        Options screen
                      </Col>
                    </Row>
                  )}

                  {formData["tsp_opt_single_life_annuity"] == "1" && (
                    <>
                      {formData["tsp_opt_single_life_annuity_tsp"] && (
                        <>
                          <Row>
                            <Col md={1}></Col>
                            <Col md={5}>SINGLE LIFE ANNUITY</Col>
                          </Row>
                          <Row>
                            <Col md={1}></Col>
                            <Col md={5}>
                              TSP - Monthly Payment:
                              {formData["SLA TSP"]}
                              (example)
                            </Col>
                          </Row>
                          <Row>
                            <Col md={1}></Col>
                            <Col md={5}>
                              <ul>
                                <li>Cash Refund</li>
                              </ul>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  )}

                  {formData["tsp_opt_joint_life_annuity"] == "1" && (
                    <>
                      {formData["tsp_opt_joint_life_annuity_tsp"] && (
                        <>
                          <Row>
                            <Col md={1}></Col>
                            <Col md={5}>JOINT LIFE ANNUITY</Col>
                          </Row>
                          <Row>
                            <Col md={1}></Col>
                            <Col md={5}>
                              TSP - Monthly Payment:
                              {formData["JLA TSP"]}
                              (example)
                            </Col>
                          </Row>
                          <Row>
                            <Col md={1}></Col>
                            <Col md={5}>
                              <ul>
                                <li>100% Survivorship</li>
                                <li>Cash Refund</li>
                              </ul>
                            </Col>
                          </Row>

                          {formData[
                            "tsp_opt_joint_life_annuity_private_sector"
                          ] == "1" && (
                            <>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={5}>JOINT LIFE ANNUITY</Col>
                              </Row>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={5}>
                                  Private Sector - Monthly Payment:{" "}
                                  {formData["JLA PS"]} (example)
                                </Col>
                              </Row>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                  <ul>
                                    <li>100% Survivorship</li>
                                    <li>Cash Refund</li>
                                    <li>Emergency Saving Access</li>
                                    <li>2 Year Maximizer**</li>
                                    <li>Income Bonus</li>
                                  </ul>
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* )} */}
                <div className="body-text-wrapper mt-3">
                  <div className="heading-gray-wrapper">
                    <p className="heading-gray">TSP - ITEMS OF IMPORTANCE</p>
                  </div>
                  <ul className="heading-gray-ul">
                    <li>* Taxable income at retirement</li>
                    <li>
                      * Not accessible until Age 59 1/2 (or retirement) without
                      loan or penalty unless separation occurs at age 55 or
                      later.
                    </li>
                    <li>
                      * 10% + current tax rate penalty if withdrawn before 59
                      1/2 or retirement
                    </li>
                    <li>
                      * Hardship withdrawals available if negative monthly
                      income is proven
                    </li>
                    <li>
                      * Estimated TSP Monthly Income amounts displayed on
                      planner assumes current age and selection of a single life
                      annuity with level payments and no additional features.
                      Monthly amount calculated at a multiple of .0045
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {(formData["s2s3_receiving_pension"] == "yes" ||
            formData["s2s3_receiving_disability_pension"] == "yes" ||
            formData["have_other_pension"] == "yes" ||
            (formData["additional_sources_of_income_at_retire"] &&
              Array.isArray(
                formData["additional_sources_of_income_at_retire"]
              )) ||
            formData["family_status"] == "married") && (
            <section className="mt-3">
              <div className="box-wrapper">
                <div className="box-body">
                  <p className="heading">Pension & Income</p>
                  <div className="body-text-wrapper ">
                    {(formData["s2s3_receiving_pension"] == "yes" ||
                      formData["s2s3_receiving_disability_pension"] ==
                        "yes") && (
                      <>
                        <div className="p-text-wrapper mb-2">
                          <p className="main-p">
                            <strong>Monthly Military Pension</strong>
                          </p>
                        </div>

                        {formData["s2s3_receiving_pension"] == "yes" && (
                          <Row>
                            <Col md={1}></Col>
                            <Col md={2}>Military Pension</Col>
                            <Col md={4}>
                              {formData["s10_monthly_military_pension"]}
                            </Col>
                            <Col md={5}></Col>
                          </Row>
                        )}

                        {formData["s2s3_receiving_disability_pension"] ==
                          "yes" && (
                          <Row>
                            <Col md={1}></Col>
                            <Col md={4}>Military Disability Pension</Col>
                            <Col md={4}>
                              {showValue(
                                formData["s10_monthly_disability_amount"]
                              )}
                            </Col>
                            <Col md={5}></Col>
                          </Row>
                        )}
                      </>
                    )}

                    {formData["have_other_pension"] == "yes" && (
                      <>
                        <div className="body-text-wrapper mt-3 ">
                          <div className="p-text-wrapper mb-2">
                            <p className="main-p ">
                              <strong>Other Pensions</strong>
                            </p>
                          </div>

                          <Row>
                            <Col md={4} className="col-spacing">
                              Pension name:
                            </Col>
                            <Col md={4}>
                              {formData["have_other_pension_name"]}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4} className="col-spacing">
                              Monthly amount:
                            </Col>
                            <Col md={4}>
                              {" "}
                              {formData["have_other_pension_monthly_amount"]}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4} className="col-spacing">
                              Is this pension currently being taken?
                            </Col>
                            <Col md={4}>
                              {" "}
                              {formData["have_other_pension_being_taken"]}
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}

                    {formData["additional_sources_of_income_at_retire"] &&
                      Array.isArray(
                        formData["additional_sources_of_income_at_retire"]
                      ) && (
                        <div className="">
                          <div className="p-text-wrapper mb-2">
                            <p className="main-p ">
                              <strong>Additional Sources Of Income</strong>
                            </p>
                          </div>
                          <div className="text-space">
                            {formData[
                              "additional_sources_of_income_at_retire"
                            ] &&
                              Array.isArray(
                                formData[
                                  "additional_sources_of_income_at_retire"
                                ]
                              ) &&
                              formData[
                                "additional_sources_of_income_at_retire"
                              ].map((additionalIncome) => (
                                <>
                                  {additionalIncome ==
                                    "Investment accounts" && (
                                    <>
                                      <Row>
                                        <Col
                                          md={12}
                                          className="col-2 indent fw-bold"
                                        >
                                          Non-liquid investment accounts:
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Account name:</Col>
                                        <Col md={4}>
                                          {formData["s10_invest_acc_name"]}
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Current balance:</Col>
                                        <Col md={4}>
                                          {showValue(
                                            formData["s10_inv_current_bal"]
                                          )}
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Account type:</Col>
                                        <Col md={4}>
                                          {formData["s10_inv_acc_type"]}
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>
                                          Statement available to view:
                                        </Col>
                                        <Col md={4} className="text-capitalize">
                                          {
                                            formData[
                                              "s10_inv_statement_available"
                                            ]
                                          }
                                        </Col>
                                      </Row>

                                      {formData["s10_invest_acc_name_ext"] &&
                                        Object.keys(
                                          formData["s10_invest_acc_name_ext"]
                                        ) &&
                                        Object.keys(
                                          formData["s10_invest_acc_name_ext"]
                                        ).map((name) => (
                                          <>
                                            <Row>
                                              <Col md={1}></Col>
                                              <Col md={3}>Account name:</Col>
                                              <Col md={4}>
                                                {formData[
                                                  "s10_invest_acc_name_ext"
                                                ] &&
                                                  formData[
                                                    "s10_invest_acc_name_ext"
                                                  ][name]}
                                              </Col>
                                            </Row>

                                            <Row>
                                              <Col md={1}></Col>
                                              <Col md={3}>Current balance:</Col>
                                              <Col md={4}>
                                                {showValue(
                                                  formData[
                                                    "s10_inv_current_bal_ext"
                                                  ] &&
                                                    formData[
                                                      "s10_inv_current_bal_ext"
                                                    ][name]
                                                )}
                                              </Col>
                                            </Row>

                                            <Row>
                                              <Col md={1}></Col>
                                              <Col md={3}>Account type:</Col>
                                              <Col md={4}>
                                                {formData[
                                                  "s10_inv_acc_type_ex"
                                                ] &&
                                                  formData[
                                                    "s10_inv_acc_type_ex"
                                                  ][name]}
                                              </Col>
                                            </Row>

                                            <Row>
                                              <Col md={1}></Col>
                                              <Col md={3}>
                                                Statement available to view:
                                              </Col>
                                              <Col
                                                md={4}
                                                className="text-capitalize"
                                              >
                                                {formData[
                                                  "s10_inv_acc_type_ex"
                                                ] &&
                                                  formData[
                                                    "s10_inv_acc_type_ex"
                                                  ][name]}
                                              </Col>
                                            </Row>
                                          </>
                                        ))}
                                    </>
                                  )}
                                  {additionalIncome ==
                                    "Liquid Investment accounts" && (
                                    <>
                                      <Row>
                                        <Col
                                          md={5}
                                          className="col-2 indent fw-bold"
                                        >
                                          Liquid investment accounts:
                                        </Col>
                                        <Col md={4}></Col>
                                        <Col md={5}></Col>
                                      </Row>
                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Account name:</Col>
                                        <Col md={4}>
                                          {
                                            formData[
                                              "s10_liquid_invest_acc_name"
                                            ]
                                          }
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>
                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Current balance:</Col>
                                        <Col md={4}>
                                          {showValue(
                                            formData[
                                              "s10_liquid_inv_current_bal"
                                            ]
                                          )}
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>
                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Account type:</Col>
                                        <Col md={4}>
                                          {formData["s10_liquid_inv_acc_type"]}
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>
                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>
                                          Statement available to view:
                                        </Col>
                                        <Col md={4} className="text-capitalize">
                                          {
                                            formData[
                                              "s10_liquid_inv_statement_available"
                                            ]
                                          }
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>

                                      {formData[
                                        "s10_liquid_invest_acc_name_ext"
                                      ] &&
                                        Object.keys(
                                          formData[
                                            "s10_liquid_invest_acc_name_ext"
                                          ]
                                        ) &&
                                        Object.keys(
                                          formData[
                                            "s10_liquid_invest_acc_name_ext"
                                          ]
                                        ).map((name) => (
                                          <>
                                            <Row>
                                              <Col md={1}></Col>
                                              <Col md={3}>Account name:</Col>
                                              <Col md={4}>
                                                {formData[
                                                  "s10_liquid_invest_acc_name_ext"
                                                ] &&
                                                  formData[
                                                    "s10_liquid_invest_acc_name_ext"
                                                  ][name]}
                                              </Col>
                                              <Col md={5}></Col>
                                            </Row>
                                            <Row>
                                              <Col md={1}></Col>
                                              <Col md={3}>Current balance:</Col>
                                              <Col md={4}>
                                                {showValue(
                                                  formData[
                                                    "s10_liquid_inv_current_bal_ext"
                                                  ] &&
                                                    formData[
                                                      "s10_liquid_inv_current_bal_ext"
                                                    ][name]
                                                )}
                                              </Col>
                                              <Col md={5}></Col>
                                            </Row>

                                            <Row>
                                              <Col md={1}></Col>
                                              <Col md={3}>Account type:</Col>
                                              <Col md={4}>
                                                {formData[
                                                  "s10_liquid_inv_acc_type_ext"
                                                ] &&
                                                  formData[
                                                    "s10_liquid_inv_acc_type_ext"
                                                  ][name]}
                                              </Col>
                                              <Col md={5}></Col>
                                            </Row>

                                            <Row>
                                              <Col md={1}></Col>
                                              <Col md={3}>
                                                Statement available to view:
                                              </Col>
                                              <Col
                                                md={4}
                                                className="text-capitalize"
                                              >
                                                {formData[
                                                  "s10_liquid_inv_statement_available_ext"
                                                ] &&
                                                  formData[
                                                    "s10_liquid_inv_statement_available_ext"
                                                  ][name]}
                                              </Col>
                                              <Col md={5}></Col>
                                            </Row>
                                          </>
                                        ))}
                                    </>
                                  )}

                                  {additionalIncome == "401k" && (
                                    <>
                                      <Row>
                                        <Col
                                          md={5}
                                          className="col-2 indent fw-bold"
                                        >
                                          401k:
                                        </Col>
                                        <Col md={4}></Col>
                                        <Col md={5}></Col>
                                      </Row>
                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Account name:</Col>
                                        <Col md={4}>
                                          {formData["s10_addIncome401k_name"]}
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>

                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Current balance:</Col>
                                        <Col md={4}>
                                          {showValue(
                                            formData[
                                              "s10_addIncome401k_current_balance"
                                            ]
                                          )}
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>

                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Account type:</Col>
                                        <Col md={4}>
                                          {
                                            formData[
                                              "s10_addIncome401k_accType"
                                            ]
                                          }
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>
                                    </>
                                  )}

                                  {additionalIncome == "Real estate income" && (
                                    <>
                                      <Row>
                                        <Col
                                          md={5}
                                          className="col-2 indent fw-bold"
                                        >
                                          Real estate income:
                                        </Col>
                                        <Col md={4}></Col>
                                        <Col md={5}></Col>
                                      </Row>
                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Monthly Gross Income:</Col>
                                        <Col md={4}>
                                          {showValue(
                                            formData[
                                              "real_estate_income_monthly_income"
                                            ]
                                          )}
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>

                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Remaining mortgage:</Col>
                                        <Col md={4} className="text-capitalize">
                                          {
                                            formData[
                                              "real_estate_income_remaining_mortgage"
                                            ]
                                          }
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>

                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Remaining amount:</Col>
                                        <Col md={4}>
                                          {showValue(
                                            formData[
                                              "real_estate_income_remaining_mortgage_amount"
                                            ]
                                          )}
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>
                                    </>
                                  )}

                                  {additionalIncome == "Other income" && (
                                    <>
                                      <Row>
                                        <Col
                                          md={5}
                                          className="col-2 indent fw-bold"
                                        >
                                          Other income:
                                        </Col>
                                        <Col md={4}></Col>
                                        <Col md={5}></Col>
                                      </Row>
                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Income name:</Col>
                                        <Col md={4}>
                                          {
                                            formData[
                                              "additional_sources_of_income_at_retire_other_income_name"
                                            ]
                                          }
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>

                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={3}>Monthly Gross Income:</Col>
                                        <Col md={4}>
                                          {showValue(
                                            formData[
                                              "additional_sources_of_income_at_retire_other_income_amount"
                                            ]
                                          )}
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>
                                    </>
                                  )}
                                </>
                              ))}
                          </div>
                        </div>
                      )}

                    {formData["family_status"] == "married" && (
                      <>
                        <div className="p-text-wrapper mb-2">
                          <p className="main-p">
                            <strong>Spouse Employment:Pension & Income</strong>
                          </p>
                        </div>

                        <>
                          <Row>
                            <Col md={1}></Col>
                            <Col md={4}>Employment Status:</Col>
                            <Col md={4}>
                              {formData["spouse_employment_status"]}
                            </Col>
                            <Col md={3}></Col>
                          </Row>
                          {(formData["spouse_employment_status"] ==
                            "Employed" ||
                            formData["spouse_employment_status"] ==
                              "Self-employed") && (
                            <>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>Annual income:</Col>
                                <Col md={4}>
                                  {showValue(
                                    formData[
                                      "s2s6_spouse_employed_annual_income"
                                    ]
                                  )}
                                </Col>
                                <Col md={3}></Col>
                              </Row>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>Desired retirement age:</Col>
                                <Col md={4}>
                                  {
                                    formData[
                                      "s2s6_spouse_employed_desired_retirement_age"
                                    ]
                                  }
                                </Col>
                                <Col md={3}></Col>
                              </Row>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>Annual income:</Col>
                                <Col md={4}>
                                  {showValue(
                                    formData[
                                      "s2s6_spouse_employed_annual_income"
                                    ]
                                  )}
                                </Col>
                                <Col md={3}></Col>
                              </Row>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>Estimated monthly pension:</Col>
                                <Col md={4}>
                                  {showValue(
                                    formData[
                                      "s2s6_spouse_employed_est_monthly_pension"
                                    ]
                                  )}
                                </Col>
                                <Col md={3}></Col>
                              </Row>

                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>Age to start Social Security:</Col>
                                <Col md={4}>
                                  {
                                    formData[
                                      "s2s6_spouse_employed_age_to_start_social_security"
                                    ]
                                  }
                                </Col>
                                <Col md={3}></Col>
                              </Row>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                  Estimated monthly Social Security income:
                                </Col>
                                <Col md={4}>
                                  {showValue(
                                    formData[
                                      "s2s6_spouse_employed_est_mon_soc_sec_inc"
                                    ]
                                  )}
                                </Col>
                                <Col md={3}></Col>
                              </Row>
                            </>
                          )}

                          {formData["spouse_employment_status"] ==
                            "Retired" && (
                            <>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>Monthly pension:</Col>
                                <Col md={4}>
                                  {showValue(
                                    formData[
                                      "s2s6_spouse_ret_disability_monthly_payment"
                                    ]
                                  )}
                                </Col>
                                <Col md={3}></Col>
                              </Row>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                  Receiving Social Security payments:
                                </Col>
                                <Col md={4}>
                                  {
                                    formData[
                                      "s2s6_ret_disability_receiving_soc_sec_payments"
                                    ]
                                  }
                                </Col>
                                <Col md={3}></Col>
                              </Row>

                              {formData[
                                "s2s6_ret_disability_receiving_soc_sec_payments"
                              ] == "no" && (
                                <>
                                  <Row>
                                    <Col md={1}></Col>
                                    <Col md={4}>
                                      Age to start Social Security:
                                    </Col>
                                    <Col md={4}>
                                      {
                                        formData[
                                          "s2s6_spouse_ret_dis_age_to_start_soc_sec"
                                        ]
                                      }
                                    </Col>
                                    <Col md={3}></Col>
                                  </Row>
                                  <Row>
                                    <Col md={1}></Col>
                                    <Col md={4}>
                                      Estimated monthly Social Security income:
                                    </Col>
                                    <Col md={4}>
                                      {showValue(
                                        formData[
                                          "s2s6_spouse_ret_dis_est_monthly_soc_sec_income"
                                        ]
                                      )}
                                    </Col>
                                    <Col md={3}></Col>
                                  </Row>
                                </>
                              )}
                            </>
                          )}

                          {formData["spouse_employment_status"] ==
                            "Receiving disability payments" && (
                            <>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>Monthly payment amount:</Col>
                                <Col md={4}>
                                  {showValue(
                                    formData[
                                      "s2s6_spouse_ret_disability_monthly_payment"
                                    ]
                                  )}
                                </Col>
                                <Col md={3}></Col>
                              </Row>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={4}>
                                  Receiving Social Security payments:
                                </Col>
                                <Col md={4}>
                                  {
                                    formData[
                                      "s2s6_ret_disability_receiving_soc_sec_payments"
                                    ]
                                  }
                                </Col>
                                <Col md={3}></Col>
                              </Row>

                              {formData[
                                "s2s6_ret_disability_receiving_soc_sec_payments"
                              ] != "yes" ? (
                                <>
                                  <Row>
                                    <Col md={1}></Col>
                                    <Col md={4}>
                                      Age to start Social Security:
                                    </Col>
                                    <Col md={4}>
                                      {
                                        formData[
                                          "s2s6_spouse_ret_dis_age_to_start_soc_sec"
                                        ]
                                      }
                                    </Col>
                                    <Col md={3}></Col>
                                  </Row>
                                  <Row>
                                    <Col md={1}></Col>
                                    <Col md={4}>
                                      Estimated monthly Social Security income:
                                    </Col>
                                    <Col md={4}>
                                      {showValue(
                                        formData[
                                          "s2s6_spouse_ret_dis_est_monthly_soc_sec_income"
                                        ]
                                      )}
                                    </Col>
                                    <Col md={3}></Col>
                                  </Row>
                                </>
                              ) : (
                                <>
                                  <Row>
                                    <Col md={1}></Col>
                                    <Col md={4}>
                                      Monthly Social Security income:
                                    </Col>
                                    <Col md={4}>
                                      {showValue(
                                        formData[
                                          "s2s6_spouse_ret_dis_soc_sec_monthl_income"
                                        ]
                                      )}
                                    </Col>
                                    <Col md={3}></Col>
                                  </Row>
                                </>
                              )}
                            </>
                          )}
                        </>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="mt-3">
            <div className="box-wrapper">
              <div className="box-body">
                <p className="heading">Expenses at Retirement</p>

                <div className="body-text-wrapper mt-3 ">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p ">
                      <strong>Total Monthly Expenses</strong>
                    </p>
                  </div>
                  <div className="text-space">
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4}>Total Monthly Expenses:</Col>
                      <Col md={2}>
                        {showValue(formData["expenses_field_monthly_total"])}
                      </Col>
                      <Col md={5} className="error-text">
                        *Reduce Monthly Expenses<br></br>
                        **Preliminary Estimate Only
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="body-text-wrapper mt-3">
                  <div className="heading-gray-wrapper">
                    <p className="heading-gray">
                      Expenses at Retirement - ITEMS OF IMPORTANCE
                    </p>
                  </div>
                  <ul className="heading-gray-ul">
                    <li>*Reduce Monthly Expenses</li>
                    <li>**Preliminary Estimate Only</li>
                    <li>
                      ***Knowing exactly how much income is <b>needed</b> before
                      retiring will help your transition into retirement. Speed
                      the time now to take a more thorough inventory.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-3">
            <div className="box-wrapper">
              <div className="box-body">
                <p className="heading">Emergency Cash</p>

                <div className="body-text-wrapper">
                  <div className="p-text-wrapper mb-2">
                    <p className="main-p">
                      <strong>Total Available Emergency Cash</strong>
                    </p>
                  </div>
                  <div className="text-space">
                    <Row>
                      <Col md={1}></Col>
                      <Col md={5}>
                        Minimum Emergency Savings<br></br> (6 Months):
                      </Col>
                      <Col md={3} className="">
                        {showValue(formData["emergencyCashNeededatRetirement"])}{" "}
                        (Estimate)
                      </Col>
                      <Col md={3}></Col>
                    </Row>
                    {formData["s14_account_type"] &&
                      Array.isArray(formData["s14_account_type"]) &&
                      formData["s14_account_type"].map((key, index) => (
                        <Row>
                          <Col md={1}></Col>
                          <Col md={5}>
                            {formData["s14_institution_name"] &&
                              formData["s14_institution_name"][index]}
                          </Col>
                          <Col md={3}>
                            {showValue(
                              formData["s14_balance"] &&
                                formData["s14_balance"][index]
                            )}{" "}
                            ({key})
                          </Col>
                          <Col md={3}></Col>
                        </Row>
                      ))}

                    <Row>
                      <Col md={1}></Col>
                      <Col md={5} className="text-bold table-border-top">
                        Total
                      </Col>
                      <Col md={3} className="text-bold table-border-top">
                        {showValue(formData["totalInstitutionalBalance"])}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={5} className="total-text-red">
                        Emergency Cash Needed
                      </Col>
                      <Col md={4} className="total-text-red">
                        {showValue(
                          convertValue(
                            formData["expenses_field_monthly_total"]
                          ) * 6
                        )}{" "}
                        <span className="conclusion-text">(at retirement)</span>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="body-text-wrapper mt-3">
                  <div className="heading-gray-wrapper">
                    <p className="heading-gray">
                      Emergency Cash - ITEMS OF IMPORTANCE
                    </p>
                  </div>
                  <ul className="heading-gray-ul">
                    <li>
                      * Additional emergency cash needed (
                      {showValue(formData["emergencyCashNeeded"])})
                    </li>
                    <li>* In addition to TSP account</li>
                    <li>
                      * Used for career emergency expenses and retirement
                      preparation
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-3">
            <div className="box-wrapper">
              <div className="box-body">
                <p className="heading">Social Security</p>

                {formData["s8s2_ss_amount_verified"] ||
                convertValue(formData["s8s2_income_amount"]) ||
                convertValue(formData["s8s2_age_to_begin"]) ? (
                  <div className="body-text-wrapper ">
                    <div className="p-text-wrapper mb-2">
                      <p className="main-p">
                        <strong>Social Security Income</strong>
                      </p>
                    </div>
                    <div className="text-space">
                      {convertValue(formData["s8s2_income_amount"]) ? (
                        <Row>
                          <Col md={1}></Col>
                          <Col md={3} className="conclusion-green">
                            Income Amount:
                          </Col>
                          <Col md={3} className="conclusion-green">
                            {showValue(formData["s8s2_income_amount"])}
                          </Col>
                          <Col md={5} className="error-text">
                            *Taxable income
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      {convertValue(formData["s8s2_age_to_begin"]) ? (
                        <Row>
                          <Col md={1}></Col>
                          <Col md={3}>Age to Begin:</Col>
                          <Col md={3}>{formData["s8s2_age_to_begin"]}</Col>
                          <Col md={5}></Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      {formData["s8s2_ss_amount_verified"] && (
                        <Row>
                          <Col md={1}></Col>
                          <Col md={3}>Verified by:</Col>
                          <Col md={3}>
                            {formData["s8s2_ss_amount_verified"]}
                          </Col>
                          <Col md={5}></Col>
                        </Row>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="body-text-wrapper mt-3">
                  <div className="heading-gray-wrapper">
                    <p className="heading-gray">
                      Social Security - ITEMS OF IMPORTANCE
                    </p>
                  </div>
                  <ul className="heading-gray-ul">
                    <li>*Taxable income at retirement</li>
                    <li>*SS system is financially unstable</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-3">
            <div className="box-wrapper">
              <div className="box-body">
                <p className="heading">RETIREMENT INCOME PROJECTION</p>

                <div className="body-text-wrapper">
                  <Row>
                    <Col md={1}></Col>
                    <Col md={4} className="">
                      1. <strong>FERS</strong> Monthly Annuity
                    </Col>
                    <Col md={3} className="">
                      <string> {showValue(fersBasicAnnuity)}</string>
                    </Col>
                    <Col md={4} className="small-description">
                      w/o survivor benefit
                    </Col>
                  </Row>

                  {formData["item_srs"] && formData["age"] > 62 ? (
                    <>
                      <Row>
                        <Col md={1}></Col>
                        <Col md={4} className="">
                          2. <strong>SRS</strong> Monthly Income
                        </Col>
                        <Col md={3} className="">
                          {showValue(formData["item_srs"])}
                        </Col>
                        <Col md={4} className="small-description">
                          Age 62
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row>
                      <Col md={1}></Col>
                      <Col md={4} className="">
                        2. <strong>SS</strong> Monthly Income
                      </Col>
                      <Col md={3} className="">
                        {showValue(formData["age_62_ss_benefit"])}{" "}
                      </Col>
                      <Col md={4} className="small-description">
                        Age 62
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={1}></Col>
                    <Col md={4} className="">
                      3. <strong>TSP Withdrawal</strong> ({" "}
                      {formData["TspStatus"]})
                    </Col>
                    <Col md={3} className="">
                      {showValue(formData["tspAmount"])}{" "}
                    </Col>
                    <Col md={4} className="error-text small-description">
                      ***Action required.{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}></Col>
                    <Col md={4} className=" ">
                      4. <strong>Other</strong>
                    </Col>
                    <Col md={2} className="table-border">
                      {/* <div className="conclusion-input-wrapper"> */}
                      <div className="conclusion-input-wrapper">
                        ${" "}
                        {formData["pdf"] ? (
                          <>{showValue(formData["totalMonthIncomeRIP"])}</>
                        ) : (
                          <>
                            <Form.Control
                              className="input-box fw-bold conclusion-input"
                              type="text"
                              name="conclusion_ret_inc_pro_other"
                              value={formData.conclusion_ret_inc_pro_other}
                              onBlur={(e) => handleBlur(e, false, setformData)}
                              onFocus={(event) =>
                                handleFocus(event, setformData)
                              }
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                              autoComplete="off"
                            />
                          </>
                        )}
                      </div>
                      {/* </div> */}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={1}></Col>
                    <Col md={4} className="total-text-greeny">
                      Total Monthly Income
                    </Col>
                    <Col md={4} className="total-text-greeny">
                      {showValue(formData["totalMonthIncomeRIP"])}{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}></Col>
                    <Col md={4} className="total-text-red">
                      Total Monthly Expenses
                    </Col>
                    <Col md={4} className="total-text-red">
                      {showValue(formData["expenses_field_monthly_total"])}
                    </Col>
                  </Row>

                  <ul className="heading-gray-ul">
                    <li>
                      *All the above items will be considered taxable income at
                      retirement.
                    </li>
                    <li>**Determine Private Sector Annuity Income Amount</li>
                    <li>
                      ***Income based on {showValue(formData["tsp_opt_amount"])}{" "}
                      of TSP funds used for monthly income only.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="body-text-wrapper mt-3 section-background">
              <div className="heading-gray-wrapper">
                <p className="heading-gray">ITEMS OF IMPORTANCE</p>
              </div>
              <div className="mb-2 warning-section">
                <Row>
                  <Col md={2}></Col>
                  <Col md={10} className="conclusion-primary-heading mb-2">
                    <strong> Primary Retirement Concerns:</strong>
                  </Col>
                </Row>

                {AboutFormList.map((item) => (
                  <>
                    {formData.s1q1 && formData.s1q1.includes(item) && (
                      <>
                        {item == "Other" &&
                        formData.s1q1 &&
                        formData.s1q1.includes("Other") ? (
                          <Row className="text-bold">
                            <Col md={2} className="mx-3"></Col>
                            <Col md={8}>
                              <i className="fa-solid fa-square-check text-success px-2 fa-lg">
                                {" "}
                              </i>{" "}
                              {item}: {formData.screen1OtherValue}
                            </Col>
                          </Row>
                        ) : (
                          <Row className="text-bold">
                            <Col md={2} className="mx-3"></Col>
                            <Col md={8}>
                              <i className="fa-solid fa-square-check text-success px-2 fa-lg">
                                {" "}
                              </i>{" "}
                              {item}
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </>
                ))}
              </div>
              <div className="warning-section">
                <Row className="warning-box-wrapper">
                  <Col md={2}>
                    <div>
                      <Image className="warning-image" src={Images.warning} />
                      <p className="warn-important">IMPORTANT</p>
                    </div>
                  </Col>
                  <Col md={10}>
                    <div className="red-container">
                      <div className="warn-text-wrapper">
                        <p className="warn-text">FEGLI</p>
                      </div>
                      <ul className="heading-gray-ul warn-li">
                        <li>
                          *<strong>Extra Benefit</strong> eliminated for all
                          Federal Employees at 45.
                        </li>
                        <li>
                          **<strong>Option B</strong> Coverage increases
                          automatically every 5 yrs.
                        </li>
                        <li>
                          ***Personal health and cost to fix FEGLI will be
                          jeopardized as you get older.
                        </li>
                        <li>
                          ****Permanent Protection not sufficient at Age 90
                        </li>
                      </ul>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <FloatingLabel
                          controlId="floatingInput"
                          className="floatingInput"
                          label="Please provide your strategy or plan of action for handling this issue. (50 character minimum)."
                        >
                          <Form.Control
                            className="remove-focus bg-white"
                            as="textarea"
                            name="fegli_plan_to_handle_this_issue_fegli"
                            value={
                              formData.fegli_plan_to_handle_this_issue_fegli
                            }
                            required
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                            placeholder="Please provide your strategy or plan of action for handling this issue. (50 character minimum)."
                            style={{ height: "92px" }}
                            minLength={50}
                            autoComplete="off"
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <div className="m-1 d-flex justify-content-end">
                        {formData.fegli_plan_to_handle_this_issue_fegli &&
                        formData.fegli_plan_to_handle_this_issue_fegli.length
                          ? formData.fegli_plan_to_handle_this_issue_fegli
                              .length
                          : 0}
                        /50
                      </div>
                      {(formData.fegli_plan_to_handle_this_issue_fegli &&
                        formData.fegli_plan_to_handle_this_issue_fegli.length >
                          0 &&
                        formData.fegli_plan_to_handle_this_issue_fegli.length <
                          50) ||
                        (validated &&
                          !formData.fegli_plan_to_handle_this_issue_fegli && (
                            <div className="heading-gray-ul">
                              Minimum 50 characters of text required.
                            </div>
                          ))}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={2}></Col>
                  <Col md={10}>
                    <div className="text-container">
                      <div className="normal-text-wrapper">
                        <p className="normal-text">Retirement Planning</p>
                      </div>
                      <ul className="normal-warn">
                        <li>
                          *Verify <b>Military Time</b> is credited
                        </li>
                        <li>
                          **If Married and/or Children. Verify{" "}
                          <b>beneficiary designations</b> are correct.
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={2}></Col>
                  <Col md={10}>
                    <div className="text-container">
                      <div className="normal-text-wrapper">
                        <p className="normal-text">
                          <strong>FERS and SRS</strong>
                        </p>
                      </div>
                      <Row>
                        <Col md={6}>
                          {" "}
                          <ul className="normal-warn">
                            <li>*Loss of Income Per Month after Retirement</li>
                          </ul>
                        </Col>
                        <Col md={6}>({showValue(formData["Loss"])})</Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          {" "}
                          <ul className="normal-warn">
                            <li>**Survivor Deduction</li>
                          </ul>
                        </Col>
                        <Col md={6}>({showValue(formData["SURVIVOR"])})</Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          {" "}
                          <ul className="normal-warn">
                            <li>
                              ***Estimated 20% Fed and State Tax Deduction
                            </li>
                          </ul>
                        </Col>
                        <Col md={6}>
                          ({showValue(formData["TAXDEDUCTION"])})
                        </Col>
                      </Row>
                      {formData["FEHB"] && (
                        <Row>
                          <Col md={6}>
                            {" "}
                            <ul className="normal-warn">
                              <li>****FEHB Health Insurance Premiums</li>
                            </ul>
                          </Col>
                          <Col md={6}>({showValue(formData["FEHB"])})</Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={6}>
                          {" "}
                          <ul className="normal-warn">
                            <li>
                              *****FERS and SRS payments are fully taxable
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="warning-section mt-4">
                <Row className="warning-box-wrapper">
                  <Col md={2}>
                    <div>
                      <Image className="warning-image" src={Images.warning} />
                      <p className="warn-important">IMPORTANT</p>
                    </div>
                  </Col>
                  <Col md={10}>
                    <div className="red-container">
                      <div className="warn-text-wrapper">
                        <p className="warn-text">Thrift Savings Plan</p>
                      </div>
                      <ul className="heading-gray-ul warn-li">
                        <li>*Taxable Income at retirement</li>
                        <li>
                          **Not accessible until Age 59 1/2 (or retirement)
                          without loan or penalty
                        </li>
                        <li>
                          ***10% + current tax rate penalty if withdrawn before
                          59 1/2 or retirement
                        </li>
                        <li>
                          ****Hardship withdrawals available if negative monthly
                          income is proven
                        </li>
                      </ul>

                      <FloatingLabel
                        controlId="floatingInput"
                        className="floatingInput"
                        label="Please provide your strategy or plan of action for handling this issue. (50 character minimum)."
                      >
                        <Form.Control
                          className="remove-focus bg-white"
                          as="textarea"
                          required
                          name="fegli_plan_to_handle_this_issue_tsp"
                          value={formData.fegli_plan_to_handle_this_issue_tsp}
                          onChange={(event) =>
                            onChangeHandler(event, setformData)
                          }
                          placeholder="Please provide your strategy or plan of action for handling this issue. (50 character minimum)."
                          style={{ height: "92px" }}
                          minLength={50}
                          autoComplete="off"
                        />
                      </FloatingLabel>
                      <div className="m-1 d-flex justify-content-end">
                        {formData.fegli_plan_to_handle_this_issue_tsp &&
                        formData.fegli_plan_to_handle_this_issue_tsp.length
                          ? formData.fegli_plan_to_handle_this_issue_tsp.length
                          : 0}
                        /50
                      </div>
                      {(formData.fegli_plan_to_handle_this_issue_tsp &&
                        formData.fegli_plan_to_handle_this_issue_tsp.length >
                          0 &&
                        formData.fegli_plan_to_handle_this_issue_tsp.length <
                          50) ||
                        (validated &&
                          !formData.fegli_plan_to_handle_this_issue_tsp && (
                            <div className="heading-gray-ul">
                              Minimum 50 characters of text required.
                            </div>
                          ))}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={2}></Col>
                  <Col md={10}>
                    <div className="text-container">
                      <div className="normal-text-wrapper">
                        <p className="normal-text">Expenses at Retirement</p>
                      </div>
                      <ul className="normal-warn">
                        <li>*Reduce Monthly Expenses</li>
                        <li>**Preliminary Estimate Only</li>
                        <li>
                          ***Knowing exactly how much income is <b>needed</b>{" "}
                          before retiring will help your transition into
                          retirement.
                        </li>
                        <li>****Complete the Budget and Expenses Worksheet</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={2}></Col>
                  <Col md={10}>
                    <div className="text-container">
                      <div className="normal-text-wrapper">
                        <p className="normal-text">Emergency Cash</p>
                      </div>
                      <ul className="normal-warn">
                        <li>*Additional emergency cash needed ($25,000)</li>
                        <li>**In addition to TSP account</li>
                        <li>
                          ***Used for career emergency expenses and retirement
                          preparation
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={2}></Col>
                  <Col md={10}>
                    <div className="text-container">
                      <div className="normal-text-wrapper">
                        <p className="normal-text">Social Security</p>
                      </div>
                      <ul className="normal-warn">
                        <li>*Taxable income at retirement</li>
                        <li>**SS system is financially unstable</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row className="warning-box-wrapper mt-3">
                  <Col md={2}>
                    <div>
                      <Image className="warning-image" src={Images.warning} />
                      <p className="warn-important">IMPORTANT</p>
                    </div>
                  </Col>
                  <Col md={10}>
                    <div className="red-container">
                      <div className="warn-text-wrapper">
                        <p className="warn-text">Retirement Income Estimate</p>
                      </div>
                      <ul className="heading-gray-ul warn-li">
                        <li>*Private Sector Annuity Income</li>
                        <li>
                          **Used for career emergency expenses and retirement
                          preparation
                        </li>
                      </ul>

                      <FloatingLabel
                        controlId="floatingInput"
                        className="floatingInput"
                        label="Please provide your strategy or plan of action for handling this issue. (50 character minimum)."
                      >
                        <Form.Control
                          className="remove-focus bg-white"
                          as="textarea"
                          required
                          name="fegli_plan_to_handle_this_issue_ret_income"
                          value={
                            formData.fegli_plan_to_handle_this_issue_ret_income
                          }
                          onChange={(event) =>
                            onChangeHandler(event, setformData)
                          }
                          placeholder="Please provide your strategy or plan of action for handling this issue. (50 character minimum)."
                          style={{ height: "92px" }}
                          minLength={50}
                          autoComplete="off"
                        />
                      </FloatingLabel>
                      <div className="m-1 d-flex justify-content-end">
                        {formData.fegli_plan_to_handle_this_issue_ret_income &&
                        formData.fegli_plan_to_handle_this_issue_ret_income
                          .length
                          ? formData.fegli_plan_to_handle_this_issue_ret_income
                              .length
                          : 0}
                        /50
                      </div>
                      {(formData.fegli_plan_to_handle_this_issue_ret_income &&
                        formData.fegli_plan_to_handle_this_issue_ret_income
                          .length > 0 &&
                        formData.fegli_plan_to_handle_this_issue_ret_income
                          .length < 50) ||
                        (validated &&
                          !formData.fegli_plan_to_handle_this_issue_ret_income && (
                            <div className="heading-gray-ul">
                              Minimum 50 characters of text required.
                            </div>
                          ))}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
          <section className="mt-3">
            <div className="notes-container">
              Important Notes to File (Optional)
            </div>
            <div className="notes-text">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  className="notes-textarea"
                  as="textarea"
                  name="agentNotes"
                  value={formData["agentNotes"]}
                  onChange={(event) => onChangeHandler(event, setformData)}
                  placeholder="Include any notes that will be helpful for the federal employee you're assisting. These notes will not included in their summary."
                  rows={5}
                  autoComplete="off"
                />
              </Form.Group>
            </div>
          </section>
          {validated && (
            <div className="red-text text-center mt-2">
              *Complete required fields to continue
            </div>
          )}
          <div className="mt-4 mb-2 d-flex justify-content-center submit">
            <div className="text-center">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default ConclusionForm;
