import React, { useState, useEffect } from "react";
import { Card, FloatingLabel, Form } from "react-bootstrap";
import { AboutFormList } from "../../../constants/index";
import { usePlannerContext } from "../../../contexts/planner.contexts";

const AboutForm = ({ submit }) => {
  const { plannerData, onChangeHandler, setOnChangeStatus } =
    usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);

  const handleCheckbox = (value, action) => {
    let payload = formData.s1q1 ? formData.s1q1 : [];
    if (action) {
      payload.push(value);
    } else {
      payload = payload.filter((val) => val !== value);
    }
    handleStateByField("s1q1", payload);
  };

  const handleStateByField = (name, value) => {
    setOnChangeStatus(true);

    setformData({
      ...formData,
      [name]: value,
    });
  };
  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="moreAboutYou">
        <div className="qs-box-more single-screen">
          <h1 className="question-more">A LITTLE MORE ABOUT YOU</h1>
          <Form
            id="form_more_about_you"
            className="subform"
            noValidate
            onSubmit={submitFormdata}
          >
            <div className="answers-more">
              {AboutFormList.map((item) => (
                <div className="answer-more check-value answer-checkbox selected">
                  {formData.s1q1 && formData.s1q1.includes(item) ? (
                    <div className="success-background">
                      {" "}
                      <i
                        className="answer-summary-1 fa fa-check-square-o check1"
                        onClick={() => handleCheckbox(item, false)}
                      >
                        {item}
                      </i>
                      {item == "Other" &&
                        formData.s1q1 &&
                        formData.s1q1.includes("Other") && (
                          <div className="single-field otherArea ">
                            <div className="textbox-wrapper h-100">
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Notes"
                              >
                                <Form.Control
                                  autoComplete="off"
                                  className="remove-focus"
                                  as="textarea"
                                  name="screen1OtherValue"
                                  value={formData.screen1OtherValue}
                                  onChange={(event) =>
                                    onChangeHandler(event, setformData)
                                  }
                                  placeholder="Notes"
                                  style={{ height: "92px" }}
                                />
                              </FloatingLabel>
                            </div>
                          </div>
                        )}
                    </div>
                  ) : (
                    <i
                      className="answer-summary-2 fa fa-square-o check2"
                      onClick={() => handleCheckbox(item, true)}
                    >
                      {item}
                    </i>
                  )}
                </div>
              ))}
            </div>
            <div className="btn-wrapper">
              <div className="text-center mt-4">
                <button className="btn-next btn question-next" type="submit">
                  CONTINUE
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Card.Body>
    </>
  );
};

export default AboutForm;
