import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { formatValue } from "../../../services/helperService";
import capitalList from "../../../constants/benefits/capital_legacy_reates.json";
import seniorList from "../../../constants/benefits/senior_choice_rates.json";
import moment from "moment";
import { usePlannerContext } from "../../../contexts/planner.contexts";

import jwt_decode from "jwt-decode";

const BenefitForm = ({ submit, formData }) => {
  const { plannerData } = usePlannerContext();

  const [isCapitalLegacy, setCapitalLegacy] = useState(true);
  const [legencyDetail, setLegencyDetail] = useState({});
  const [decodedToken, setDecodedToken] = useState({});

  useEffect(() => {
    let legacyStatus = true;
    let payload = {};
    const dobDate = moment(formData["dob"]);
    let age = moment().diff(dobDate, "years", true);
    let legencyList = capitalList;

    const localToken = localStorage.getItem("token");

    setDecodedToken(jwt_decode(localToken));

    if (age) {
      if (age >= 55) {
        legacyStatus = false;
        legencyList = seniorList;
      }
      age = Math.floor(age);
    } else {
      age = 0;
    }
    const legencyData = legencyList.find((item) => item["age"] == age);
    if (legencyData) {
      const lifeInsuranceCapital = [
        {
          name: "Year 1",
          value: formatValue(legencyData["li_amnt_year_1"]),
          protection: formatValue(legencyData["pro_amnt_year_1"]),
        },
        {
          name: "Year 2",
          value: formatValue(legencyData["li_amnt_year_2"]),
          protection: formatValue(legencyData["pro_amnt_year_2"]),
        },
        {
          name: "Year 11",
          value: formatValue(legencyData["li_amnt_year_11"]),
          protection: formatValue(legencyData["pro_amnt_year_11"]),
        },
        {
          name: "Age 65",
          value: formatValue(legencyData["li_amnt_age_65"]),
          protection: formatValue(legencyData["pro_amnt_age_65"]),
        },
      ];

      const lifeInsuranceSenior = [
        {
          name: "Year 1",
          value: formatValue(legencyData["li_amnt_year_1"]),
          protection: formatValue(legencyData["pro_amnt_year_1"]),
        },
      ];

      const otherInsuranceCapital = [
        {
          name: "Year 1",
          value: formatValue(legencyData["ri_amnt_year_1"]),
          protection: formatValue(legencyData["val_at_1_per_year_1"]),
        },
        {
          name: "Year 2",
          value: formatValue(legencyData["ri_amnt_year_2"]),
          protection: formatValue(legencyData["val_at_1_per_year_2"]),
        },
        {
          name: "Year 11",
          value: formatValue(legencyData["ri_amnt_year_11"]),
          protection: formatValue(legencyData["val_at_1_per_year_11"]),
        },
        {
          name: "Age 65",
          value: formatValue(legencyData["ri_amnt_age_65"]),
          protection: formatValue(legencyData["val_at_1_per_age_65"]),
        },
      ];

      let otherInsuranceSenior = [
        {
          name: "Year 1",
          value: formatValue(legencyData["trade_amnt_year_1"]),
          protection: formatValue(legencyData["val_at_1_per_year_1"]),
        },
      ];

      if (age <= 65) {
        otherInsuranceSenior = [
          ...otherInsuranceSenior,
          {
            name: "Age 65",
            value: formatValue(legencyData["trade_amnt_age_65"]),
            protection: formatValue(legencyData["val_at_1_per_age_65"]),
          },
        ];
      }
      if (age <= 70) {
        otherInsuranceSenior = [
          ...otherInsuranceSenior,
          {
            name: "Age 70",
            value: formatValue(legencyData["trade_amnt_age_70"]),
            protection: formatValue(legencyData["val_at_1_per_age_70"]),
          },
        ];
      }
      payload = {
        title: legacyStatus ? "CAPITAL LEGACY" : "SENIOR CHOICE",
        "bi-weekly": legacyStatus ? "$105" : "$" + legencyData["bwlc"],
        secondTitle: legacyStatus ? "ROTH IRA" : "FPAR (Optional)",
        lifeInsurance: legacyStatus
          ? lifeInsuranceCapital
          : lifeInsuranceSenior,
        otherInsurance: legacyStatus
          ? otherInsuranceCapital
          : otherInsuranceSenior,
      };
    } else {
      payload = {
        title: "CAPITAL LEGACY",
        "bi-weekly": "$105",
        secondTitle: "ROTH IRA",
        lifeInsurance: {
          name: "N/A",
          value: "N/A",
          protection: "N/A",
        },
        otherInsurance: {
          name: "N/A",
          value: "N/A",
          protection: "N/A",
        },
      };
    }
    setLegencyDetail(payload);
    setCapitalLegacy(legacyStatus);
  }, [formData["dob"]]);

  const capitalBenefits = () => (
    <tr>
      <td className="border-box">
        <div className="greybox">
          <ul className="grey-box-list1">
            <li>
              Endowment Paid After 10 years
              <ul className="style-star">
                <li>(1st year premium refunded)</li>
              </ul>
            </li>
            <li>Level Premium</li>
            <li>Portable</li>
            <li>Guaranteed Coverage Amount</li>
            <li>Preferred Rates Available</li>
            <li style={{ listStyleType: "none" }}>&nbsp;</li>
          </ul>
          <div className="black-strip">
            CAPITAL LEGACY PLUS Options Available
          </div>
          <ul className="grey-box-list1">
            <li style={{ listStyleType: "none" }}>&nbsp;</li>
            <li>Spouse Term Coverage Available</li>
            <li>Child Term Coverage Available</li>
          </ul>
        </div>
      </td>
      <td>
        <div className="greybox">
          <ul className="grey-box-list1">
            <li>Payroll Deduction</li>
            <li>
              Competitive Rates
              <ul className="style-star">
                <li>Guarantee 3%</li>
                <li>Current 4%</li>
              </ul>
            </li>
            <li>Flexible (increase, decrease, stop)</li>
            <li>Retirement Supplement</li>
            <li>Emergency Cash Option</li>
          </ul>
          <div className="black-strip">GROWS INCOME TAX FREE</div>
          <ul className="grey-box-list1">
            <li>Compound Interest</li>
            <li>No Investment Risk</li>
            <li>Income for Life Option</li>
          </ul>
        </div>
      </td>
    </tr>
  );

  const seniorBenefits = () => (
    <tr>
      <td className="border-box">
        <div className="greybox">
          <ul className="grey-box-list1">
            <li>Level Premium</li>
            <li>Portable</li>
            <li>Guaranteed Coverage Amount</li>
          </ul>
          <div class="black-strip" style={{ backgroundColor: "transparent" }}>
            &nbsp;
          </div>
          <ul class="grey-box-list1">
            <li style={{ listStyleType: "none" }}>&nbsp;</li>
            <li style={{ listStyleType: "none" }}>&nbsp;</li>
            <li style={{ listStyleType: "none" }}>&nbsp;</li>
            <li style={{ listStyleType: "none" }}>&nbsp;</li>
            <li style={{ listStyleType: "none" }}>&nbsp;</li>
            <li style={{ listStyleType: "none" }}>&nbsp;</li>
          </ul>
        </div>
      </td>
      <td>
        <div className="greybox">
          <ul className="grey-box-list1">
            <li>Payroll Deduction</li>
            <li>
              Competitive Rates
              <ul className="style-star">
                <li>Guarantee 3%</li>
                <li>Current 4%</li>
              </ul>
            </li>
            <li>Flexible (increase, decrease, stop)</li>
            <li>Retirement Supplement</li>
            <li>Emergency Cash Option</li>
          </ul>
          <div className="black-strip">INTERIM PAY SOLUTION</div>
          <ul className="grey-box-list1">
            <li>Compound Interest</li>
            <li>No Investment Risk</li>
            <li>Income for Life Option</li>
          </ul>
        </div>
      </td>
    </tr>
  );
  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <div className="benefitForm-section">
      <Card.Body>
        <Form
          id="form_benefits"
          className="benefits"
          noValidate
          onSubmit={submitFormdata}
        >
          <div className={isCapitalLegacy ? "section-group" : "section-group2"}>
            <div className="content-box">
              <p className="box-title">{legencyDetail["title"]}</p>
              <p className="box-sub-title">Benefits Worksheet</p>
              <div className="box-section d-flex justify-content-between">
                <div className="d-flex">
                  <div className="title text-nowrap">Federal Employee:</div>
                  <div className="sub-title">{formData["name"]}</div>
                </div>
                <div className="d-flex">
                  <div className="title text-nowrap">
                    Bi-Weekly Contribution:
                  </div>
                  <div className="sub-title">{legencyDetail["bi-weekly"]}</div>
                </div>
              </div>

              <table className="tbl-second">
                <tbody>
                  <tr className="second-tbl-first-row">
                    <th>LIFE INSURANCE</th>
                    <th>{legencyDetail["secondTitle"]} </th>
                  </tr>
                  <tr>
                    <th className="protection-th">
                      <div>
                        <div className="row">
                          <div className="col-md-7"></div>
                          <div className="col-md-5">PROTECTION</div>
                        </div>
                        {legencyDetail["lifeInsurance"] &&
                          legencyDetail["lifeInsurance"].length > 0 &&
                          legencyDetail["lifeInsurance"].map(
                            ({ name, value, protection }) => (
                              <div className="row">
                                <div className="col-md-3">{name}:</div>
                                <div className="col-md-4">${value}</div>
                                <div className="col-md-5">${protection}</div>
                              </div>
                            )
                          )}
                      </div>
                    </th>
                    <th className="protection-th2">
                      <div>
                        <div className="row">
                          <div className="col-md-7"></div>
                          <div className="col-md-5">VALUE @ 3%</div>
                        </div>
                        {legencyDetail["otherInsurance"] &&
                          legencyDetail["otherInsurance"].length > 0 &&
                          legencyDetail["otherInsurance"].map(
                            ({ name, value, protection }) => (
                              <div className="row">
                                <div className="col-md-3">{name}:</div>
                                <div className="col-md-4">${value}</div>
                                <div className="col-md-5 greeny">
                                  ${protection}
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </th>
                  </tr>
                  {isCapitalLegacy ? capitalBenefits() : seniorBenefits()}
                </tbody>
              </table>
            </div>
          </div>

          <p className="text-center">
            <a
              href="https://www.insuranceapplication.com/"
              className="btn btn-primary btnSeeIfIQualify benefits-btn"
              target="_blank"
            >
              See If I Qualify
            </a>
          </p>

          <p className="text-center mt-2">
            You Have Completed the Benefits Portion of the BPG.
          </p>
          {formData["age"] >= 55 && plannerData["bpg_access"] != 2 ? (
            <div className="text-center mt-2 mb-4">
              <button
                className="btn-next btn question-next w-auto"
                type="submit"
              >
                Enter Retirement Section
              </button>
            </div>
          ) : (
            <div className="text-center mt-2 mb-4 ">
              <button
                className="btn-next btn question-next w-autow-100"
                type="submit"
              >
                Continue
              </button>
            </div>
          )}
        </Form>
      </Card.Body>
    </div>
  );
};

export default BenefitForm;
