import React from "react";

const Summary = () => {
  return (
    <div className="article-section" data-screen="summary">
      <div className="container">
        <p>
          <strong>
            <u>SECTION V</u> - CL Close
          </strong>
          <br></br>
          <strong>
            <em>BENEFITS SUMMARY</em>
          </strong>
        </p>
        <ol start="61" type="1">
          <li>
            <em>
              Review the Benefits Summary with the client to ensure they
              understand the gaps and pitfalls (in red).  Input any notes at the
              bottom of the page.<strong></strong>
            </em>
          </li>
        </ol>
        <p>
          <strong>
            <em>Press </em>
          </strong>
          <strong>
            <em>
              <span className="green">CONTINUE</span> and move into The Capital
              Legacy Benefits Worksheet portion of the BPG
            </em>
          </strong>
        </p>
      </div>
    </div>
  );
};

export default Summary;
