import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Col, Form, Image, Row } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import {
  convertValue,
  formatCalculatedValue,
  formatValue,
} from "../../../services/helperService";
import Images from "../../CRMs/crm2.0";
const ExpenseForm = ({ submit }) => {
  const {
    plannerData,
    handleBlur,
    onChangeHandler,
    handleKeyPress,
    handleFocus,
  } = usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      let data = plannerData;
      data["expenses_field5"] =
        (convertValue(plannerData["pay_s2_32"]) * 26) / 12;
      data["expenses_field5"] = formatCalculatedValue(data["expenses_field5"]);

      setformData(data);
    }
  }, [plannerData]);

  const [total, setTotal] = useState(
    formatValue(formData["expenses_field_monthly_total"])
  );
  const [moneyLeftAfterPayment, setMoneyLeftAfterPayment] = useState(
    formatValue(formData["moneyLeftAfterPayment"])
  );
  const [currentTotal, setCurrentTotal] = useState(
    formatValue(formData["expenses_field_monthly_total2"])
  );
  const [netToBank, setNetToBank] = useState(formatValue(formData["money"]));
  useEffect(() => {
    let payload = {};
    payload["expenses_field_monthly_total"] = total;
    payload["expenses_field_monthly_total2"] = currentTotal;
    payload["money"] = netToBank;
    payload["moneyLeftAfterPayment"] = moneyLeftAfterPayment;
    if (formData && Object.keys(formData).length > 0) {
      setformData({
        ...formData,
        ...payload,
      });
    }
  }, [moneyLeftAfterPayment, netToBank, currentTotal, total]);

  useEffect(() => {
    totalMonthlyExpense();
    retirementMonthlyExpense();
  }, [
    formData["expenses_field1"],
    formData["expenses_field2"],
    formData["expenses_field3"],
    formData["expenses_field4"],
    formData["expenses_field5"],
    formData["expenses_field6"],
    formData["expenses_field7"],
    formData["expenses_field11"],
    formData["expenses_field22"],
    formData["expenses_field33"],
    formData["expenses_field44"],
    formData["expenses_field55"],
    formData["expenses_field66"],
    formData["expenses_field77"],
  ]);

  const totalMonthlyExpense = () => {
    let totalExpence = 0;
    totalExpence =
      convertValue(formData["expenses_field1"]) +
      convertValue(formData["expenses_field2"]) +
      convertValue(formData["expenses_field3"]) +
      convertValue(formData["expenses_field4"]) +
      convertValue(formData["expenses_field5"]) +
      convertValue(formData["expenses_field6"]) +
      convertValue(formData["expenses_field7"]);
    let money = convertValue(formData["pay_s2_36"]);
    money = (money * 26) / 12;
    setNetToBank(formatCalculatedValue(money));
    let moneyAfterPayment = 0;
    moneyAfterPayment = money - totalExpence;
    moneyAfterPayment = formatCalculatedValue(moneyAfterPayment);
    setMoneyLeftAfterPayment(moneyAfterPayment);
    totalExpence = formatCalculatedValue(totalExpence);
    setTotal(totalExpence ? totalExpence : "0.00");
  };

  const retirementMonthlyExpense = () => {
    let totalExpence =
      convertValue(formData["expenses_field11"]) +
      convertValue(formData["expenses_field22"]) +
      convertValue(formData["expenses_field33"]) +
      convertValue(formData["expenses_field44"]) +
      convertValue(formData["expenses_field55"]) +
      convertValue(formData["expenses_field66"]) +
      convertValue(formData["expenses_field77"]);

    totalExpence = formatCalculatedValue(totalExpence);
    setCurrentTotal(totalExpence ? totalExpence : "0.00");
  };
  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="expense-form">
        <Form
          name="expense"
          noValidate
          onSubmit={submitFormdata}
          className="employee-form"
        >
          <div className="text-center">
            <div className="head-title">Income & Expenses</div>
          </div>

          <Row className="mb-2">
            <Col md={6}>
              <div className=" mt-3">
                <div className="panel-heading">
                  <i className="fa-solid fa-star start-icon"></i>
                  MFB TIP: Discretionary Income
                </div>
                <div className="small-rainy-text">
                  The portion of money left over, after all expenses is call
                  DISCRETIONARY INCOME. This number is important in determining
                  not only when you can retire, but when you can afford to
                  retire.
                </div>
              </div>
            </Col>
            <Col md={6}>
              <Image className="expense-graph" src={Images.expenseGraph} />
            </Col>
          </Row>
          <div className="expense-container">
            <p className="expense-title">
              <strong>DEBT and EXPENSE ANALYSIS</strong>
            </p>
          </div>
          <div className="expense-container-2">
            <table>
              <colgroup>
                <col span="1" style={{ width: "55%" }} />
                <col span="1" style={{ width: "22%" }} />
                <col span="1" style={{ width: "23%" }} />
              </colgroup>
              <tr>
                <td></td>
                <td>
                  <div className="d-flex justify-content-center font-100">
                    Current
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center font-100">
                    At Retirement
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    1. What are your monthly vehicle expenses? (payment, gas,
                    insurance)
                  </div>
                </td>
                <td>
                  {" "}
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field1"
                        placeholder="0.00"
                        value={formData.expenses_field1}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field11"
                        value={formData.expenses_field11}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    2. What are your monthly cell phone expenses?
                  </div>
                </td>
                <td>
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field2"
                        placeholder="0.00"
                        value={formData.expenses_field2}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field22"
                        placeholder="0.00"
                        value={formData.expenses_field22}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    3. What are your monthly housing expenses? (mortgage,
                    utilities, etc)
                  </div>
                </td>
                <td>
                  {" "}
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field3"
                        placeholder="0.00"
                        value={formData.expenses_field3}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field33"
                        placeholder="0.00"
                        value={formData.expenses_field33}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    4. What are your monthly credit card payments?
                  </div>
                </td>
                <td>
                  {" "}
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field4"
                        placeholder="0.00"
                        value={formData.expenses_field4}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field44"
                        placeholder="0.00"
                        value={formData.expenses_field44}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    5. What are your monthly health insurance premiums?
                  </div>
                </td>
                <td>
                  {" "}
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field5"
                        placeholder="0.00"
                        value={formData.expenses_field5}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field55"
                        placeholder="0.00"
                        value={formData.expenses_field55}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    6. What are your monthly grocery and dining expenses?
                  </div>
                </td>
                <td>
                  {" "}
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field6"
                        placeholder="0.00"
                        value={formData.expenses_field6}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field66"
                        placeholder="0.00"
                        value={formData.expenses_field66}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    7. What are your other monthly expenses? (child care, child
                    support, entertainment, etc.)
                  </div>
                </td>
                <td>
                  {" "}
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field7"
                        placeholder="0.00"
                        value={formData.expenses_field7}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field77"
                        placeholder="0.00"
                        value={formData.expenses_field77}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="total-expense">
                <td className="text-align-second ">Total Monthly Expenses:</td>
                <td>
                  {" "}
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field_monthly_total"
                        value={total}
                        disabled={true}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="expenses_field_monthly_total2"
                        value={currentTotal}
                        disabled={true}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    8. How much money do you have left after paying all of your
                    bills?
                  </div>
                </td>
                <td>
                  {" "}
                  <div
                    className={`input-wrapper mt-1 ${
                      convertValue(moneyLeftAfterPayment) < 0 && "red"
                    }`}
                  >
                    {" "}
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className={`input-box expense-input-large  ${
                          convertValue(moneyLeftAfterPayment) < 0 && "red"
                        }`}
                        type="text"
                        name="moneyLeftAfterPayment"
                        value={
                          moneyLeftAfterPayment &&
                          moneyLeftAfterPayment.replace("-", "")
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                </td>
                <td className="right-border">
                  <div className="input-wrapper mt-1">
                    $
                    <div className="text-wrapper w-100">
                      <Form.Control
                        autoComplete="off"
                        className="input-box expense-input-large"
                        type="text"
                        name="moneyLeftAfterPayment2"
                        value="TBT"
                        disabled={true}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div className="expense-normal-space">
              <Row>
                <Col md={{ span: 10, offset: 1 }}>
                  <Row>
                    <Col>
                      <p className="expense-normal ">
                        Current{" "}
                        <span className="expense-bold">NET TO BANK:</span>
                      </p>
                    </Col>
                    <Col md={4}>
                      <p className="expense-normal">
                        <span className="expense-bold">
                          {" "}
                          ${formData["pay_s2_36"]}
                        </span>{" "}
                        bi-weekly
                      </p>
                    </Col>
                    <Col md={4}>
                      <p className="expense-normal">
                        <span className="expense-bold">
                          ${netToBank ? netToBank : "0.00"}{" "}
                        </span>{" "}
                        monthly
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <p className="expense-normal ">
                        Current <span className="expense-bold ">EXPENSES:</span>
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        {" "}
                        <span className="expense-bold ">
                          ${total ? total : "0.00"}
                        </span>
                      </p>
                    </Col>
                    <Col md={4}>
                      <p className="expense-normal ">
                        <span
                          className={`expense-bold  ${
                            convertValue(moneyLeftAfterPayment) < 0 &&
                            "red-text"
                          }`}
                        >
                          {" "}
                          $
                          {moneyLeftAfterPayment
                            ? moneyLeftAfterPayment.replace("-", "")
                            : "0.00"}
                        </span>{" "}
                        monthly
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div>
              <div className=" mt-3 mb-3">
                <div className="panel-heading">
                  <i className="fa-solid fa-star start-icon"></i>
                  MFB TIP: Transition to Retirement
                </div>
                <div className="small-rainy-text">
                  Your pre-retirement window, will be significant in your
                  transition to retirement. Working on reducing your current
                  debts, expenses, and lifestyle will determine your comfort
                  level at retirement and when you are able to retire.
                </div>
              </div>
            </div>
            <div>
              <div className="expense-dark-text-wrapper">
                <div className="expense-dark-heading">
                  Income & Expenses - ITEMS OF IMPORTANCE
                </div>
                <div className="expense-dark-sub-heading">
                  * Knowing exactly how much income <b>needed</b> before
                  retiring will help your transition into retirement. Spend the
                  time now to take inventory.
                </div>
              </div>
            </div>
          </div>
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default ExpenseForm;
