import GenericService from "./GenericService";
import jwtDecode from "jwt-decode";
import axios from "axios";

const baseUrl = "https://myagentcrm.com/wp-json/bpg/";

class UserService extends GenericService {
  login = (username, password) =>
    new Promise((resolve, reject) => {
      this.post("login", { username, password })
        .then(({ token }) => {
          if (token) {
            localStorage.setItem("token", token);
          }
          resolve(token);
        })
        .catch((err) => {
          reject(err);
        });
    });

  verifyToken = async (token) => {
    return await axios
      .get(`${baseUrl}hello?timestamp=${Date.now()}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        if (data) {
          localStorage.setItem("token", token);
        }
        return data;
      })
      .catch((error) => {
        console.log("error-> ", error);
        return error;
      });
  };

  register = (data) =>
    new Promise((resolve, reject) => {
      this.post("users/register", data)
        .then((data) => {
          window.location.href = "/user";
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  update = (data) =>
    new Promise((resolve, reject) => {
      this.put("users/update", data)
        .then((data) => {
          window.location.href = "/user";
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  deleteUser = (id) =>
    new Promise((resolve, reject) => {
      this.delete("users/delete/" + id)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("benefitPlan");
    window.location.href = "/login";
  };
  isLoggedIn = () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    return token ? true : false;
  };
  getUserToken = () => {
    try {
      const jwt = localStorage.getItem("token");
      return jwtDecode(jwt);
    } catch (ex) {
      return null;
    }
  };
  getLoggedInUser = () => {
    let user = localStorage.getItem("benefitPlan");
    if (user) {
      user = JSON.parse(user);
    }
    return user;
  };
  getUser = (id, token) => {
    return axios.get(`${baseUrl}getLead/${id}?timestamp=${Date.now()}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  getSpecificUsers = (type) =>
    this.get(`users/getSpecificUsers/${type}?timestamp=${Date.now()}`);

  getAccess = () => JSON.parse(localStorage.getItem("access"));
}

let userService = new UserService();
export default userService;
