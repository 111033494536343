import React from "react";
import { Card, Image } from "react-bootstrap";
import Images from "../../CRMs/crm2.0";

const Congrets = ({
  formData,
  onChangeHandler,
  handleOnchangeCheckbox,
  validated,
  submit,
}) => {
  return (
    <>
      <Card.Body className="email-report-section">
        <div className="qs-box single-screen">
          <div style={{ padding: "5% 0" }}>
            <div className="mdb-logo text-center">
              <Image style={{ width: "40%" }} src={Images.myfedbenefitsLogo} />
            </div>
            <h1 className="text-center mt-4" style={{ color: "#50ae32" }}>
              Congratulations!
            </h1>
            <h4 className="text-center" style={{ color: "#50ae32" }}>
              You've completed the Federal Employee Benefits Planner.
            </h4>
          </div>
        </div>
      </Card.Body>
    </>
  );
};

export default Congrets;
