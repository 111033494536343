let data = {
  logo: "/images/fedhelp-logo-sm.png",
  doller75: "/images/dollars-75p-v2.jpg",
  doller100: "/images/dollars-100p-v2.jpg",
  dollarMd: "/images/d-medium.jpg",
  dollarLg: "/images/d-large.jpg",
  dollarSm: "/images/d-small.jpg",
  social: "/images/srs-social.jpg",
  riskGraph: "/images/cone-of-risk-v2.jpg",
  expenseGraph: "/images/today-vs-tomorrow-chart.jpg",
  tspImage1: "/images/tsp-image-1.jpg",
  tspImage2: "/images/tsp-image-2.jpg",
  tspImage3: "/images/tsp-image-3.jpg",
  tspImage4: "/images/tsp-image-4.jpg",
  thriftGraph: "/images/thrift-graph.jpg",
  emergencySavingImage1: "/images/emergency-saving-1.jpg",
  emergencySavingImage2: "/images/emergency-saving-2.jpg",
  warning: "/images/warning-sign.png",
  myfedbenefitsLogo: "/images/myfedbenefits-logo.png",
  myfedbenefitsheader: "/images/mybenefitsheader.jpg",
  retirementImage: "/images/retirement-planning-image.jpg",
  dollarGreen: "/images/dollar-green-gap.png",
  dollarDisable: "/images/dollar-disable-gap.png",
  gapArrow: "/images/gap-arrow.png",
};

export default data;
