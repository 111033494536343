import moment from "moment";
import { useState } from "react";
import { Button, Col, Form, Row, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import SalesService from "../../../services/SalesService";
import {
  annuityProduct,
  lifeProducts,
  applicationStatus,
  salesType,
  presentationType,
} from "../../../constants/index";
import { useEffect } from "react";
import userService from "../../../services/UserService";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { usePlannerContext } from "../../../contexts/planner.contexts";

const ProtectionForm = ({ showModal, setShowModal, leadData, submit }) => {
  const initialForm = {
    application_date: null,
    application_status: "",
    presentation_type: "",
    id: "",
    soldOn: moment(),
    type: "life",
    product: "",
    amount: "",
    side_fund_premium_amount: "",
    amountType: "",
    life_sale_allotment_start_date: null,
  };

  const [validated, setValidated] = useState(false);
  const [emails, setEmails] = useState([]);
  const [editFormValidated, setEditFormValidated] = useState(false);
  const [sale, setSale] = useState(initialForm);
  const [editSale, setEditSale] = useState(initialForm);
  const [allSales, setAllSales] = useState([]);

  const [userDetails, setUserDetails] = useState("");
  const [loader, setLoader] = useState({});
  const [checkOffline, setCheckOffline] = useState(false);

  const { plannerData } = usePlannerContext();

  const getAllSales = async () => {
    const user = userService.getLoggedInUser();

    let { data } = await SalesService.getSales(plannerData["profileid"]);
    setUserDetails(user);
    setAllSales(data);
    const emails = [];
    if (user["email"]) {
      emails.push({
        label: user["email"],
        value:
          (user.firstname ? user.firstname : "") +
          " " +
          (user.lastname ? user.lastname : ""),
      });
    }
    if (user["alt_email"]) {
      emails.push({
        label: user["alt_email"],
        value:
          (user.firstname ? user.firstname : "") +
          " " +
          (user.lastname ? user.lastname : ""),
      });
    }
    setEmails(emails);
  };

  const handleStorageChange = (event) => {
    getAllSales();
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  useEffect(() => {
    getAllSales();
    resetSaleState();
  }, []);

  const resetSaleState = () => {
    setSale({
      ...initialForm,
      type: "life",
      saleDate: moment(),
      amountType: "bi-weekly",
      amount: 0,
    });
  };

  const handleSubRow = async (row) => {
    if (row["id"] === editSale["id"]) {
      setEditSale({});
    } else {
      setEditSale({
        ...row,
        amount:
          row.amountType == "monthly"
            ? row.amount / 12
            : row.amountType == "bi-weekly"
            ? row.amount / 26
            : row.amount,
        sale_date:
          row.sale_date && moment.unix(row.sale_date).isValid()
            ? moment.unix(row.sale_date)
            : null,
        application_date:
          row.application_date && moment.unix(row.application_date).isValid()
            ? moment.unix(row.application_date)
            : null,
        life_sale_allotment_start_date:
          row.life_sale_allotment_start_date &&
          moment(row.life_sale_allotment_start_date).isValid()
            ? moment(row.life_sale_allotment_start_date)
            : null,
      });
    }
  };

  const deleteSale = async (id) => {
    let status = window.confirm("Are you sure you want to delete this sale?");
    if (status) {
      setLoader({ deleteLoader: true });

      SalesService.deleteSales(id)
        .then(({ status }) => {
          getAllSales();
          setEditSale(initialForm);
          toast.success("Sale Deleted ! ", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoader({ deleteLoader: false });
        })
        .catch((error) => {
          toast.error(`Something went wrong ! `, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoader({ deleteLoader: false });
        });
    }
  };

  const submitForm = async (event, data, isEdit = false) => {
    event.preventDefault();
    try {
      event.stopPropagation();
      setValidated(false);
      setEditFormValidated(false);
      if (event.currentTarget.name == "sale") {
        setLoader({ mainLoader: true });
      } else {
        setLoader({ subLoader: true });
      }

      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        if (event.currentTarget.name == "sale") {
          setValidated(true);
          setLoader({ mainLoader: false });
          return;
        } else {
          setEditFormValidated(true);
          setLoader({ subLoader: false });
          return;
        }
      }
      if (!navigator.onLine) {
        toast.success(`Sorry! You are offline so you can't update data `, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoader({ mainLoader: false, subLoader: false });
      }

      let salesData = {
        sale_date: moment(data.soldOn).format("YYYY-MM-DD"),
        profileId: userDetails["profileId"],
        type: data.type ? data.type : "",
        amount: data.amount ? parseFloat(data.amount) : 0,

        product: data.product ? data.product : "",
        application_date: data.application_date
          ? moment(data.application_date).format("YYYY-MM-DD")
          : "",
        application_status: data.application_status
          ? data.application_status
          : "",
        presentation_type: data.presentation_type ? data.presentation_type : "",

        side_fund_premium_amount: data.side_fund_premium_amount
          ? parseFloat(data.side_fund_premium_amount)
          : 0,

        life_sale_allotment_start_date: data.life_sale_allotment_start_date
          ? moment(data.life_sale_allotment_start_date).format("YYYY-MM-DD")
          : "",
        amountType: data.amountType ? data.amountType : "",
      };

      if (form.name == "sale") {
        resetSaleState();
      }

      if (data["id"]) {
        salesData.id = data["id"];
        salesData.saleType = data.type;

        SalesService.updateSale(salesData)
          .then(({ status }) => {
            if (status) {
              setLoader({ subLoader: false });
              getAllSales();
              handleSubRow({});
              toast.success(`Sale Updated ! `, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          })
          .catch((error) => {
            setLoader({ subLoader: false });
            toast.error(`Something went wrong ! `, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
      } else {
        const salesData = {
          sale_date: moment(data.soldOn).format("YYYY-MM-DD"),
          subid: userDetails.id ? userDetails.id : 0,
          profileId: userDetails["profileId"],
          email: userDetails["email"],
          bpg_request: "1",
          type: data.type ? data.type : "",
          amount: data.amount ? parseFloat(data.amount) : 0,
          product: data.product ? data.product : "",
          application_date: data.application_date
            ? moment(data.application_date).format("YYYY-MM-DD")
            : "",
          application_status: data.application_status
            ? data.application_status
            : "",
          presentation_type: data.presentation_type
            ? data.presentation_type
            : "",

          side_fund_premium_amount: data.side_fund_premium_amount
            ? parseFloat(data.side_fund_premium_amount)
            : 0,

          life_sale_allotment_start_date: data.life_sale_allotment_start_date
            ? moment(data.life_sale_allotment_start_date).format("YYYY-MM-DD")
            : "",
          amountType: data.amountType ? data.amountType : "",
          saleType: data["type"],
        };

        SalesService.addSales(salesData)
          .then(({ status }) => {
            getAllSales();
            setLoader({ mainLoader: false });
            toast.success(`Sale Added ! `, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          })
          .catch((error) => {
            setLoader({ mainLoader: false });
            toast.error(`Something went wrong ! `, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
      }
    } catch {
      setLoader({ mainLoader: false, subLoader: false });
    }
  };

  const handleOnChange = (event) => {
    const value = event.target.value;

    setSale({
      ...sale,
      [event.target.name]: value,
    });
  };

  const handleDateChange = (value, name) => {
    setSale({
      ...sale,
      [name]: moment(value),
    });
  };

  const handleEditableDateChange = (value, name) => {
    setEditSale({
      ...editSale,
      [name]: moment(value),
    });
  };

  const handleOnChangeEditable = (event) => {
    const value = event.target.value;

    setEditSale({
      ...editSale,
      [event.target.name]: value,
    });
  };

  const loadOptions = (data) => {
    return data.map(({ label, value }) => (
      <option value={value}>{label}</option>
    ));
  };

  const handleOnlineIcon = () => {
    setCheckOffline(false);
  };

  const handleOfflineIcon = () => {
    setCheckOffline(true);
  };

  useEffect(() => {
    navigator.onLine ? setCheckOffline(false) : setCheckOffline(true);
    // Add event listener for the 'online' event
    window.addEventListener("online", handleOnlineIcon);
    window.addEventListener("offline", handleOfflineIcon);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnlineIcon);
      window.removeEventListener("offline", handleOfflineIcon);
    };
  }, []);

  return (
    <Card.Body className="protection-section">
      <div className="text-center">
        <div className="head-title">PROTECTION & ROTH IRA</div>
      </div>
      <Form
        name="sale"
        noValidate
        validated={validated}
        onSubmit={(e) => submitForm(e, sale)}
      >
        <Row className="mb-3">
          <Col md={3}>
            <Form.Group className="mt-2" md="12" controlId="soldOn">
              <Form.Label>Date</Form.Label>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={sale["soldOn"] ? sale["soldOn"] : null}
                  disabled={checkOffline}
                  defaultValue={null}
                  emptyLabel="Date"
                  className={`w-100 protection-datepicker `}
                  onChange={(date) => handleDateChange(date, "soldOn")}
                />
              </LocalizationProvider>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mt-2" md="12" controlId="type">
              <Form.Label>Type</Form.Label>
              <Form.Select
                required
                disabled={checkOffline}
                name="type"
                onChange={handleOnChange}
                value={sale.type}
              >
                <option value={""}></option>
                {loadOptions(salesType)}
              </Form.Select>
              <Form.Control.Feedback type="invalid" className="red">
                <b>This field is required.</b>
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mt-2" md="12" controlId="product">
              <Form.Label>Product</Form.Label>
              <Form.Select
                required
                disabled={checkOffline}
                name="product"
                onChange={handleOnChange}
                value={sale.product}
              >
                <option value={""}></option>
                {sale.type == "life"
                  ? loadOptions(lifeProducts)
                  : loadOptions(annuityProduct)}
              </Form.Select>
              <Form.Control.Feedback type="invalid" className="red">
                <b>This field is required.</b>
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mt-2" md="12" controlId="premium">
              <div>
                {sale.type == "life" && (
                  <Form.Label>
                    <div className="d-flex">
                      <div>Premium </div>
                      <div className=" d-flex">
                        <div className="d-flex px-1">
                          <div className="d-flex">
                            <div>
                              <input
                                type="radio"
                                disabled={checkOffline}
                                id="bi-weekly"
                                name="amountType"
                                className={`premium-radio`}
                                checked={sale.amountType == "bi-weekly"}
                                onChange={(event) => {
                                  setSale({
                                    ...sale,
                                    [event.target.name]: "bi-weekly",
                                  });
                                }}
                              />
                            </div>
                            <label
                              htmlFor="bi-weekly"
                              className="premium-label text-nowrap c-pointer"
                            >
                              {" "}
                              Bi-weekly{" "}
                            </label>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div>
                            <input
                              disabled={checkOffline}
                              type="radio"
                              id="monthly"
                              name="amountType"
                              className={`premium-radio`}
                              label={`Monthly`}
                              checked={sale.amountType == "monthly"}
                              onChange={(event) => {
                                setSale({
                                  ...sale,
                                  [event.target.name]: "monthly",
                                });
                              }}
                            />
                          </div>
                          <label
                            htmlFor="monthly"
                            className="premium-label c-pointer"
                          >
                            {" "}
                            Monthly{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </Form.Label>
                )}

                {sale.type == "annuity" && (
                  <Form.Label>
                    <div>Total Annuity Amount </div>
                  </Form.Label>
                )}

                <div>
                  <Form.Control
                    autoComplete="off"
                    required
                    disabled={checkOffline}
                    name="amount"
                    type="number"
                    onChange={handleOnChange}
                    value={sale.amount}
                  />
                  <Form.Control.Feedback type="invalid" className="red">
                    <b>This field is required.</b>
                  </Form.Control.Feedback>
                </div>
              </div>
            </Form.Group>
          </Col>
          {sale.type == "life" && (
            <Col md={3}>
              <Form.Group
                className="mt-2"
                md="12"
                controlId="side_fund_premium_amount"
              >
                <div>
                  <Form.Label>
                    <div className="d-flex">Side Fund Premium Amount</div>
                  </Form.Label>
                  <div>
                    <Form.Control
                      autoComplete="off"
                      disabled={checkOffline}
                      name="side_fund_premium_amount"
                      type="number"
                      onChange={handleOnChange}
                      value={sale.side_fund_premium_amount}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
          )}
          <Col md={3}>
            <Form.Group className="mt-2" md="12" controlId="clientName">
              <Form.Label>Client Survey Email</Form.Label>
              <Form.Select
                name="clientName"
                disabled={checkOffline}
                onChange={handleOnChange}
                value={sale.clientName}
              >
                <option value={""}></option>
                {loadOptions(emails)}
              </Form.Select>
            </Form.Group>
          </Col>
          {sale.type == "life" && (
            <Col md={3}>
              <Form.Group
                className="mt-2"
                md="12"
                controlId="life_sale_allotment_start_date"
              >
                <Form.Label>Allotment Start date</Form.Label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disabled={checkOffline}
                    defaultValue={null}
                    emptyLabel="Allotment Start date"
                    value={
                      sale["life_sale_allotment_start_date"]
                        ? sale["life_sale_allotment_start_date"]
                        : null
                    }
                    className={` w-100 protection-datepicker `}
                    onChange={(date) =>
                      handleDateChange(date, "life_sale_allotment_start_date")
                    }
                  />
                </LocalizationProvider>
              </Form.Group>
            </Col>
          )}
          <Col md={3}>
            <Form.Group className="mt-2" md="12" controlId="application_status">
              <Form.Label>Application Date</Form.Label>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disabled={checkOffline}
                  defaultValue={null}
                  emptyLabel="Application Date"
                  value={
                    sale["application_date"] ? sale["application_date"] : null
                  }
                  className={` w-100 protection-datepicker `}
                  onChange={(date) =>
                    handleDateChange(date, "application_date")
                  }
                />
              </LocalizationProvider>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mt-2" md="12" controlId="presentation_type">
              <Form.Label>Application Status</Form.Label>
              <Form.Select
                disabled={checkOffline}
                name="application_status"
                onChange={handleOnChange}
                value={sale.application_status}
              >
                <option value={""}></option>
                {loadOptions(applicationStatus)}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mt-2" md="12" controlId="presentationType">
              <Form.Label>Presentation Type</Form.Label>
              <Form.Select
                disabled={checkOffline}
                required
                name="presentation_type"
                onChange={handleOnChange}
                value={sale.presentation_type}
              >
                <option value={""}></option>
                {loadOptions(presentationType)}
              </Form.Select>
              <Form.Control.Feedback type="invalid" className="red">
                <b>This field is required.</b>
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col
            md={3}
            className={`d-flex  ${
              sale["type"] == "life" ? " align-items-end" : "mt-3"
            }`}
          >
            <div className="px-1">
              <Button
                disabled={checkOffline}
                type="submit"
                variant="primary"
                className="submit-btn mt-3"
              >
                {loader && loader["mainLoader"] ? (
                  <i className="fa fa-spinner appulse"></i>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <hr />
      {allSales && allSales.length > 0 && (
        <div className="mt-2">
          <table className="table table-responsive lms-table">
            <colgroup>
              <col span="1" style={{ width: "6%" }} />
              <col span="1" style={{ width: "14%" }} />
              <col span="1" style={{ width: "6%" }} />
              <col span="1" style={{ width: "26%" }} />
              <col span="1" style={{ width: "18%" }} />
              <col span="1" style={{ width: "12%" }} />
              <col span="1" style={{ width: "12%" }} />
              <col span="1" style={{ width: "6%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>#</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Product</th>
                <th>Added On</th>
                <th>Logged By</th>
                <th>Added By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allSales &&
                allSales.length > 0 &&
                allSales.map((data, index) => {
                  return (
                    <>
                      <tr
                        key={index}
                        className={`${
                          editSale && editSale["id"] == data["id"] && "active"
                        } ${data["deleted"] == "1" && "deleted"}`}
                      >
                        <td>{data["id"]}</td>
                        <td className="text-right">
                          $
                          {data["amountType"] == "monthly"
                            ? data["amount"] / 12
                            : data["amountType"] == "bi-weekly"
                            ? data["amount"] / 26
                            : data["amount"]}{" "}
                          {data["amountType"] && data["type"] == "life"
                            ? data["amountType"] == "bi-weekly"
                              ? "bi-weekly"
                              : "monthly"
                            : ""}
                        </td>
                        <td className="type">{data["type"]}</td>
                        <td>{data["product"]}</td>
                        <td>
                          {data["sale_date"] && data["sale_date"] > 0
                            ? moment
                                .unix(data["sale_date"])
                                .format("MM/DD/YYYY h:mm A")
                            : ""}
                        </td>
                        <td>
                          {" "}
                          {data["loggedByFullName"]
                            ? data["loggedByFullName"]
                            : ""}
                        </td>
                        <td>
                          {" "}
                          {data["soldByFullName"] ? data["soldByFullName"] : ""}
                        </td>
                        <td className="text-center">
                          {" "}
                          {data["deleted"] == "1" ? (
                            <div className="red">Deleted</div>
                          ) : (
                            <i
                              className="fa fa-pencil pencil"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleSubRow(data)}
                            ></i>
                          )}{" "}
                        </td>
                      </tr>

                      {editSale && editSale["id"] === data["id"] && (
                        <tr>
                          <td colSpan="12">
                            <Form
                              name="editSale"
                              noValidate
                              validated={editFormValidated}
                              onSubmit={(e) => submitForm(e, editSale, true)}
                            >
                              <Row className="mb-3">
                                <Col md={3}>
                                  <Form.Group
                                    className="mt-2"
                                    md="12"
                                    controlId="sale_date"
                                  >
                                    <Form.Label>Date</Form.Label>
                                    <LocalizationProvider
                                      dateAdapter={AdapterMoment}
                                    >
                                      <DatePicker
                                        defaultValue={null}
                                        emptyLabel="Application Date"
                                        value={
                                          editSale["sale_date"]
                                            ? editSale["sale_date"]
                                            : null
                                        }
                                        className={` w-100 protection-datepicker `}
                                        onChange={(date) =>
                                          handleEditableDateChange(
                                            date,
                                            "sale_date"
                                          )
                                        }
                                      />
                                    </LocalizationProvider>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group
                                    className="mt-2"
                                    md="12"
                                    controlId="type"
                                  >
                                    <Form.Label>Type</Form.Label>
                                    <Form.Select
                                      required
                                      name="type"
                                      onChange={handleOnChangeEditable}
                                      value={editSale.type}
                                    >
                                      <option value={""}></option>
                                      {loadOptions(salesType)}
                                    </Form.Select>
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="red"
                                    >
                                      <b>This field is required.</b>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group
                                    className="mt-2"
                                    md="12"
                                    controlId="product"
                                  >
                                    <Form.Label>Product</Form.Label>
                                    <Form.Select
                                      required
                                      name="product"
                                      onChange={handleOnChangeEditable}
                                      value={editSale.product}
                                    >
                                      <option value={""}></option>
                                      {editSale.type == "life"
                                        ? loadOptions(lifeProducts)
                                        : loadOptions(annuityProduct)}
                                    </Form.Select>
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="red"
                                    >
                                      <b>This field is required.</b>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group
                                    className="mt-2"
                                    md="12"
                                    controlId="premium"
                                  >
                                    <div>
                                      {editSale.type == "life" && (
                                        <div className="d-flex">
                                          <Form.Label>
                                            <div className="px-1">Premium </div>
                                          </Form.Label>
                                          <div className="d-flex">
                                            <div>
                                              <input
                                                id="bi-weekly2"
                                                type="radio"
                                                name="amountType"
                                                className={`premium-radio`}
                                                checked={
                                                  editSale.amountType ==
                                                    "bi-weekly" ||
                                                  editSale.amountType == ""
                                                }
                                                onChange={(event) => {
                                                  setEditSale({
                                                    ...editSale,
                                                    amountType: "bi-weekly",
                                                  });
                                                }}
                                              />
                                            </div>
                                            <label
                                              htmlFor="bi-weekly2"
                                              className="premium-label"
                                            >
                                              {" "}
                                              Bi-weekly{" "}
                                            </label>
                                          </div>
                                          <div className="d-flex">
                                            <div>
                                              <input
                                                id="monthly2"
                                                type="radio"
                                                name="amountType"
                                                className={`premium-radio`}
                                                label={`Monthly`}
                                                checked={
                                                  editSale.amountType ==
                                                  "monthly"
                                                }
                                                onChange={(event) => {
                                                  setEditSale({
                                                    ...editSale,
                                                    amountType: "monthly",
                                                  });
                                                }}
                                              />
                                            </div>
                                            <label
                                              htmlFor="monthly2"
                                              className="premium-label"
                                            >
                                              {" "}
                                              Monthly{" "}
                                            </label>
                                          </div>
                                        </div>
                                      )}

                                      {editSale.type == "annuity" && (
                                        <Form.Label>
                                          <div className="px-1">
                                            Total Annuity Amount{" "}
                                          </div>
                                        </Form.Label>
                                      )}

                                      <Form.Control
                                        autoComplete="off"
                                        name="amount"
                                        type="number"
                                        onChange={handleOnChangeEditable}
                                        value={editSale.amount}
                                        required
                                      />

                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="red"
                                      >
                                        <b>This field is required.</b>
                                      </Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                </Col>
                                {editSale.type == "life" && (
                                  <Col md={3}>
                                    <Form.Group
                                      className="mt-2"
                                      md="12"
                                      controlId="side_fund_premium_amount"
                                    >
                                      <div>
                                        <Form.Label>
                                          <div className="d-flex">
                                            Side Fund Premium Amount
                                          </div>
                                        </Form.Label>
                                        <div>
                                          <Form.Control
                                            autoComplete="off"
                                            name="side_fund_premium_amount"
                                            type="number"
                                            onChange={handleOnChangeEditable}
                                            value={
                                              editSale.side_fund_premium_amount
                                            }
                                          />
                                        </div>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                )}

                                {editSale.type == "life" && (
                                  <Col md={3}>
                                    <Form.Group
                                      className="mt-2"
                                      md="12"
                                      controlId="life_sale_allotment_start_date"
                                    >
                                      <Form.Label>
                                        Allotment Start date
                                      </Form.Label>

                                      <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                      >
                                        <DatePicker
                                          defaultValue={null}
                                          emptyLabel=" Allotment Start date"
                                          value={
                                            editSale[
                                              "life_sale_allotment_start_date"
                                            ]
                                              ? editSale[
                                                  "life_sale_allotment_start_date"
                                                ]
                                              : null
                                          }
                                          className={` w-100 protection-datepicker `}
                                          onChange={(date) =>
                                            handleEditableDateChange(
                                              date,
                                              "life_sale_allotment_start_date"
                                            )
                                          }
                                        />
                                      </LocalizationProvider>
                                    </Form.Group>
                                  </Col>
                                )}
                                <Col md={3}>
                                  <Form.Group className="mt-2" md="12">
                                    <Form.Label>Application Date</Form.Label>
                                    <LocalizationProvider
                                      dateAdapter={AdapterMoment}
                                    >
                                      <DatePicker
                                        defaultValue={null}
                                        emptyLabel="Application Date"
                                        name="application_date"
                                        value={
                                          editSale["application_date"]
                                            ? editSale["application_date"]
                                            : null
                                        }
                                        className={` w-100 protection-datepicker `}
                                        onChange={(date) =>
                                          handleEditableDateChange(
                                            date,
                                            "application_date"
                                          )
                                        }
                                      />
                                    </LocalizationProvider>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group className="mt-2" md="12">
                                    <Form.Label>Application Status</Form.Label>
                                    <Form.Select
                                      name="application_status"
                                      onChange={handleOnChangeEditable}
                                      value={editSale.application_status}
                                      controlId="presentation_type"
                                    >
                                      <option value={""}></option>
                                      {loadOptions(applicationStatus)}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group className="mt-2" md="12">
                                    <Form.Label>Presentation Type</Form.Label>
                                    <Form.Select
                                      required
                                      name="presentation_type"
                                      onChange={handleOnChangeEditable}
                                      value={editSale.presentation_type}
                                    >
                                      <option value={""}></option>
                                      {loadOptions(presentationType)}
                                    </Form.Select>
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="red"
                                    >
                                      <b>This field is required.</b>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>

                                <Col
                                  md={5}
                                  className={`d-flex  ${
                                    editSale["type"] == "life"
                                      ? " align-items-end"
                                      : "mt-3"
                                  }`}
                                >
                                  <div className="px-1">
                                    <Button
                                      type="submit"
                                      variant="primary"
                                      className="submit-btn"
                                    >
                                      {loader && loader["subLoader"] ? (
                                        <i className="fa fa-spinner appulse"></i>
                                      ) : (
                                        "Submit"
                                      )}
                                    </Button>
                                  </div>
                                  <div className="px-1">
                                    <Button
                                      role="button"
                                      className="cancel-btn"
                                      variant="outline-secondary"
                                      onClick={() => handleSubRow({})}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                  <div
                                    onClick={() => deleteSale(editSale["id"])}
                                    className={`delet-text ${
                                      editSale["type"] == "life"
                                        ? "mb-2"
                                        : " align-items-center"
                                    }`}
                                  >
                                    {loader && loader["deleteLoader"] ? (
                                      <i className="fa fa-spinner appulse"></i>
                                    ) : (
                                      "Delete this sale"
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {checkOffline && (
        <Col
          md={12}
          className="text-center"
          style={{ fontSize: "18px", fontWeight: "bolder" }}
        >
          This part of the planner requires an internet connection. Please
          resume entering the data when you are back online.
        </Col>
      )}
      <Form onSubmit={submit}>
        <div className="btn-wrapper">
          <div className="text-center mt-4">
            <button className="btn-next btn question-next" type="submit">
              CONTINUE
            </button>
          </div>
        </div>
      </Form>
    </Card.Body>
  );
};

export default ProtectionForm;
