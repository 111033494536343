import React from "react";

const Welcome = () => {
  return (
    <div className="article-section">
      <div className="container">
        <h3>
          <u>SECTION I</u> - Warm up and Introduction{" "}
        </h3>
        <ol>
          <li>
            Hi (Employee's First Name), this is _____________ with
            MyFEDBenefits, how are you today?
          </li>
          <p>
            <em>(Provide an appropriate response to their statement.)</em> {" "}
          </p>
          <li>
            We're ready to get started with your benefit review I'm going to
            have you to go ahead and get a pen and a piece of paper and head to
            a place where you can follow along with our review and take notes if
            you haven't already. Okay? There will be a lot of important things
            you'll want to write down during our meeting today. I'll email all
            of the materials to you after our meeting and all of my contact
            information will be included– just in case you need to follow up
            with me about anything, okay?
          </li>
          <br></br>
          <li>
            As the Federal Benefits Specialist assigned to you, my job is to
            serve you and make sure you get the information you need to make the
            choices that are best for you and your family. We provide education
            and assistance to any and all Federal Employees, whether they work
            for the IRS, the Social Security Administration, or the Postal
            Service; We'll make sure you understand and make the most of your
            Benefits.{" "}
          </li>
          <br></br>
          <li>
            Many Federal Employees we meet with end up working for the
            Government most of their lives never fully understanding how their
            benefits work and most importantly how they benefit from them. Every
            federal employee wants to get as much out of their Benefits as they
            can. I'm going to help make sure you understand how your benefits
            work and how to position yourself to maximize those benefits from
            now through your entire career and into retirement.{" "}
          </li>
          <br></br>
          <li>
            Often, we find that federal employees are paying a lot of money
            towards benefits they're not even aware of. <br></br>
            <br></br> This happens because you're usually not provided enough
            information and education about your benefits to really know what
            you're actually getting. Right? All of the benefits, the decisions
            you need to make about them, now and throughout your career, can be
            confusing. At the end of the day, it's hard to know if the decisions
            you've already made are the best ones for you. That's why it's
            important that you've made the time to do this review, now.<br></br>
            <br></br>{" "}
            <strong>
              <em>Benefits Questionnaire</em>
            </strong>
          </li>
          <br></br>

          <li>
            The primary reason for our meeting today is to review your benefits,
            help you understand how they work, make sure you get as much out of
            them as possible, and make sure you're not spending money on
            anything you don't need. We'll also look at the pitfalls and gaps in
            your benefits and how those issues affect you throughout your career
            and at retirement. By the time we're done with your review today,
            you'll know that you've done all you can to get the most out of your
            benefits and are on track for a solid retirement. Everyone wants to
            retire some day, and everyone needs to make sure they're going to be
            able to enjoy it. We'll help you make sure everything is on track.
          </li>
          <br></br>
          <li>
             In fact, now that we're working with you, we'll do an annual review
            to help make sure you stay on track. We'll start by completing your
            Benefits Planning Guide today and we'll touch base with you every
            year to make sure that as things change you stay on track. We'll
            complete your Benefit Planning Guide, with your specific
            information, as we review everything today and I'll include it in
            the email I send you.{" "}
          </li>
          <br></br>
          <li>Do you have a paystub available?</li>
        </ol>
      </div>
    </div>
  );
};

export default Welcome;
