import axios from "axios";
import GenericService from "./GenericService";
import { Handle401 } from "./helperService";

const baseUrl = "https://myagentcrm.com/wp-json/bpg";

class BenefitService extends GenericService {
  getPlanner = (id, token) => {
    try {
      return axios
        .get(`${baseUrl}/getplanner/${id}?timestamp=${Date.now()}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((data) => data)
        .catch((error) => {
          console.log("error=> ", error);
          window.location.href = "https://myagentcrm.com/crm/bpg-manager/";
          Handle401(error);
        });
    } catch (error) {
      return error;
    }
  };
  updatePlanner = (data) => {
    try {
      if (!navigator.onLine) {
        // If the system is offline, store the request in localStorage
        const queuedRequests =
          JSON.parse(localStorage.getItem("queuedRequests")) || [];
        queuedRequests.push(data);
        localStorage.setItem("queuedRequests", JSON.stringify(queuedRequests));
        return Promise.reject({
          status: -1,
          data: null,
          message: "Application is offline",
        });
      } else {
        return this.post("/updatePlannerTest", data);
      }
    } catch (error) {
      return error;
    }
  };
  plannerReport = (data) => {
    try {
      let token = localStorage.getItem("token");
      return axios.get(`${baseUrl}/getSummary/${data}`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
        transformResponse: [
          function (data) {
            if (data) {
              let blob = new window.Blob([data], { type: "application/pdf" });
              return window.URL.createObjectURL(blob);
            }
          },
        ],
      });
    } catch (error) {
      console.log("error => ", error);
      Handle401(error);
      return error;
    }
  };
  sendEmail = (data) => {
    try {
      if (!navigator.onLine) {
        // If the system is offline, store the request in localStorage
        const queuedRequests =
          JSON.parse(localStorage.getItem("queuedRequestsEmail")) || [];
        queuedRequests.push(data);
        localStorage.setItem(
          "queuedRequestsEmail",
          JSON.stringify(queuedRequests)
        );
        return Promise.reject({
          status: -1,
          data: null,
          message: "Application is offline",
        });
      } else {
        return this.post(`/sendEmail`, data);
      }
    } catch (error) {
      return error;
    }
  };
}

let benefitService = new BenefitService();
export default benefitService;
