import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Image } from "react-bootstrap";
import Images from "../../CRMs/crm2.0";
import {
  formatCalculatedValue,
  convertValue,
} from "../../../services/helperService";
import { usePlannerContext } from "../../../contexts/planner.contexts";

const FersForm2 = ({ validated, submit }) => {
  const {
    plannerData,
    onChangeHandler,
    handleBlur,
    handleKeyPress,
    handleFocus,
    setOnChangeStatus,
  } = usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      const fersAnnuityArr = [
        plannerData["fersBasicAnnuity"],
        plannerData["fersBasicAnnuity95"],
        plannerData["fersBasicAnnuity90"],
      ];

      const pay_s2_1 = convertValue(plannerData["pay_s2_1"]);
      const groseeIncome = pay_s2_1 / 12;
      const valueToUse = plannerData["valueToUse"]
        ? plannerData["valueToUse"] - 1
        : 0;
      const fersBasicAnnuity = convertValue(fersAnnuityArr[valueToUse]);

      let loss_in_monthly_income = groseeIncome - fersBasicAnnuity;
      loss_in_monthly_income = loss_in_monthly_income
        ? Math.abs(loss_in_monthly_income)
        : "";
      let surviving_spouse_monthly_income = plannerData["fersBasicAnnuity50"];

      if (
        plannerData["family_status"] == "single" ||
        plannerData["family_status"] == "divorced"
      ) {
        surviving_spouse_monthly_income = "N/A - Single";
      }
      let srsyears = plannerData["yearsOfService"];
      if (
        plannerData.have_military_service == "yes" &&
        plannerData.s2s3_receiving_pension == "no"
      ) {
        let month = convertValue(plannerData.military_service_years) * 12;
        month = month + convertValue(plannerData.military_service_months);
        let year = month / 12;
        srsyears = srsyears - year;
      }

      const Srs =
        convertValue(plannerData["age_62_ss_benefit"]) * (srsyears / 40);

      setformData({
        ...plannerData,
        age_62_ss_benefit_final: formatCalculatedValue(Srs),
        yearsOfServiceat62: srsyears
          ? convertValue(srsyears).toFixed(2)
          : "0.00",
        monthly_gross_income: groseeIncome
          ? formatCalculatedValue(groseeIncome)
          : "0.00",
        reduced_monthly_income: fersBasicAnnuity
          ? formatCalculatedValue(fersBasicAnnuity)
          : "0.00",
        loss_in_monthly_income: loss_in_monthly_income
          ? formatCalculatedValue(loss_in_monthly_income)
          : "0.00",
        surviving_spouse_monthly_income,
      });
    }
    console.log(formData);
  }, [plannerData]);

  const handleCalculation = (event) => {
    setOnChangeStatus(true);
    let value = event.target.value;
    let multiplyValue =
      event.target.name == "age_62_ss_benefit"
        ? formData.yearsOfServiceat62
        : formData.age_62_ss_benefit;
    const total =
      value && multiplyValue
        ? (convertValue(value) * convertValue(multiplyValue)) / 40
        : "0.00";

    //
    setformData({
      ...formData,
      age_62_ss_benefit_final: formatCalculatedValue(total),
      [event.target.name]: value,
    });
  };
  const submitFormdata = (event) => {
    submit(event, formData);
  };
  return (
    <>
      <Card.Body className="fers-section2">
        <Form
          name="fersForm2"
          noValidate
          onSubmit={submitFormdata}
          className="employee-form"
        >
          <div className="text-center">
            <div className="head-title">
              FEDERAL EMPLOYEES RETIREMENT SYSTEM (FERS)
            </div>
          </div>

          <Row>
            <Col md={6}>
              <div>
                <div className="section-head">RETIREMENT</div>

                <div className="sub-title">
                  <p>
                    All federal workers first hired after 1983 are automatically
                    included in FERS. FERS employees who leave service before
                    eligibility for immediate benefits can begin to receive
                    benefits at earlier ages than CSRS.
                  </p>
                </div>
                <div
                  style={{
                    border: "1px solid #000",
                    padding: "5px",
                    fontSize: "14px",
                    marginTop: "20px",
                  }}
                >
                  FERS - .8% • FERS-RAE - 3.1% • FERS-FRAE - 4.4%
                </div>
              </div>
              <div>
                <div className="section-head mt-3">ELIGIBILITY TO RETIRE</div>

                <div className="sub-title mb-3">
                  <p>
                    FERS benefits can begin for workers at age 60 with twenty
                    years of service or at age 62 with five years of service.
                    Employees who meet the Minimum Age Requirement (MRA) with
                    thirty years of service are also eligible for FERS pension.
                  </p>
                </div>
              </div>
              <div
                style={{
                  whiteSpace: "normal",
                  padding: "5px 10px",
                  marginBottom: "15px",
                  border: "1px solid #333",
                }}
              >
                <table className="padding-none">
                  <tbody>
                    <tr>
                      <th>If you were born</th>
                      <th>Your FERS MRA is</th>
                      <th>If you were born</th>
                      <th>Your FERS MRA is</th>
                    </tr>
                    <tr>
                      <td>before 1948</td>
                      <td>55</td>
                      <td>in 1965</td>
                      <td>56 & 2 mos.</td>
                    </tr>
                    <tr>
                      <td>in 1948</td>
                      <td>55 $ 2 mos.</td>
                      <td>in 1966</td>
                      <td>56 & 4 mos.</td>
                    </tr>
                    <tr>
                      <td>in 1949</td>
                      <td>55 & 4 mos.</td>
                      <td>in 1967</td>
                      <td>56 & 6 mos.</td>
                    </tr>
                    <tr>
                      <td>in 1950</td>
                      <td>55 & 6 mos.</td>
                      <td>in 1968</td>
                      <td>56 & 8 mos.</td>
                    </tr>
                    <tr>
                      <td>3in 1951</td>
                      <td>55 & 8 mos.</td>
                      <td>in 1969</td>
                      <td>56 & 10 mos.</td>
                    </tr>
                    <tr>
                      <td>in 1952</td>
                      <td>55 & 10 mos.</td>
                      <td>1970 or after</td>
                      <td>57</td>
                    </tr>
                    <tr>
                      <td>1953-1964</td>
                      <td>56</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  whiteSpace: "normal",
                  padding: "5px 10px",
                  marginBottom: "15px",
                  border: "1px solid #333",
                }}
              >
                <div className="section-head">MINIMUM RETIREMENT AGE (MRA)</div>
                <p>Age 56+ w/ 30 years of Creditable Service</p>
                <p>Age 60 w/ 20 years of Creditable Service</p>
                <p>Age 62 w/ 5 years of Creditable Service</p>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <div className="section-head">
                  SPECIAL RETIREMENT SUPPLEMENT
                </div>
                <div className="sub-title">
                  <p>
                    Special Retirement Supplement (SRS) is a unique benefit for
                    FERS employees who retire at MRA prior to age 62. The amount
                    is a percentage of the amount your Social Security benefit
                    will be at age 62. It was created by Congress to bridge the
                    income gap you have when retiring prior to age 62.
                  </p>
                </div>
              </div>
              <div>
                {((formData["MRA"] >= 50 && formData["yearsOfService"] >= 20) ||
                  formData["yearsOfService"] >= 25 ||
                  formData["yearsOfService"] >= 30) &&
                formData["MRA"] < 62 &&
                formData["special_provisions"] == "1" ? (
                  <div>
                    <Row>
                      <Image src={Images.social} />
                    </Row>

                    <div
                      style={{
                        whiteSpace: "normal",
                        padding: "10px",
                        border: "1px solid #333",
                      }}
                    >
                      <div style={{ fontSize: "14px" }}>
                        <p>Formula:</p>
                      </div>
                      <table className="fears-formula-table">
                        <tr>
                          <td>
                            {" "}
                            <div className="input-wrapper align-items-center ">
                              <div>$</div>
                              <div className="text-wrapper">
                                <Form.Control
                                  autoComplete="off"
                                  className="input-box"
                                  type="text"
                                  name="age_62_ss_benefit"
                                  value={formData.age_62_ss_benefit}
                                  onBlur={(e) =>
                                    handleBlur(e, false, setformData)
                                  }
                                  onKeyPress={(e) => handleKeyPress(e)}
                                  onFocus={(event) =>
                                    handleFocus(event, setformData)
                                  }
                                  onChange={handleCalculation}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="mx-2"> X</div>
                          </td>
                          <td>
                            <div className="text-wrapper input-wrapper ">
                              <Form.Control
                                autoComplete="off"
                                className="input-box m-0"
                                type="text"
                                name="yearsOfServiceat62"
                                onKeyPress={(e) => handleKeyPress(e)}
                                value={formData.yearsOfServiceat62}
                                onBlur={(e) =>
                                  handleBlur(e, false, setformData)
                                }
                                onFocus={(event) =>
                                  handleFocus(event, setformData)
                                }
                                onChange={handleCalculation}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="text-nowrap  mx-1">/ 40 =</div>
                          </td>

                          <td>
                            <div className="input-wrapper align-items-center">
                              <div>$</div>
                              <div className="text-wrapper">
                                <Form.Control
                                  autoComplete="off"
                                  className="input-box"
                                  type="text"
                                  name="age_62_ss_benefit_final"
                                  value={formData.age_62_ss_benefit_final}
                                  onChange={(event) =>
                                    onChangeHandler(event, setformData)
                                  }
                                  onBlur={(e) =>
                                    handleBlur(e, false, setformData)
                                  }
                                  onKeyPress={(e) => handleKeyPress(e)}
                                  onFocus={(event) =>
                                    handleFocus(event, setformData)
                                  }
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p
                              align="center"
                              style={{
                                fontSize: "10px",
                                whiteSpace: "nowrap",
                                float: "left",
                              }}
                            >
                              Age 62 SS Benefit
                            </p>
                          </td>
                          <td></td>
                          <td>
                            <p align="center" style={{ fontSize: "10px" }}>
                              Yrs of Service
                            </p>
                          </td>
                          <td></td>
                          <td>
                            <p align="center" style={{ fontSize: "10px" }}>
                              Benefit
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <div className="mt-2" style={{ fontSize: "10px" }}>
                              <p>*Default set to $1,300</p>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div
                      style={{
                        whiteSpace: "normal",
                        padding: "5px 10px",
                        marginBottom: "5px",
                        marginTop: "5px",
                        border: "1px solid #b3282a",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            color: "#b3282a",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          NOTE : Service does not include Military or Unused
                          Sick-Leave{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {formData["MRA"] < 62 &&
                      ((formData["MRA"] >= 60 &&
                        formData["yearsOfService"] >= 20) ||
                        (formData["MRA"] < 60 &&
                          formData["yearsOfService"] >= 30)) && (
                        <div>
                          <Row>
                            <Image src={Images.social} />
                          </Row>

                          <div
                            style={{
                              whiteSpace: "normal",
                              padding: "10px",
                              border: "1px solid #333",
                            }}
                          >
                            <div style={{ fontSize: "14px" }}>
                              <p>Formula:</p>
                            </div>
                            <table className="fears-formula-table">
                              <tr>
                                <td>
                                  {" "}
                                  <div className="input-wrapper align-items-center ">
                                    <div>$</div>
                                    <div className="text-wrapper">
                                      <Form.Control
                                        autoComplete="off"
                                        className="input-box"
                                        type="text"
                                        name="age_62_ss_benefit"
                                        value={formData.age_62_ss_benefit}
                                        onBlur={(e) =>
                                          handleBlur(e, true, setformData)
                                        }
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        onFocus={(event) =>
                                          handleFocus(event, setformData)
                                        }
                                        onChange={handleCalculation}
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="mx-2"> X</div>
                                </td>
                                <td>
                                  <div className="text-wrapper input-wrapper ">
                                    <Form.Control
                                      autoComplete="off"
                                      className="input-box m-0"
                                      type="text"
                                      name="yearsOfServiceat62"
                                      onKeyPress={(e) => handleKeyPress(e)}
                                      value={formData.yearsOfServiceat62}
                                      onBlur={(e) =>
                                        handleBlur(e, false, setformData)
                                      }
                                      onFocus={(event) =>
                                        handleFocus(event, setformData)
                                      }
                                      onChange={handleCalculation}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="text-nowrap  mx-1">
                                    / 40 =
                                  </div>
                                </td>

                                <td>
                                  <div className="input-wrapper align-items-center">
                                    <div>$</div>
                                    <div className="text-wrapper">
                                      <Form.Control
                                        autoComplete="off"
                                        className="input-box"
                                        type="text"
                                        name="age_62_ss_benefit_final"
                                        value={formData.age_62_ss_benefit_final}
                                        onChange={(event) =>
                                          onChangeHandler(event, setformData)
                                        }
                                        onBlur={(e) =>
                                          handleBlur(e, false, setformData)
                                        }
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        onFocus={(event) =>
                                          handleFocus(event, setformData)
                                        }
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p
                                    align="center"
                                    style={{
                                      fontSize: "10px",
                                      whiteSpace: "nowrap",
                                      float: "left",
                                    }}
                                  >
                                    Age 62 SS Benefit
                                  </p>
                                </td>
                                <td></td>
                                <td>
                                  <p
                                    align="center"
                                    style={{ fontSize: "10px" }}
                                  >
                                    Yrs of Service
                                  </p>
                                </td>
                                <td></td>
                                <td>
                                  <p
                                    align="center"
                                    style={{ fontSize: "10px" }}
                                  >
                                    Benefit
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  <div
                                    className="mt-2"
                                    style={{ fontSize: "10px" }}
                                  >
                                    <p>*Default set to $1,300</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div
                            style={{
                              whiteSpace: "normal",
                              padding: "5px 10px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              border: "1px solid #b3282a",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  color: "#b3282a",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                NOTE : Service does not include Military or
                                Unused Sick-Leave{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}

                <div className="section-head">
                  REDUCED BENEFITS FOR EARLY RETIREMENT
                </div>
                <div className="sub-title">
                  <p>
                    Workers can retire at MRA with ten years of service but
                    their pension will be deducted 5% for each year the employee
                    is under 62. The computation formula for a FERS retirement
                    benefit depends on the age of the employee at retirement,
                    how many years of service, and the"high-3" average salary.
                    The computation formula is (1) if retiree is under age 62:
                    1% x"high-3" average x years of service, (2) if at least 62
                    and at least 20 years of service: 1.1% x "high-3" average x
                    years of service.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div className="section-head">MILITARY BUY-BACK CREDIT</div>
                <div className="sub-title">
                  <p>
                    If covered under FERS you will receive retirement credit for
                    military service only if a deposit is made. The deposit
                    equals 3% of basic military (you received for post 1956
                    military service) pay plus applicable interest.
                    <a
                      style={{ color: "#337ab7", textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://myfedbenefitshelp.com/bbc/"
                    >
                      <strong>Use the Military Buyback Calculator here.</strong>
                    </a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <table className="fers-income-table w-100">
            <colgroup>
              <col span="1" style={{ width: "25%" }} />
              <col span="1" style={{ width: "50%" }} />
              <col span="1" style={{ width: "25%" }} />
            </colgroup>
            <tr>
              <td>
                {" "}
                <div className="box-text">
                  <a
                    style={{
                      textDecoration: "none",
                      fontSize: "16px",
                      color: "#337ab7",
                    }}
                    href=""
                  >
                    Income{" "}
                    <span style={{ borderBottom: "1px solid #337ab7" }}>
                      Before
                    </span>{" "}
                    Retirement
                  </a>
                </div>
              </td>
              <td>
                <div className="box-text">
                  <a
                    style={{
                      textDecoration: "none",
                      fontSize: "16px",
                      color: "#337ab7",
                    }}
                    href=""
                  >
                    Income{" "}
                    <span style={{ borderBottom: "1px solid #337ab7" }}>
                      After
                    </span>{" "}
                    Retirement
                  </a>
                </div>
              </td>
              <td className="d-flex align-items-baseline">
                <div className="box-text">
                  <a
                    style={{
                      textDecoration: "none",
                      fontSize: "16px",
                      color: "#337ab7",
                    }}
                    href=""
                    className="px-1"
                  >
                    Surviving Spouse Income
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  align="center"
                  style={{ padding: "20px 0", textAlign: "center" }}
                  className="mt-2"
                >
                  <Image
                    style={{ width: "150px", display: "inline" }}
                    src={Images.dollarMd}
                  />
                </div>
              </td>
              <td>
                <div
                  align="center"
                  style={{ padding: "20px 0", textAlign: "center" }}
                  className="mt-2"
                >
                  <Image
                    style={{ width: "150px", display: "inline" }}
                    src={Images.dollarMd}
                  />
                </div>
              </td>
              <td>
                <div
                  align="center"
                  style={{ padding: "25px 0", textAlign: "center" }}
                  className="mt-2"
                >
                  <Image
                    style={{ width: "150px", display: "inline" }}
                    src={Images.dollarSm}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="pt-2">
                {" "}
                <div>
                  <b className="bold-text">Monthly Gross Income</b>
                </div>
              </td>
              <td>
                <Row>
                  <Col>
                    <div
                      style={{
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                    >
                      <b>Reduced Monthly Income</b>

                      <b style={{ color: "#b3282a", fontStyle: "italic" }}>
                        {" "}
                        (Taxable)
                      </b>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                    >
                      <b>Loss in Monthly Income</b>
                    </div>
                  </Col>
                </Row>
              </td>
              <td className="d-flex align-items-baseline">
                <div className="px-1">
                  <b className="bold-text">Surviving Spouse Monthly Income</b>
                </div>
              </td>
            </tr>
            <tr>
              <td className="p-0">
                <div
                  className="input-wrapper mt-1"
                  style={{ marginRight: "5px" }}
                >
                  <b>$</b>
                  <div className="text-wrapper" style={{ width: "100%" }}>
                    <Form.Control
                      autoComplete="off"
                      className="input-box fers-input-large "
                      type="text"
                      name="monthly_gross_income"
                      value={formData.monthly_gross_income}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onBlur={(e) => handleBlur(e, false, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                    />
                  </div>
                </div>
              </td>
              <td className="p-0">
                <Row>
                  <Col>
                    <div className="input-wrapper mt-1">
                      <b>$</b>
                      <div className="text-wrapper" style={{ width: "100%" }}>
                        <Form.Control
                          autoComplete="off"
                          className="input-box fers-input-large"
                          type="text"
                          name="reduced_monthly_income"
                          value={formData.reduced_monthly_income}
                          onChange={(event) =>
                            onChangeHandler(event, setformData)
                          }
                          onBlur={(e) => handleBlur(e, false, setformData)}
                          onKeyPress={(e) => handleKeyPress(e)}
                          onFocus={(event) => handleFocus(event, setformData)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="input-wrapper mt-1 mx-1">
                      <b>$</b>
                      <div className="text-wrapper" style={{ width: "100%" }}>
                        <Form.Control
                          autoComplete="off"
                          className={`input-box fers-input-large ${
                            formData["loss_in_monthly_income"] != "0.00"
                              ? "text-danger"
                              : ""
                          }`}
                          type="text"
                          name="loss_in_monthly_income"
                          value={
                            formData.loss_in_monthly_income != "0.00"
                              ? "-" + formData.loss_in_monthly_income
                              : "0.00"
                          }
                          readonly
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </td>
              <td className="p-0">
                <div className="input-wrapper mt-1 mx-1">
                  <b>$</b>
                  <div className="text-wrapper" style={{ width: "100%" }}>
                    <Form.Control
                      autoComplete="off"
                      className="input-box fers-input-large"
                      type="text"
                      name="surviving_spouse_monthly_income"
                      value={formData.surviving_spouse_monthly_income}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onBlur={(e) => handleBlur(e, false, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <Row>
                  <Col>
                    <div className="px-1">
                      <div>
                        <b>
                          + SOCIAL SECURITY{" "}
                          <span
                            style={{ color: "#b3282a", fontStyle: "italic" }}
                          >
                            (Taxable)
                          </span>
                        </b>
                      </div>
                      <div>
                        <b>
                          + TSP{" "}
                          <span
                            style={{ color: "#b3282a", fontStyle: "italic" }}
                          >
                            (Taxable)
                          </span>
                        </b>
                      </div>
                      <div>
                        <b>
                          + OTHER / ROTH IRA{" "}
                          <span
                            style={{ color: "#b3282a", fontStyle: "italic" }}
                          >
                            (Non-Taxable)
                          </span>
                        </b>
                      </div>
                    </div>
                  </Col>
                </Row>
              </td>
              <td></td>
            </tr>
          </table>
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default FersForm2;
