import { Navigate } from "react-router-dom";
import NotFound from "../components/notFound";
import userService from "../services/UserService";

const PrivateRoute = ({ child }) => {
  const isAuthenticated = userService.isLoggedIn();

  if (isAuthenticated) {
    return <>{child}</>;
  }

  return <NotFound />;
};

export default PrivateRoute;
