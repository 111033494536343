import React from "react";

const Summary = () => {
  return (
    <div className="article-section">
      <div className="container">
        <p>
          <strong>
            <em>CAPITAL LEGACY CLOSE</em>
          </strong>
          <strong></strong>
        </p>
        <ol start="62" type="1">
          <li>
            You have the same two issues every federal employee has – not
            because you did anything wrong, but because of the way your benefits
            are designed. You won't have enough life insurance when you die, and
            every employee wants to have as much income as possible in
            retirement.
          </li>
          <br></br>
          <li>
            Let's make sure you understand the two "gaps" that we have
            identified today. First, remember back in the FEGLI Section we saw
            that you will have a significant gap of life insurance coverage
            after age 65? The "free" Basic Life Insurance provided by the
            government will drop by 75%., That only leaves you $
            <u>           </u>
            of death benefit for the rest of your life. Now that may seem like
            it is enough to cover your final expenses today but think about
            living to age 85 or even 90. Twenty-five to thirty years{" "}
            <em>
              <u>past</u>
            </em>
            retirement: don't you think things will cost a LOT more in the
            future? Inflation is a fact in all of our lives, and the costs
            associated with final expenses are only going to go up as we get
            older.{" "}
          </li>
          <br></br>
          <p>
            A majority of the Federal Employees that we meet with care about
            these numbers and want to make sure that their family members won't
            have to worry about how to pay for things! That's what we care
            about! Making sure our loved ones aren't left having to figure out
            how to pay for our final expenses?!
          </p>
          <li>
            Next, you will have a "gap" or need for enough income in retirement
            to be comfortable and to have access to cash or a rainy-day fund
            when an emergency happens throughout your career or during
            retirement. Remember, we've already seen that your income will drop
            significantly when you retire. It will be important for you to have
            as much available cash as possible and to pay as little tax as
            possible.
          </li>
          <br></br>
          <li>
            We will solve these two gaps today: as long as you know how to get
            into your Liteblue OR know your 4-digit PIN (personal identification
            number) and as long as you qualify for the benefit. We will use a
            program called "Capital Legacy that's built specifically for federal
            employees and will help you solve both issues you have.
          </li>
          <br></br>
          <li>
            Capital Legacy has two parts: Life Insurance &amp; ROTH IRA savings.
            The life insurance will stay with you after retirement, and the Roth
            IRA savings are tax free. We've already seen that most of your
            retirement income will be taxed, so the tax-free savings will be
            very important to you at retirement!{" "}
          </li>
          <br></br>
          <li>
            This program is based upon an allotment of $106 per pay period….
            many people start this for $125 or even $150 per pay, and what I'm
            recommending for you is $106 per  pay period. But, at this point,
            the most important part of this program is to get something started
            today. It comes out of your check as an Allotment and will show up
            as "ALOT $106" on your pay stub.{" "}
          </li>
          <br></br>
          <li>
            In the first year, $100 goes towards the life insurance locking in
            your coverage and locking in your rate. $5 goes towards the Roth
            IRA, and $1 goes to the bank that handles the transfer of your money
            from your paycheck to your Capital Legacy benefits Beginning with
            the 2nd year, this splits to $50 life insurance, and $55 Roth IRA
            and $1 continues to go to the bank.
          </li>
          <br></br>
          <li>
            Capital Legacy will provide you with a level death benefit for the
            next 10 years. At $100 per pay that will give you a benefit amount
            of{" "}
            <span className="black-font">
              <em>
                <u>$180,000.00</u>{" "}
                <strong>
                  (insert their face amount from the Capital Legacy Rate Sheet
                  aka Money Purchase Sheet)
                </strong>
              </em>
            </span>
            <em>. </em>At the end of the 10th year the money that you paid into
            Capital Legacy during the first year will go into your Roth IRA. At
            that point, the program continues with decreasing term insurance
            coverage to age 100 without any price increases., And you continue
            to build your "nest egg" of tax-free savings in the Roth IRA.
          </li>
          <br></br>
          <li>
            One of the best parts of this program is that your Roth IRA earns a
            great interest rate. It's currently growing at 4%. It has gone as
            high as 8%. Most important, it has a guaranteed rate of 1%, but it's
            never been less than 4%. So regardless of what's going on with the
            economy (and we all know the economy is up and down) you will always
            make at least 1% interest on the Roth IRA. By retirement your
            account will have grown to at least{" "}
            <em className="black-font">
              <u>$61,682.00</u> (
              <strong>
                Insert their A/V from the Capital Legacy Rate Sheet aka Money
                Purchase Sheet)
              </strong>
            </em>
          </li>
          <br></br>
          <li>
            In addition to this great return on your investment, as well as
            having the right long term life insurance needs solved, there are
            also some other benefits included with Capital Legacy at no charge
            <u>.</u> In many cases, a serious illness such as cancer has a
            devastating effect on a household income. Not only could you have to
            miss work and lose wages, but often your spouse will have to miss
            some work to take care of you. As a result, both of you could see a
            decline in income during these times. This benefit gives you
            additional protection and can prevent serious financial hardship.
          </li>
          <br></br>
          <li>
            So, as you can see, the Capital Legacy benefit will not only solve
            the two main "gaps" in benefits coverage that you currently have,
            but it will also do much more for you and your family.
          </li>
          <br></br>
          <li>Do you have any questions?</li>
          <br></br>
          <li>
            The process to get this going for you is simple: We'll go ahead and
            complete the application and set up your allotment. There are a few
            questions we need to answer to make sure you will qualify for the
            benefit.
          </li>
          <br></br>
          <li>
            Based on what we saw from your paycheck, we know that we need to
            slide over the additional amount you are contributing to your TSP
            plus add a few dollars to that amount to get you to the $106 per
            pay. Go ahead and log into Lite Blue and I'll walk you through how
            to set everything up…….
          </li>
        </ol>
        <p className="fw-bold">*Agent Tips</p>
        <ol>
          <ol start="a" type="a">
            <li>
              At this point, you should be ready to go. Do NOT ask for the sale,
              just assume the close. "These are your gaps, this closes those
              gaps, I'm going to help you do this…. let's get started."{" "}
            </li>
            <br></br>
            <li>
              Set up the allotment and go through the health question to make
              sure they qualify for coverage.
            </li>
            <br></br>
            <li>
              If they do not know their LiteBlue Password, use the phone system
              and their 4-digit PIN to set up the allotment.{" "}
            </li>
            <br></br>
            <li>
              If they do not know their 4-digit PIN, get on LiteBlue, and click
              "forgot password" and answer the two questions to reset their
              Password.
            </li>
            <br></br>
            <li>
              If they cannot answer the questions, and if they do not know their
              4-digit PIN, then call PostalEase on speakerphone and process a
              request to "reset their 4-digit PIN." The new PIN will arrive in
              5-7 business days.{" "}
            </li>
          </ol>
        </ol>
        <p className="fw-bold">
          DO NOT LET THIS STOP YOU FROM HELPING THEM START THEIR CAPITAL LEGACY!
        </p>
        <p>
          If you are resetting the 4-digit PIN, go ahead with the online E-app
          process and set this up as a BANK DRAFT with the first deduction date
          25 days in the future. Help the client<br></br>
          request a new PIN through calling PostalEase, and let the client know
          they must contact you when their PIN arrives so you can help them set
          up the allotment and cancel the bank draft BEFORE the draft date.{" "}
          <em>
            <u>If you are following this process, it is imperative that</u>{" "}
            <u>
              you get a PAPER COPY FIRSTNET FORM signed while you are in the
              home.{" "}
            </u>
          </em>
          If you fail to do this, you will have to make another trip out to see
          this client once they receive their 4-digit PIN.
        </p>
        <p>
          Once their PIN arrives, you can walk them through setting up their new
          LiteBlue password and allotment over the phone. At this point, have
          the client give you the confirmation number and first deduction date
          for the allotment. Write that information on the signed paper FirstNet
          form, and upload that document to your LIFE folder in ShareFile with a
          note attached to change this policy from bank draft to allotment.
        </p>
        <p>
          <strong>
            <em>Press </em>
          </strong>
          <strong>
            <em>
              <span className="green">CONTINUE</span> and move into Protection
              and Roth IRA portion of the BPG
            </em>
          </strong>
          <strong></strong>
        </p>
      </div>
    </div>
  );
};

export default Summary;
