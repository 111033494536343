import React from "react";

const Fegli = () => {
  return (
    <div className="article-section" data-screen="tsp">
      <div className="container">
        <p className="fw-bold">TSP</p>
        <ol start="45" type="1">
          <li>
            <strong>
              <em>This is your Thrift Savings Plan, </em>
            </strong>
            which is very similar to a private corporation's 401k Plan or a
            Traditional IRA. All these things have one thing in common: the fact
            they're <em>Tax Deferred Savings Plans.</em>{" "}
          </li>
          <br></br>
          <li>
            <strong>Tax Deferred </strong>means that you contribute a certain
            amount of money straight off of your paycheck{" "}
            <strong>before it is taxed</strong>, in your case{" "}
            <strong>
              <em>$116</em>.{" "}
            </strong>
            The Government then gives you a certain amount of "free money"
            called matching. That money then grows at whatever rate of return
            you're getting in your chosen funds. When you retire and start
            pulling your money out, the government taxes you on whatever you're
            taking out. <strong>Does this make sense?</strong>
          </li>
          <br></br>
          <li>
            <strong>
              Taking advantage of government matching and using the TSP is step
              in the right direction. But, the TSP is only matched on the first
              5% you contribute.
            </strong>{" "}
            Obviously making this a great place for your{" "}
            <strong>first 5%. </strong>This is the most important part for you
            to understand about your Thrift Savings Plan.{" "}
          </li>
          <br></br>
          <li>
            You're currently contributing <strong>6% </strong>of your salary or
            $116 – (<em>Stub, </em>TSP Line) – Now here's the math for this: We
            take your{" "}
            <strong>
              <em>$116 </em>
            </strong>
            and divide it by 6, which tells us each percent you contribute is
            worth{" "}
            <strong>
              <em>$19.33</em>
            </strong>
            , okay? Then we multiply this by <strong>5 </strong>(for the{" "}
            <strong>5% </strong>matching you're getting) to tell us the Maximum
            Amount you should be putting into your TSP to capitalize on the
            Government's free money. So that's $96.66 that's automatically
            doubled with their matching.
          </li>
          <li>
            That's awesome – that's a great place to put your{" "}
            <strong>First 5%. </strong>The problem is you have this other 1%
            that's going in that equals <em>$19.33 </em>with NO "matching" on
            it. And don't forget, you have to Pay Taxes on this <em>$19.33 </em>
            when you take it out later. That's why it's so important to slide
            this money over into a ROTH IRA.
          </li>
          <br></br>
          <li>
            In your TSP, do you have any idea what Fund(s) your money is in
            right now? Do you remember doing anything or did you just leave it
            in the Default fund? Typically, the majority of people leave their
            money in the Default fund which is the "G-Fund", this is the Fund
            with the least Risk to your money. The problem is, for the most part
            it's not even keeping up with Inflation and at best you're going to
            average about 1.5%. Did you realize that?
          </li>
          <br></br>
          <li>
            I'd recommend you take a look at the L-Funds available in the TSP.
            It encompasses all the other Funds and is managed by BlackRock which
            is one of the biggest money management companies in the world. Each
            of the L Funds have different risk factors associated with them, and
            BlackRock takes into consideration the amount of time you have left
            until   retirement. The L-Funds basically calculates risk based on
            the year you want to retire and   shifts your money into the least
            risky funds the closer you get to your retirement date.
          </li>
          <li>
            As you evaluate your tolerance for risk throughout your career, and
            as you are heading into retirement, you should be considering the
            impact of risk on your retirement assets. You should look at the
            various TSP Fund options as the illustrated cone gets wider and
            wider from the G Fund to the I Fund. This is called &quot;The Cone
            of Risk&quot;. It is important that you align your TSP balance
            allocations and new contributions with the level of risk you are the
            most comfortable accepting, according to your time horizon. The
            basic concept is that most individuals have a higher risk tolerance
            early in their careers. But, as you approach retirement, most
            individuals risk tolerance reduces considerably due to the lack of
            time to recover from major swings in the stock market. The L-Funds
            help you reduce "The Cone of Risk" as you get closer to Retirement. {" "}
          </li>
          <li>
            I remember on your Paycheck Stub that you had a loan on your TSP. So
            obviously at some point you needed to access that money, which is
            quite normal, and it's very likely sometime between now and
            retirement you could need to access it again, right?
          </li>
          <p>
            And that's where we have a bit of a gap or "pitfall" with the way
            the Thrift Savings Plan is set up. Basically, you're putting your
            money into this program every pay period and you really can't even
            touch it until you're retired. That can put people in a bind when
            <br></br>
            they're forced to pull out their money and end up having to pay it
            all back. Who needs another "loan" payment, right? So that's why
            this isn't really the most "Flexible" place for people to stick
            their money. But again, it definitely allows you to capitalize on
            the Government's free 5% matching.
          </p>
          <li>
            Anything over and above the 5% contribution, we will slide to your
            ROTH IRA. This gives you opportunity for tax free growth and the
            ability to receive ALL of your interest received. Not just what's
            left after paying taxes. Remember you will also pay taxes on all
            Traditional TSP money once you start receiving it.{" "}
          </li>
          <li>Any questions?</li>
        </ol>
        <p>
          <strong>
            <em>
              Press <span className="green">CONTINUE</span> and move into the
              Social Security portion of the BPG
            </em>
          </strong>
        </p>
        <div className="article-section" data-screen="tsp-s2">
          <ol start="52" type="1">
            <li>
              As you evaluate your tolerance for risk throughout your career,
              and as you are heading into retirement, you should be considering
              the impact of risk on your retirement assets. You should look at
              the various TSP Fund options as the illustrated cone gets wider
              and wider from the G Fund to the I Fund. This is called &quot;The
              Cone of Risk&quot;. It is important that you align your TSP
              balance allocations and new contributions with the level of risk
              you are the most comfortable accepting, according to your time
              horizon. The basic concept is that most individuals have a higher
              risk tolerance early in their careers. But, as you approach
              retirement, most individuals risk tolerance reduces considerably
              due to the lack of time to recover from major swings in the stock
              market. The L-Funds help you reduce "The Cone of Risk" as you get
              closer to Retirement. {" "}
            </li>
          </ol>
          <p>
            When was the last time you looked at your TSP account? {" "}
            <strong>
              <em>
                (Select the appropriate response in the BPG, then press{" "}
                <span className="green">CONTINUE</span>)
              </em>
            </strong>{" "}
          </p>
        </div>

        <div className="article-section" data-screen="tsp-s3">
          <ol start="53" type="1">
            <li>
              I remember on your Paycheck Stub that you had a loan on your TSP.
              So obviously at some point you needed to access that money, which
              is quite normal, and it's very likely sometime between now and
              retirement you could need to access it again, right?
            </li>
            <p>
              And that's where we have a bit of a gap or "pitfall" with the way
              the Thrift Savings Plan is set up. Basically, you're putting your
              money into this program every pay period and you really can't even
              touch it until you're retired. That can put people in a bind when
              <br></br>
              they're forced to pull out their money and end up having to pay it
              all back. Who needs another "loan" payment, right? So that's why
              this isn't really the most "Flexible" place for people to stick
              their money. But again, it definitely allows you to capitalize on
              the Government's free 5% matching.
            </p>
            <li>
              Anything over and above the 5% contribution, we will slide to your
              ROTH IRA. This gives you opportunity for tax free growth and the
              ability to receive ALL of your interest received. Not just what's
              left after paying taxes. Remember you will also pay taxes on all
              Traditional TSP money once you start receiving it.{" "}
            </li>
            <li>Any questions?</li>
          </ol>
          <p>
            <strong>
              <em>
                Press <span className="green">CONTINUE</span> and move into the
                Social Security portion of the BPG
              </em>
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Fegli;
