import React, { useEffect } from "react";
import { useState } from "react";
import { Card, Form, Row, Col, Image } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import { getMonthlyPayment } from "../../../services/helperService";

import Images from "../../CRMs/crm2.0";
import userService from "../../../services/UserService";

const TspOptionForm = ({ submit }) => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmitted, setSubmit] = useState(false);
  const { plannerData, setOnChangeStatus } = usePlannerContext();
  const [formData, setformData] = useState({});
  const [monthlyPayment, setMonthlyPayment] = useState();

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      const data = getMonthlyPayment(
        plannerData.tsp_opt_amount ? plannerData.tsp_opt_amount : 100000,
        plannerData["MRA"]
      );

      if (plannerData["family_status"] == "married") {
        setOpen(true);
      }
      if (
        plannerData["family_status"] == "single" ||
        plannerData["family_status"] == "divorced"
      ) {
        setShow(true);
      }
      setMonthlyPayment(data);
      setformData(plannerData);
    }
  }, [plannerData]);
  const SubmitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSubmit(true);

    if (userService.getAccess().bpg_skip_req == 1) {
      submit(e, formData);
    } else if (formData["lifeAnnuity"]) {
      setSubmit(false);
      submit(e, formData);
    }
  };

  const handleOpenSingleLife = (event) => {
    setShow(!show);
  };

  const handleOpenJointLife = (event) => {
    setOpen(!open);
  };

  useEffect(() => {
    const value = formData.tsp_opt_amount;
    if (formData.tsp_opt_amount) {
      const payment = getMonthlyPayment(value, formData["MRA"]);
      setMonthlyPayment(payment);
    }
  }, [formData.tsp_opt_amount]);

  const handleTSPAmount = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const payment = getMonthlyPayment(value, formData["MRA"]);
    setMonthlyPayment(payment);
    handleStateByField(name, value);
  };
  const handleStateByField = (name, value) => {
    setOnChangeStatus(true);

    setformData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <>
      <Card.Body className="Tsp-withdraw-opt">
        <Form name="esForm" onSubmit={SubmitForm} className="Tsp-withdraw-form">
          <div style={{ textAlign: "center" }}>
            <div className="head-title">TSP Withdrawal Options</div>
          </div>

          <div className="Tsp-box">
            <Row className="box">
              <Col md={10}>
                <div className="Tsp-text-wrapper">
                  <p className="section-desc Tsp-text">
                    <strong>
                      What amount from your TSP account do you want to use for
                      monthly income?
                    </strong>
                  </p>
                </div>
              </Col>
              <Col md={2}>
                <Form.Select
                  className="Tsp-select"
                  name="tsp_opt_amount"
                  value={formData.tsp_opt_amount}
                  onChange={handleTSPAmount}
                >
                  <option value="100000">$100,000</option>
                  <option value="150000">$150,000</option>
                  <option value="200000">$200,000</option>
                  <option value="250000">$250,000</option>
                  <option value="300000">$300,000</option>
                  <option value="350000">$350,000</option>
                  <option value="400000">$400,000</option>
                  <option value="450000">$450,000</option>
                </Form.Select>
              </Col>
            </Row>
          </div>
          <p className="error-text">
            *Assumes an immediate annuity at Age 62.{" "}
          </p>
          <p className="error-text">
            **Assumes 3 to 6 months of Emergency Savings.{" "}
          </p>

          <div className="Tsp-red-box mt-2 ">
            <div
              className="display-icon"
              onClick={() =>
                handleStateByField("lifeAnnuity", "tsp_opt_full_or_partial_wid")
              }
            >
              {formData["lifeAnnuity"] == "tsp_opt_full_or_partial_wid" ? (
                <i className="fa-solid fa-square-check  px-2 fa-lg"></i>
              ) : (
                <i
                  className={`fa-solid fa-square px-2 fa-lg ${
                    formData["lifeAnnuity"] && "icon-gray"
                  }`}
                ></i>
              )}
              Full or Partial Withdrawal:
            </div>
          </div>
          <div className="Tsp-sub-red-box Tsp-Text">
            Full or Partial Withdrawal allows you to withdraw your entire
            account at one time or over a series of monthly payments spread over
            time.
          </div>

          <div className="Tsp-red-box mt-3">
            <div
              className="display-icon"
              onClick={() =>
                handleStateByField("lifeAnnuity", "tsp_opt_tsp_monthly_pay")
              }
            >
              {formData["lifeAnnuity"] == "tsp_opt_tsp_monthly_pay" ? (
                <i className="fa-solid fa-square-check px-2 fa-lg"></i>
              ) : (
                <i
                  className={`fa-solid fa-square px-2 fa-lg ${
                    formData["lifeAnnuity"] && "icon-gray"
                  }`}
                ></i>
              )}
              TSP Monthly Payments:
            </div>
          </div>
          <div className="Tsp-sub-red-box Tsp-Text">
            With the MONTHLY PAYMENT option, you take the risk that you out your
            money - your payments will end when the money in your account runs
            out. However, you have the flexibility to change your payment amount
            when you desire, and because the balance that is not yet paid in
            your TSP account, you can continue to invest in any TSP funds you
            choose
          </div>
          <div>
            <div onClick={handleOpenSingleLife} className="Tsp-red-box mt-3">
              {" "}
              <div className="display-icon">
                {show ? (
                  <i className="fa-solid fa-minus mx-2"></i>
                ) : (
                  <i className="fa-sharp fa-solid fa-plus mx-2"></i>
                )}
                Single Life Annuity
              </div>
            </div>

            {show && (
              <>
                <div className="Tsp-single-Ann-box">
                  <div className="Tsp-red-box text-flex mt-3">
                    <Row className="display-text">
                      <Col md={6}>
                        <div
                          className="display-icon"
                          onClick={() =>
                            handleStateByField(
                              "lifeAnnuity",
                              "tsp_opt_single_life_annuity_tsp"
                            )
                          }
                        >
                          {formData["lifeAnnuity"] ==
                          "tsp_opt_single_life_annuity_tsp" ? (
                            <i className="fa-solid fa-square-check px-2 fa-lg"></i>
                          ) : (
                            <i
                              className={`fa-solid fa-square px-2 fa-lg ${
                                formData["lifeAnnuity"] && "icon-gray"
                              }`}
                            ></i>
                          )}
                          MetLife
                        </div>
                      </Col>{" "}
                      <Col md={6}>
                        Monthly Payment:{" "}
                        <span className="tsp-withdraw-value">
                          {" "}
                          $
                          {monthlyPayment && monthlyPayment["SLA TSP"]
                            ? monthlyPayment["SLA TSP"]
                            : "N/A"}
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className="Tsp-sub-red-box">
                    <Row>
                      <Col md={2}>
                        <Image className="Tsp-img1" src={Images.tspImage1} />
                      </Col>
                      <Col md={6}>
                        {" "}
                        <p className="Tsp-Text">
                          A Single Life Annuity is an annuity that provides a
                          monthly annuity payment only to you for as long as you
                          live. It ceases to pay benefits after your death.
                        </p>
                      </Col>
                      <Col md={4}>
                        <div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              Cash Refund
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-regular fa-square greyBox  px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3">
                              Emergency Saving (10%)
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-regular fa-square greyBox px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3">
                              2 Year Maximize"
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-regular fa-square greyBox px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3">
                              Income Bonus
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="Tsp-single-Ann-box">
                  <div className="Tsp-red-box text-flex mt-3">
                    <Row className="display-text">
                      <Col md={6}>
                        <div
                          className="display-icon"
                          onClick={() =>
                            handleStateByField(
                              "lifeAnnuity",
                              "tsp_opt_single_life_annuity_private_sector"
                            )
                          }
                        >
                          {formData["lifeAnnuity"] ==
                          "tsp_opt_single_life_annuity_private_sector" ? (
                            <i className="fa-solid fa-square-check px-2 fa-lg"></i>
                          ) : (
                            <i
                              className={`fa-solid fa-square px-2 fa-lg ${
                                formData["lifeAnnuity"] && "icon-gray"
                              }`}
                            ></i>
                          )}
                          Private Sector
                        </div>
                      </Col>{" "}
                      <Col md={6}>
                        Monthly Payment:
                        <span className="tsp-withdraw-value">
                          {" "}
                          $
                          {monthlyPayment && monthlyPayment["SLA PS"]
                            ? monthlyPayment["SLA PS"]
                            : "N/A"}
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className="Tsp-sub-red-box">
                    <Row>
                      <Col md={2}>
                        <Image className="Tsp-img2" src={Images.tspImage2} />
                      </Col>
                      <Col md={6}>
                        {" "}
                        <p className="Tsp-Text">
                          The{" "}
                          <span className="Tsp-bold">
                            Private Sector Annuity
                          </span>{" "}
                          functions the same as the Joint Life Annuity through
                          METLIFE, however has a few more features that most
                          federal employees need.
                        </p>
                        <p className="Tsp-Text">
                          <span className="Tsp-bold">
                            Emergency Savings Access{" "}
                          </span>
                          offers a 10% penalty free withdrawal option to people
                          that need a little extra cash. This feature is
                          available each year, if needed.
                        </p>
                      </Col>
                      <Col md={4}>
                        <div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              100% Survivorship{" "}
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              Cash Refund
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              Emergency Saving Access
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              2 Year Maximize"
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              Income Bonus
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="Tsp-private-sector-text">
                          The{" "}
                          <span className="Tsp-bold">
                            Enhanced Death Benefit
                          </span>{" "}
                          feature offers an additional Death Benefit feature
                          greater than the initial premium amount. Assuming no
                          withdrawals were taken.
                        </div>
                        <div className="Tsp-private-sector-text mt-2">
                          <span className="Tsp-bold">Income Bonus</span> is a
                          fixed rate of return credited to your income benefit
                          annually. The amount of income bonus can vary between
                          3% and 30% depending on the strategy selected
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}

            <div className="Tsp-red-box mt-3" onClick={handleOpenJointLife}>
              {" "}
              <div className="display-icon">
                {open ? (
                  <i className="fa-solid fa-minus mx-2"></i>
                ) : (
                  <i className="fa-sharp fa-solid fa-plus mx-2"></i>
                )}
                Joint Life Annuity
              </div>
            </div>
            {open && (
              <>
                <div className="Tsp-single-Ann-box">
                  <div className="Tsp-red-box text-flex mt-3">
                    <Row className="display-text">
                      <Col md={6}>
                        {" "}
                        <div
                          className="display-icon"
                          onClick={() =>
                            handleStateByField(
                              "lifeAnnuity",
                              "tsp_opt_joint_life_annuity_tsp"
                            )
                          }
                        >
                          {formData["lifeAnnuity"] ==
                          "tsp_opt_joint_life_annuity_tsp" ? (
                            <i className="fa-solid fa-square-check px-2 fa-lg"></i>
                          ) : (
                            <i
                              className={`fa-solid fa-square px-2 fa-lg ${
                                formData["lifeAnnuity"] && "icon-gray"
                              }`}
                            ></i>
                          )}
                          MetLife
                        </div>
                      </Col>{" "}
                      <Col md={6}>
                        Monthly Payment:{" "}
                        <span className="tsp-withdraw-value">
                          {" "}
                          $
                          {monthlyPayment && monthlyPayment["JLA TSP"]
                            ? monthlyPayment["JLA TSP"]
                            : "N/A"}
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className="Tsp-sub-red-box">
                    <Row>
                      <Col md={2}>
                        <Image className="Tsp-img1" src={Images.tspImage3} />
                      </Col>
                      <Col md={6}>
                        {" "}
                        <p className="Tsp-Text">
                          A Joint Life With Spouse Annuity is an annuity that
                          provides a monthly payment to you while you and your
                          spouse ("joint annuitant") are alive. 100% Survivor
                          Annuity: The amount of the monthly annuity payment to
                          the survivor is the same as the annuity payment made
                          while you and your joint annuitant are alive. 50%
                          Survivor Annuity: The amount of the monthly annuity
                          payment to the survivor - whether the survivor is you
                          or your spouse - is reduced by half (50%) of the
                          annuity payment made while both you and your spouse
                          are alive.
                        </p>
                      </Col>
                      <Col md={4}>
                        <div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              100% Survivorship{" "}
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              Cash Refund
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square text-white px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3">
                              Emergency Saving (10%)
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square text-white px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3">
                              2 Year Maximize**
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square text-white px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3">
                              Income Bonus
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="Tsp-red-box text-flex mt-3">
                    <Row className="display-text">
                      <Col md={6}>
                        <div
                          className="display-icon"
                          onClick={() =>
                            handleStateByField(
                              "lifeAnnuity",
                              "tsp_opt_joint_life_annuity_private_sector"
                            )
                          }
                        >
                          {formData["lifeAnnuity"] ==
                          "tsp_opt_joint_life_annuity_private_sector" ? (
                            <i className="fa-solid fa-square-check px-2 fa-lg"></i>
                          ) : (
                            <i
                              className={`fa-solid fa-square px-2 fa-lg ${
                                formData["lifeAnnuity"] && "icon-gray"
                              }`}
                            ></i>
                          )}
                          Private Sector
                        </div>
                      </Col>{" "}
                      <Col md={6}>
                        Monthly Payment:{" "}
                        <span className="tsp-withdraw-value">
                          {" "}
                          $
                          {monthlyPayment && monthlyPayment["JLA PS"]
                            ? monthlyPayment["JLA PS"]
                            : "N/A"}
                        </span>{" "}
                      </Col>
                    </Row>
                  </div>
                  <div className="Tsp-sub-red-box">
                    <Row>
                      <Col md={2}>
                        <Image className="Tsp-img2" src={Images.tspImage4} />
                      </Col>
                      <Col md={6}>
                        {" "}
                        <p className="Tsp-Text">
                          The{" "}
                          <span className="Tsp-bold">
                            Private Sector Annuity
                          </span>{" "}
                          functions the same as the Joint Life Annuity through
                          METLIFE, however has a few more features that most
                          federal employees need.
                        </p>
                        <p className="Tsp-Text">
                          <span className="Tsp-bold">
                            Emergency Savings Access{" "}
                          </span>
                          offers a 10% penalty free withdrawal option to people
                          that need a little extra cash. This feature is
                          available each year, if needed.
                        </p>
                      </Col>
                      <Col md={4}>
                        <div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              100% Survivorship{" "}
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              Cash Refund
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              Emergency Saving Access
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              2 Year Maximize"
                            </p>
                          </div>
                          <div className="icon-text">
                            <i className="fa-solid fa-square-check text-success px-2 fa-lg"></i>
                            <p className="Tsp-single-Ann-p-col3 text-black">
                              Income Bonus
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="Tsp-private-sector-text">
                          The{" "}
                          <span className="Tsp-bold">
                            Enhanced Death Benefit
                          </span>{" "}
                          feature offers an additional Death Benefit feature
                          greater than the initial premium amount. Assuming no
                          withdrawals were taken.
                        </div>
                        <div className="Tsp-private-sector-text mt-2">
                          <span className="Tsp-bold">Income Bonus</span> is a
                          fixed rate of return credited to your income benefit
                          annually. The amount of income bonus can vary between
                          3% and 30% depending on the strategy selected
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}
          </div>
          {!formData["lifeAnnuity"] && isSubmitted && (
            <p className="red text-center mt-4">
              Select at least one item to proceed.
            </p>
          )}
          <div className="">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default TspOptionForm;
