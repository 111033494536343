import React, { useEffect, useState } from "react";
import { Card, FloatingLabel, Form } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import BenefitService from "../../../services/BenefitService";

const EmailReport = ({ validated, submit }) => {
  const { plannerData, onChangeHandler, handleOnchangeCheckbox } =
    usePlannerContext();

  const [error, setError] = useState(false);
  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
      console.log(formData);
    }
  }, [plannerData]);
  const handleSubmit = async (event) => {
    setError("");
    event.preventDefault();
    event.stopPropagation();

    if (
      formData["finalCheckToSendPdf"] &&
      formData["finalCheckToSendPdf"] == "1" &&
      formData["toEmail"]
    ) {
      const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!emailReg.test(formData["toEmail"])) {
        setError("Please enter a valid email address.");
        return;
      }
      const payload = {
        id: formData["id"],
        profileId: formData["profileid"],
        toEmail: formData["toEmail"],
        attach_summary_report:
          formData["attach_summary_report"] == null
            ? "1"
            : formData["attach_summary_report"],
        attach_beneficiary_changes_forms:
          formData["attach_beneficiary_changes_forms"],
        attach_budget_worksheet: formData["attach_budget_worksheet"],
        attach_follow_fb_sms:
          formData["attach_follow_fb_sms"] == null
            ? "1"
            : formData["attach_follow_fb_sms"],
      };

      BenefitService.sendEmail(payload);
    }

    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="email-report-section">
        <Form
          className="subform"
          name="emailReportForm"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <div className="qs-box single-screen">
            <h1 className="question">Reports &amp; Documents</h1>
            <div className="input-div">
              <label className="input-label">
                <input
                  type="checkbox"
                  className="label-check-fa allow_email_correspondence report-section-input"
                  name="finalCheckToSendPdf"
                  checked={
                    formData["finalCheckToSendPdf"] === "1" ? true : false
                  }
                  onChange={(event) => {
                    handleOnchangeCheckbox(
                      event,
                      event.target.checked ? "1" : "0",
                      setformData
                    );
                  }}
                />
                <span>
                  Check to allow email correspondence and receive a summary
                  report of your planner.{" "}
                  <a
                    className="email-report-a"
                    target="_blank"
                    href="https://myfedbenefitshelp.com/privacy/ "
                  >
                    Privacy policy
                  </a>
                  .
                </span>
              </label>
            </div>

            {formData.finalCheckToSendPdf == "1" && (
              <div className="email-area">
                <div className="col-md-12">
                  <div className="mt-2 col-md-6 col-md-offset-3 email-report-floating-label">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email:"
                      className="mb-2 email-report-floating-placeholder required-input"
                    >
                      <Form.Control
                        type="email"
                        name="toEmail"
                        className="tsp-2-textbox"
                        placeholder="Age To Begin"
                        value={formData.toEmail}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        isInvalid={validated && !formData.toEmail}
                        autoComplete="off"
                        required
                      />
                    </FloatingLabel>
                    {error && <p className="red fw-bold">{error}</p>}
                  </div>
                </div>
                <div className="attach-email-wrapper">
                  <p className="attach-email-p">Attachments to be Emailed:</p>
                  <ul className="attach-email-ul">
                    <li>
                      <label className="attach-email-label">
                        <input
                          type="checkbox"
                          className="label-check-fa attach-email-input"
                          checked={
                            formData.attach_budget_worksheet == "1"
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            handleOnchangeCheckbox(
                              e,
                              e.target.checked ? "1" : "0",
                              setformData
                            )
                          }
                          name="attach_budget_worksheet"
                          autoComplete="off"
                        />{" "}
                        Budget Worksheet
                      </label>
                    </li>
                    <li>
                      <label className="attach-email-label">
                        <input
                          type="checkbox"
                          className="label-check-fa attach-email-input"
                          checked={
                            formData.attach_beneficiary_changes_forms == "1"
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            handleOnchangeCheckbox(
                              e,
                              e.target.checked ? "1" : "0",
                              setformData
                            )
                          }
                          name="attach_beneficiary_changes_forms"
                          autoComplete="off"
                        />{" "}
                        Beneficiary Changes Forms (TSP and FEGLI)
                      </label>
                    </li>
                    <li>
                      <label className="attach-email-label">
                        <input
                          type="checkbox"
                          name="attach_summary_report"
                          className="label-check-fa attach-email-input"
                          checked={
                            formData.attach_summary_report == "1" ||
                            formData.attach_summary_report == null
                              ? true
                              : false
                          }
                          onChange={(event) => {
                            handleOnchangeCheckbox(
                              event,
                              event.target.checked ? "1" : "0",
                              setformData
                            );
                          }}
                        />
                        <span>Summary Report</span>
                      </label>
                    </li>
                  </ul>
                  <p className="attach-email-p">Stay Informed:</p>
                  <ul className="attach-email-ul">
                    <li>
                      <label className="attach-email-label">
                        <input
                          type="checkbox"
                          name="attach_follow_fb_sms"
                          className="label-check-fa attach-email-input"
                          checked={
                            formData.attach_follow_fb_sms == "1" ||
                            formData.attach_follow_fb_sms == null
                              ? true
                              : false
                          }
                          onChange={(event) => {
                            handleOnchangeCheckbox(
                              event,
                              event.target.checked ? "1" : "0",
                              setformData
                            );
                          }}
                        />
                        <span>
                          Receive an SMS to follow us on Facebook{" "}
                          <i class="fa fa-thumbs-o-up"></i>
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
                {validated && (
                  <div className="red-text text-center mt-2 mb-2">
                    *Complete required fields to continue
                  </div>
                )}
                <div className="complete-planner-wrapper">
                  <button
                    className="btn-next btn question-next"
                    id="emailSummaryReport"
                    type="submit"
                  >
                    Complete Planner
                  </button>
                </div>
              </div>
            )}

            {formData.finalCheckToSendPdf !== "1" && (
              <div className="complete-planner-wrapper">
                <button
                  className="btn-next btn question-next"
                  id="emailSummaryReport"
                  type="submit"
                >
                  Complete Planner
                </button>
              </div>
            )}
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default EmailReport;
