import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Pay from "./pay.helper";
import Pay2 from "./pay-s2.helper";
import Fers from "./fers.helper";
import Fers2 from "./fers-s2.helper";
import Welcome from "./welcome.helper";
import Welcome2 from "./welcome2.helper";
import Fegli from "./fegli.helper";
import Tsp from "./tsp.helper";
import Ss from "./ss.helper";
import Es from "./es.helper";
import Gap from "./gap.helper";
import Summary from "./summary.helper";
import Benefits from "./benefits.helper";
import "./planner.scss";

const Guide = () => {
  const [page, setPage] = useState("");

  const location = useLocation();

  useEffect(() => {
    setPage("");
    let path = location && location.pathname && location.pathname.split("/");
    let pageName = path && path[path.length - 1];

    if (pageName) {
      setPage(pageName);
    }
  }, []);

  return (
    <div className="planner-container">
      <div style={{ width: "100%", minHeight: "93vh" }}>
        <header className="header planner-title">
          <div>
            <h1 className="planner-title">
              Agent Compliance & Assistance Screen
            </h1>
          </div>
        </header>
        <div>{page == "pay" && <Pay />}</div>
        <div>{page == "pay-s2" && <Pay2 />}</div>
        <div>{page == "welcome" && <Welcome />}</div>
        <div>{page == "welcome2" && <Welcome2 />}</div>
        <div>{page == "fegli" && <Fegli />}</div>
        <div>{page == "fers" && <Fers />}</div>
        <div>{page == "fers-s2" && <Fers2 />}</div>
        <div>{page == "tsp" && <Tsp />}</div>
        <div>{page == "ss" && <Ss />}</div>
        <div>{page == "es" && <Es />}</div>
        <div>{page == "gap" && <Gap />}</div>
        <div>{page == "summary" && <Summary />}</div>
        <div>{page == "benefits" && <Benefits />}</div>
        <div>
          {page == "retirement" && (
            <div className="article-section">
              <div className="container">
                <h3>retirement</h3>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "retirement-s2" && (
            <div className="article-section">
              <div className="container">
                <h3>retirement-s2</h3>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "more-about-you" && (
            <div className="article-section">
              <div className="container">
                <h3>more-about-you</h3>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "more-about-you-s2" && (
            <div className="article-section">
              <div className="container">
                <h3>more-about-you-s2</h3>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "fers-annuity" && (
            <div className="article-section">
              <div className="container">
                <h3>fers-annuity</h3>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "tsp-ret" && (
            <div className="article-section">
              <div className="container">
                <h3>tsp-ret</h3>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "pensions-and-income" && (
            <div className="article-section">
              <div className="container">
                <h3>pensions-and-income</h3>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "expenses" && (
            <div className="article-section">
              <div className="container">
                <h3>expenses</h3>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "es-ret" && (
            <div className="article-section">
              <div className="container">
                <h3>es-ret</h3>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "tsp-options" && (
            <div className="article-section">
              <div className="container">
                <h3>tsp-options</h3>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "conclusion" && (
            <div className="article-section">
              <div className="container">
                <h3>conclusion</h3>
              </div>
            </div>
          )}
        </div>

        {page == "client-survey" && (
          <div className="article-section">
            <div className="container">
              <ol start="76">
                <li>
                  The next step is to complete a review on me.  My Supervisor
                  receives a copy of these reviews, so I could really use your
                  help….(
                  <em>go through each question, collecting their response</em>)
                </li>
                <br></br>
                <li>
                  We've covered a lot of valuable information today, some if
                  which affects you today, and some will effect you at
                  Retirement.  It is very important that each and every Federal
                  Employee get a personal, comprehensive review annually.  In
                  fact, it's your right to an annual review. {" "}
                </li>
                <br></br>
                <li>
                  Unfortunately, many Federal Employees fall through the cracks,
                  and don't take the time to get a review, and gain a better
                  understanding of their Benefits.  At Retirement, it's to late
                  to make the necessary changes to be better prepared.  My job
                  is to work with each and every Federal Employee, and ensure
                  they understand ALL of their Benefits.  Name the Top 3(
                  <em>you can always collect more than 3!</em>)  people that you
                  know need help at your Station, and I will move them up my
                  list.  (<em>Complete the Referral Portion of the BPG</em>).
                </li>
              </ol>
              <p>
                <strong>
                  <em>Press </em>
                </strong>
                <strong>
                  <em>
                    <span className="green">CONTINUE</span> and move into the
                    Reports &amp; Documents portion of the BPG
                  </em>
                </strong>
              </p>
            </div>
          </div>
        )}
        {page == "protection" && (
          <div className="article-section">
            <div className="container">
              <p>
                <strong>
                  <u>SECTION VI</u> - Wrap-up
                </strong>
                <br></br>
                <strong>Protection and Roth IRA</strong>

                <em>
                  Complete the page, filling-in all the necessary information:
                  If a sale was completed, select the Life, Product, input the
                  LIFE Premium, Side-Fund Premium (Roth IRA), etc.
                </em>
              </p>
              <p>
                <strong>
                  <em>Press </em>
                </strong>
                <strong>
                  <em>
                    <span className="green">CONTINUE</span> and move into the
                    Client Survey portion of the BPG
                  </em>
                </strong>
              </p>
            </div>
          </div>
        )}
        {page == "email-report" && (
          <div className="article-section">
            <div className="container">
              <ol start="79" type="1">
                <li>
                  We have on final item to cover.  In order to send you your
                  completed Benefits Planner, I need your email address and
                  permission to send you email correspondence (
                  <em>
                    check the box to allow email correspondence, if the employee
                    agrees, then input their email address)
                  </em>
                  .  I will include the additional items of importance that we
                  discussed: Budget Worksheet, Beneficiary Change forms for the
                  TSP and FEGLI, and the Summary Report (
                  <em>
                    select which items you need to send the client, and the BPG
                    will automatically attach them to the email
                  </em>
                  ). {" "}
                </li>
                <br></br>
                <li>
                  I want to make sure you stay informed on all topics that
                  effect your Benefits and Retirement.  By clicking this box
                  (Receive an SMS (text) to follow us on Facebook), you will
                  stay up to date on important topics that effect your Benefits
                  and Retirement.  Make sure you click the link and FOLLOW the
                  MyFEDBenefits Help page on Facebook. You will never receive
                  spam, only important information that YOU need to know. {" "}
                </li>
              </ol>
              <p>
                <strong>
                  <em>
                    Press <span className="green">COMPLETE PLANNER</span> and it
                    will complete the BPG
                  </em>
                </strong>
                .
              </p>
            </div>
          </div>
        )}

        {page == "congrats" && (
          <h1 className="green article-section">
            {" "}
            Planner Completed! Great Work!
          </h1>
        )}
      </div>
      <div style={{ width: "100%", background: "#333", zIndex: 1 }}>
        <p
          className="copy"
          style={{
            color: "#888",
            fontSize: "14px",
            margin: 0,
            lineHeight: "1.6em",
            padding: "7px",
            textAlign: "center",
          }}
        >
          © Copyright MyAgentCRM. All rights reserved.{" "}
          <a
            style={{ color: "#337ab7", TextDecoder: "none" }}
            href="https://myagentcrm.com/privacy/"
            itemprop="item"
          >
            <span itemprop="name">Privacy Policy</span>
          </a>{" "}
          |{" "}
          <a
            style={{ color: "#337ab7", TextDecoder: "none" }}
            href="https://myagentcrm.com/terms/"
            itemprop="item"
          >
            <span itemprop="name">Terms of Use</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Guide;
