import react, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";

const RainyDayFund = ({
  submit,

  validated,
}) => {
  const { plannerData, handleOnchangeCheckbox, setOnChangeStatus } =
    usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);

  const handleCheckbox = (value) => {
    setOnChangeStatus(true);
    let payload =
      formData.bpg_ret_f5 && Array.isArray(formData.bpg_ret_f5)
        ? formData.bpg_ret_f5
        : [];
    if (payload && payload.includes(value)) {
      payload = payload.filter((val) => val !== value);
    } else {
      payload.push(value);
    }
    handleState("bpg_ret_f5", payload);
  };

  const handleState = (name, value) => {
    setformData({
      ...formData,
      [name]: value,
    });
  };

  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="rainyDayFund">
        <Form
          name="esForm"
          validated={validated}
          onSubmit={submitFormdata}
          className="employee-form"
        >
          <div className="text-center">
            <div className="head-title">RAINY DAY FUND</div>
          </div>

          <div className="small-rainy-text">
            <span className="upperCaseTitle">
              Rainy Day Funds vs Emergency Savings{" "}
            </span>
            The term "Emergency Fund" refers to a savings account equal to at
            least three to six months of living expenses in case of an
            unexpected financial emergency, such as major house repairs or
            sudden illness. This account should be relatively liquid and used
            for large expenses that are often unforeseen. Emergency Savings are
            much larger and usually the end result of a successful{" "}
            <b>Rainy Day Fund</b>.
          </div>
          <div className=" mt-3">
            <div className="panel-heading">
              <i className="fa-solid fa-star start-icon"></i>
              MFB Tip: Why Do You Need A Rainy Day Fund
            </div>
            <div className="small-rainy-text">
              <span className="upperCaseTitle">Rainy day funds </span> may sound
              similar to Emergency Savings, but they’re intended to pay for the
              occasional, more frequent smaller expenses. For example, let’s say
              your refrigerator suddenly stops working, and you need to replace
              it, or your expenses related to children’s sports, summer camp,
              etc.
            </div>
          </div>
          <div className=" mt-3">
            <div className="panel-heading">
              <i className="fa-solid fa-star start-icon"></i>
              MFB Tip: Credit Card Usage
            </div>
            <div className="small-rainy-text">
              <span className="upperCaseTitle">Remember, </span>
              when you pay interest, you are losing money. But when you earn
              interest, your money is making more money all by itself.
            </div>
          </div>
          <div className=" mt-3">
            <div className="panel-heading">
              <i className="fa-solid fa-star start-icon"></i>
              MFB Tip: Complete A Budget Workbook
            </div>
            <div className="small-rainy-text">
              <span className="upperCaseTitle">A budget </span>
              is a vital tool for changing the way you handle your money. When
              you are budgeting, you understand where your money is coming from
              and are purposeful about where you spend it. You are making your
              money do what you want it to do, rather than spending without a
              plan.
            </div>
            <div className="mt-4 mb-4">
              <b>
                The goal of budgeting is to always spend less than you earn.
              </b>
            </div>
          </div>
          <div>
            <div className="fund-analysis-header">RAINY DAY FUND ANALYSIS</div>
            <div className="fund-analysis-body">
              <div>
                <div className="row">
                  <div className="col-md-8 col-xs-8 fund-analysis-text">
                    <div>Can you see the benefit of a Rainy Day Fund?</div>
                  </div>
                  <div className="col-md-4 col-xs-4 fund-analysis-text">
                    <div className="mx-2 d-flex">
                      <div className="mx-2 ">
                        <input
                          id="bpg_ret_f1Yes"
                          type="radio"
                          name="bpg_ret_f1"
                          className={`mt-3`}
                          checked={formData.bpg_ret_f1 == "Yes"}
                          onChange={(event) => {
                            handleOnchangeCheckbox(event, "Yes", setformData);
                          }}
                        />{" "}
                        <label htmlFor="bpg_ret_f1Yes">Yes</label>
                      </div>
                      <div className="mx-2 ">
                        <input
                          id="bpg_ret_f1No"
                          type="radio"
                          name="bpg_ret_f1"
                          className={`mt-3`}
                          checked={formData.bpg_ret_f1 == "No"}
                          onChange={(event) => {
                            handleOnchangeCheckbox(event, "No", setformData);
                          }}
                        />{" "}
                        <label htmlFor="bpg_ret_f1No">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 col-xs-8 fund-analysis-text">
                    <div>Do you have a Rainy Day Fund?</div>
                  </div>
                  <div className="col-md-4 col-xs-4 fund-analysis-text">
                    <div className="mx-2 d-flex">
                      <div className="mx-2 ">
                        <input
                          id="bpg_ret_f2Yes"
                          type="radio"
                          name="bpg_ret_f2"
                          className={`mt-3`}
                          checked={formData.bpg_ret_f2 == "Yes"}
                          onChange={(event) => {
                            handleOnchangeCheckbox(event, "Yes", setformData);
                          }}
                        />{" "}
                        <label htmlFor="bpg_ret_f2Yes">Yes</label>
                      </div>
                      <div className="mx-2 ">
                        <input
                          id="bpg_ret_f2No"
                          type="radio"
                          name="bpg_ret_f2"
                          className={`mt-3`}
                          checked={formData.bpg_ret_f2 == "No"}
                          onChange={(event) => {
                            handleOnchangeCheckbox(event, "No", setformData);
                          }}
                        />{" "}
                        <label htmlFor="bpg_ret_f2No">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 col-xs-8 fund-analysis-text">
                    <div>Do you have a budget to track expenses?</div>
                  </div>
                  <div className="col-md-4 col-xs-4 fund-analysis-text">
                    <div className="mx-2 d-flex">
                      <div className="mx-2">
                        <input
                          id="bpg_ret_f3Yes"
                          type="radio"
                          name="bpg_ret_f4"
                          className={`mt-3`}
                          checked={formData.bpg_ret_f4 == "Yes"}
                          onChange={(event) => {
                            handleOnchangeCheckbox(event, "Yes", setformData);
                          }}
                        />{" "}
                        <label htmlFor="bpg_ret_f3Yes">Yes</label>
                      </div>
                      <div className="mx-2">
                        <input
                          id="bpg_ret_f3No"
                          type="radio"
                          name="bpg_ret_f4"
                          className={`mt-3`}
                          checked={formData.bpg_ret_f4 == "No"}
                          onChange={(event) => {
                            handleOnchangeCheckbox(event, "No", setformData);
                          }}
                        />{" "}
                        <label htmlFor="bpg_ret_f3No">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-xs-12 fund-analysis-text">
                    <div>
                      What areas would you like help with most (SELECT ALL THAT
                      APPLY):
                    </div>
                  </div>
                  <div className="col-md-12 col-xs-12 fund-analysis-text">
                    <div className="mx-5 d-flex justify-content-between">
                      <div className="mx-2">
                        <label>
                          <input
                            type="checkbox"
                            name="Budgeting"
                            onChange={(event) => {
                              handleCheckbox(event.target.name);
                            }}
                            checked={
                              formData.bpg_ret_f5 &&
                              formData.bpg_ret_f5.includes("Budgeting")
                            }
                          />{" "}
                          Budgeting
                        </label>
                      </div>
                      <div className="mx-2">
                        <label>
                          <input
                            type="checkbox"
                            name="Saving Money"
                            onChange={(event) => {
                              handleCheckbox(event.target.name);
                            }}
                            checked={
                              formData.bpg_ret_f5 &&
                              formData.bpg_ret_f5.includes("Saving Money")
                            }
                          />{" "}
                          Saving Money
                        </label>
                      </div>
                      <div className="mx-2">
                        <label>
                          <input
                            type="checkbox"
                            name="Reducing Debt"
                            onChange={(event) => {
                              handleCheckbox(event.target.name);
                            }}
                            checked={
                              formData.bpg_ret_f5 &&
                              formData.bpg_ret_f5.includes("Reducing Debt")
                            }
                          />{" "}
                          Reducing Debt
                        </label>
                      </div>
                      <div className="mx-2">
                        <label>
                          <input
                            type="checkbox"
                            name="Rainy Day Fund"
                            onChange={(event) => {
                              handleCheckbox(event.target.name);
                            }}
                            checked={
                              formData.bpg_ret_f5 &&
                              formData.bpg_ret_f5.includes("Rainy Day Fund")
                            }
                          />{" "}
                          Rainy Day Fund
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};
export default RainyDayFund;
