import React from "react";

const Gap = () => {
  return (
    <div className="article-section">
      <div className="container">
        <h3>
          Default screen content. This screen has no content for the article.
        </h3>
      </div>
    </div>
  );
};

export default Gap;
