import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Table } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import {
  convertValue,
  formatCalculatedValue,
  formatValue,
  get_c4_code,
  get_cost_of_option_a,
  get_cost_of_option_b,
  get_cost_of_option_c,
  roundUpToNextThousand,
} from "../../../services/helperService";

const PaystubForm2 = ({ validated, submit }) => {
  const {
    plannerData,
    onChangeHandler,
    handleOnchangeCheckbox,
    handleBlur,
    handleKeyPress,
    setOnChangeStatus,
    handleFocus,
  } = usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      let payload = plannerData;
      let cost_basic_life = plannerData["cost_basic_life"];
      if (payload["lite_pay_agency"] == "USPS") {
        cost_basic_life = "0.00";
      }

      if (payload && payload["fegli_line9"]) {
        if (payload["lite_pay_agency"] == "Other") {
          payload["pay_s2_15"] = "";

          if (payload && payload["checkboxBasicLife"] == "1") {
            let basePay = convertValue(payload["pay_s2_1"]);
            basePay = basePay + (1000 - (basePay % 1000));
            let cost_basic_life = basePay + 2000;
            cost_basic_life = (cost_basic_life / 1000) * 0.16;
            payload["cost_basic_life"] = cost_basic_life;
          }
        }
      }

      if (payload && payload["fegli_line9"]) {
        let pay = payload["pay_s2_1"];

        let age = 0;
        const validateDate = moment(payload["dob"]);
        if (validateDate && validateDate.isValid()) {
          age = moment().diff(validateDate, "years", true);
        }

        let line2 = "";
        line2 = convertValue(pay);
        line2 = line2 && roundUpToNextThousand(line2);
        line2 = convertValue(line2);
        var costOfOptionB = "";
        if (line2) {
          costOfOptionB = get_cost_of_option_b(
            age,
            line2 * parseInt(payload["fegli_line9"])
          );
        }
        costOfOptionB = (costOfOptionB * 12) / 26;
        payload["fegli_cost_option_b"] = costOfOptionB
          ? costOfOptionB.toFixed(2)
          : "0.00";
      } else {
        payload["fegli_cost_option_b"] = "0.00";
      }

      payload = {
        ...payload,
        pay_s2_tsp_r_percent:
          payload["pay_s2_100"] == "%" ? payload["pay_s2_tsp_r_percent"] : "",
        pay_s2_tsp_percent:
          payload["pay_s2_25"] == "%" ? payload["pay_s2_tsp_percent"] : "",
        cost_basic_life,
      };

      setformData(payload);
    }

    console.log(plannerData);
  }, [plannerData]);
  const submitFormdata = (event) => {
    submit(event, formData);
  };

  const handlePaystubCheckbox = (event, value) => {
    setOnChangeStatus(true);
    event.persist();

    const { name } = event.target;
    const payload = {};
    if (value == "0") {
      if (name == "checkboxOptionA") {
        payload["fegli_cost_option_a"] = "0.00";
      }
      if (name == "checkboxBasicLife") {
        payload["cost_basic_life"] = "0.00";
      }
    } else {
      // if (formData["lite_pay_agency"] !== "USPS") {
      if (name == "checkboxBasicLife") {
        let basePay = convertValue(formData["pay_s2_1"]);
        basePay = basePay + (1000 - (basePay % 1000));
        let cost_basic_life = basePay + 2000;
        cost_basic_life = (cost_basic_life / 1000) * 0.16;
        payload["cost_basic_life"] = cost_basic_life;
        if (formData["lite_pay_agency"] == "USPS") {
          payload["cost_basic_life"] = "0.00";
        }
      }

      if (name == "checkboxOptionA") {
        let age = 0;
        const validateDate = moment(formData["dob"]);
        if (validateDate && validateDate.isValid()) {
          age = moment().diff(validateDate, "years", true);
        }
        var costOfOptionA = get_cost_of_option_a(age);
        // console.log(costOfOptionA)
        // console.log(costOfOptionA);
        // costOfOptionA = (costOfOptionA * 12) / 26;
        // costOfOptionA = costOfOptionA;
        payload["fegli_cost_option_a"] = costOfOptionA
          ? formatCalculatedValue(costOfOptionA)
          : "0.00";
      }
      // }
    }

    setformData({ ...formData, ...payload, [name]: value });
  };

  const handlePaystubSelect = (event) => {
    setOnChangeStatus(true);
    const value = event.target.value;
    const name = event.target.name;
    let payload = { [name]: value };
    let age = 0;
    const validateDate = moment(formData["dob"]);
    if (validateDate && validateDate.isValid()) {
      age = moment().diff(validateDate, "years", true);
    }
    if (value) {
      if (name == "fegli_line9") {
        let line2 = "";
        line2 = convertValue(formData["pay_s2_1"]);

        line2 = line2 && roundUpToNextThousand(line2);
        line2 = convertValue(line2);
        var costOfOptionB = "";
        if (line2) {
          costOfOptionB = get_cost_of_option_b(age, line2 * parseInt(value));
        }
        costOfOptionB = (costOfOptionB * 12) / 26;
        payload["fegli_cost_option_b"] = costOfOptionB
          ? costOfOptionB.toFixed(2)
          : "0.00";
      }

      if (name == "selectOptionCMultiple") {
        var costOfOptionC = get_cost_of_option_c(age, value);
        costOfOptionC = (costOfOptionC * 12) / 26;

        payload["fegli_cost_option_c"] =
          costOfOptionC && costOfOptionC.toFixed(2);
      }
    } else {
      if (name == "fegli_line9") {
        payload["fegli_cost_option_b"] = "0.00";
      }
      if (name == "selectOptionCMultiple") {
        payload["fegli_cost_option_c"] = "0.00";
      }
    }
    setformData({ ...formData, ...payload });
  };

  const handlePayStub = (event) => {
    setOnChangeStatus(true);
    let pay = formData["pay_s2_1"];
    if (event.target.name == "pay_s2_1") {
      pay = event.target.value;
    }
    let fegli = formData["pay_s2_15"];
    if (event.target.name == "pay_s2_15") {
      fegli = event.target.value;
    }
    let age = 0;
    const validateDate = moment(formData["dob"]);
    if (validateDate && validateDate.isValid()) {
      age = moment().diff(validateDate, "years", true);
    }
    const splittedValue = fegli && fegli.split(/[a-z,A-Z]/);
    let alphabet =
      fegli && fegli.match(/[a-zA-Z]/) && fegli.match(/[a-zA-Z]/)[0];
    let options = alphabet ? get_c4_code(alphabet.toUpperCase()) : "";
    let line2 = "";
    line2 = convertValue(pay);
    line2 = line2 && roundUpToNextThousand(line2);
    line2 = convertValue(line2);
    let cost_basic_life = plannerData["cost_basic_life"];
    let payload = {};
    if (options && options.includes("BL")) {
      payload = { ...payload, checkboxBasicLife: "1" };
    } else {
      payload = { ...payload, checkboxBasicLife: "0" };
    }

    if (options && options.includes("A")) {
      payload = { ...payload, checkboxOptionA: "1" };
    } else {
      payload = { ...payload, checkboxOptionA: "0" };
    }

    if (options && options.includes("B-")) {
      const splitOptionB = options.match(/-(\d+)/);
      payload = { ...payload, fegli_line9: parseInt(splitOptionB[1]) };
    } else {
      payload = { ...payload, fegli_line9: "" };
    }

    if (options && options.includes("C")) {
      payload = {
        ...payload,
        selectOptionCMultiple: parseInt(splittedValue[1]),
      };
    } else {
      payload = {
        ...payload,
        selectOptionCMultiple: "",
      };
    }

    if (payload && payload["checkboxOptionA"] == "1") {
      var costOfOptionA = get_cost_of_option_a(age);
      // costOfOptionA = (costOfOptionA * 12) / 26;
      payload["fegli_cost_option_a"] = costOfOptionA
        ? formatCalculatedValue(costOfOptionA)
        : "0.00";
    } else {
      payload["fegli_cost_option_a"] = "0.00";
    }

    if (payload && payload["fegli_line9"]) {
      var costOfOptionB = "";
      if (line2) {
        costOfOptionB = get_cost_of_option_b(
          age,
          line2 * parseInt(payload["fegli_line9"])
        );
      }
      costOfOptionB = (costOfOptionB * 12) / 26;
      payload["fegli_cost_option_b"] = costOfOptionB
        ? costOfOptionB.toFixed(2)
        : "0.00";
    } else {
      payload["fegli_cost_option_b"] = "0.00";
    }
    if (payload && payload["selectOptionCMultiple"]) {
      var costOfOptionC = get_cost_of_option_c(
        age,
        payload["selectOptionCMultiple"]
      );
      costOfOptionC = (costOfOptionC * 12) / 26;

      payload["fegli_cost_option_c"] =
        costOfOptionC && costOfOptionC.toFixed(2);
    } else {
      payload["fegli_cost_option_c"] = "0.00";
    }
    setformData({
      ...formData,
      pay_s2_1: pay,
      high3Avg: formatValue(pay),
      fegli_line1: pay,
      pay_s2_15: fegli ? fegli.toUpperCase() : "",
      fegli_line2: line2,
      cost_basic_life: formatCalculatedValue(cost_basic_life),
      ...payload,
    });

    // }
  };

  return (
    <>
      <Card.Body className="paystub-section2">
        <Form
          id="pay-s2"
          name="paystubForm2"
          noValidate
          validated={validated}
          onSubmit={submitFormdata}
          className="employee-form"
        >
          <div className="text-center">
            <div className="head-title">Paystub Review</div>
          </div>
          <div className="tab-title mt-3">
            <div className="form-title">Employee Information</div>
          </div>
          <div className="subSection employee-section">
            <Row className="p-2">
              <Col md={6}>
                <Row>
                  <Col md={3}>Employee:</Col>
                  <Col className="fw-lighter">
                    {formData.firstname ? formData.firstname : ""}{" "}
                    {formData.lastname ? formData.lastname : ""}
                  </Col>
                </Row>
                <Row>
                  <Col>Finance Number:</Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>Pay Period:</Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>Inclusive Dates:</Col>
                  <Col></Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col>Employee ID:</Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>Pay Location:</Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>Pay Date:</Col>
                  <Col></Col>
                </Row>
              </Col>
              <Col md={6} className="mt-4 payAmounts">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div
                      className="fw-bold "
                      style={{
                        fontSize: "22px",
                        width: "100%",
                      }}
                    >
                      <div>
                        <span className="red">*</span>Net Pay:
                      </div>
                    </div>
                  </div>
                  <div>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_36"
                      className="form-element"
                      value={formData.pay_s2_36}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} className="mt-4 payAmounts">
                <div className="d-flex justify-content-between align-items-center ">
                  <div>
                    <div
                      className="fw-bold "
                      style={{
                        fontSize: "22px",
                        width: "100%",
                      }}
                    >
                      <div className="text-nowrap">
                        <span className="red">*</span>{" "}
                        {formData["lite_pay_agency"] == "Other"
                          ? "Adjusted Basic Pay:"
                          : "Annual Pay:"}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_1"
                      className="form-element"
                      value={formData.pay_s2_1}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={handlePayStub}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="tab-title">
            <div className="form-title">Paid Hours</div>
          </div>
          <div className="subSection">
            <Table className="mx-1 my-3 table-content" bordered responsive="md">
              <colgroup>
                <col span="1" style={{ width: "9%" }} />
                <col span="1" style={{ width: "6%" }} />
                <col span="1" style={{ width: "6%" }} />
                <col span="1" style={{ width: "6%" }} />
                <col span="1" style={{ width: "9%" }} />
                <col span="1" style={{ width: "9%" }} />
                <col span="1" style={{ width: "9%" }} />
                <col span="1" style={{ width: "6%" }} />
                <col span="1" style={{ width: "14%" }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "10%" }} />
              </colgroup>
              <thead>
                <tr className="text-center">
                  <th className="text-nowrap">Pay Period</th>
                  <th className="text-nowrap">Week</th>
                  <th className="text-nowrap">RSC</th>
                  <th className="text-nowrap">Level</th>
                  <th className="text-nowrap">Step</th>
                  <th className="text-nowrap">Des Act</th>
                  <th className="text-nowrap">Salary Rate</th>
                  <th className="text-nowrap">Code</th>
                  <th className="text-nowrap">Description</th>
                  <th className="text-nowrap">Hours</th>
                  <th className="text-nowrap">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-nowrap">WORK HOURS</td>
                  <td className="transperentNumber ">
                    <Form.Control
                      autoComplete="off"
                      className="w-100 text-end"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_working_hours"
                      value={formData.pay_s2_working_hours}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      required
                      isInvalid={validated && !formData.pay_s2_working_hours}
                    />
                  </td>
                  <td className="transperentNumber ">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_working_hours_amount"
                      value={formData.pay_s2_working_hours_amount}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      required
                      isInvalid={
                        validated && !formData.pay_s2_working_hours_amount
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-nowrap">OVERTIME HOURS</td>
                  <td className="transperentNumber ">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_overtime_hours"
                      value={formData.pay_s2_overtime_hours}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      required
                      isInvalid={validated && !formData.pay_s2_overtime_hours}
                    />
                  </td>
                  <td className="transperentNumber ">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_overtime_hours_amount"
                      value={formData.pay_s2_overtime_hours_amount}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      required
                      isInvalid={
                        validated && !formData.pay_s2_overtime_hours_amount
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td colspan="3">
                    <b>
                      <span className="red">*</span>Total Hours Gross Pay:
                    </b>
                  </td>
                  <td className={"highlightNumber"}>
                    {" "}
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_2"
                      value={formData.pay_s2_2}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end required-input"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      isInvalid={validated && !formData.pay_s2_2}
                      required
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="tab-title">
            <div className="form-title">Annual & Sick Leave Information</div>
          </div>
          <div className="subSection">
            <Table className="m-3 table-content" bordered responsive="md">
              <thead>
                <tr>
                  <th colspan="2" className="text-center">
                    Annual & Sick Leave
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="w-75 ">
                    <span className="red">*</span>=Available AL Balance
                  </td>
                  <td className={"highlightNumber"}>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0"
                      name="pay_s2_18"
                      value={formData.pay_s2_18}
                      className="text-end required-input"
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onBlur={(e) => handleBlur(e, false, setformData)}
                      required
                      isInvalid={validated && !formData.pay_s2_18}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="red">*</span>=Current SL Balance
                  </td>
                  <td className={"highlightNumber"}>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0"
                      name="pay_s2_29"
                      value={formData.pay_s2_29}
                      className="text-end  required-input"
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      onBlur={(e) => handleBlur(e, false, setformData)}
                      required
                      isInvalid={validated && !formData.pay_s2_29}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="tab-title">
            <div className="form-title">Deduction</div>
          </div>
          <div className="subSection">
            <Table className="m-3 table-content" bordered responsive="md">
              <colgroup>
                <col span="1" style={{ width: "70%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th
                    style={{ width: "20%" }}
                    className="text-center"
                    rowSpan="2"
                  >
                    Description
                  </th>
                  <th
                    style={{ width: "20%" }}
                    colspan="2"
                    className="text-center"
                  >
                    Amount
                  </th>
                </tr>
                <tr style={{ width: "20%" }}>
                  <th>Pay Period</th>
                  <th>YTD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="red">*</span>Social Security
                  </td>
                  <td className={"highlightNumber"}>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_34"
                      value={formData.pay_s2_34}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end  required-input"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      required
                      isInvalid={validated && !formData.pay_s2_34}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span className="red">*</span>Medicare
                  </td>
                  <td className={"highlightNumber"}>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_13"
                      value={formData.pay_s2_13}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end  required-input"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      required
                      isInvalid={validated && !formData.pay_s2_13}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span className="red">*</span>Federal Tax
                  </td>
                  <td className={"highlightNumber"}>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_5"
                      value={formData.pay_s2_5}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      required
                      isInvalid={validated && !formData.pay_s2_5}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>State Income Tax</td>
                  <td className="transperentNumber text-end d-flex justify-content-end">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_8"
                      value={formData.pay_s2_8}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end d-flex justify-content-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="d-flex align-items-center">
                    <div> Thrift Saving Plan</div>
                    <div className="d-flex align-items-center mx-2">
                      <div className="d-flex mx-2 fw-bold">
                        <div>
                          <input
                            id="thriftSavingcheck"
                            type="checkbox"
                            name="pay_s2_25"
                            checked={
                              formData["pay_s2_25"] == "$" ? true : false
                            }
                            onChange={(event) => {
                              handleOnchangeCheckbox(event, "$", setformData);
                            }}
                          />
                        </div>
                        <label className="mx-1" htmlFor="thriftSavingcheck">
                          Fixed
                        </label>
                      </div>
                      <div className="mx-2 d-flex align-items-center  fw-bold">
                        <div>
                          <input
                            id="thriftSavingcheck"
                            type="checkbox"
                            name="pay_s2_25"
                            checked={
                              formData["pay_s2_25"] == "%" ? true : false
                            }
                            onChange={(event) => {
                              handleOnchangeCheckbox(event, "%", setformData);
                            }}
                          />
                        </div>
                        <div className="mx-2 d-flex align-items-center highlightHalfNumber w-60">
                          {formData["pay_s2_25"] == "%" ? (
                            <Form.Control
                              autoComplete="off"
                              id="thriftSavingcheck2"
                              type="number"
                              placeholder="0"
                              step="1"
                              name="pay_s2_tsp_percent"
                              value={formData.pay_s2_tsp_percent}
                              className="text-end required-input"
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                              isInvalid={
                                validated &&
                                (!formData.pay_s2_tsp_percent ||
                                  formData.pay_s2_tsp_percent == "0")
                              }
                              required
                            />
                          ) : (
                            <Form.Control
                              autoComplete="off"
                              id="thriftSavingcheck2"
                              type="number"
                              placeholder="0"
                              step="1"
                              min="0"
                              name="pay_s2_tsp_percent"
                              value={formData.pay_s2_tsp_percent}
                              className="text-end"
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                            />
                          )}{" "}
                        </div>
                        <div className="mx-1">%</div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="text-end transperentNumber d-flex justify-content-end">
                      <Form.Control
                        autoComplete="off"
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_26"
                        value={formData.pay_s2_26}
                        onBlur={(event) => handleBlur(event, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        className="text-end"
                        onFocus={(event) => handleFocus(event, setformData)}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                      />
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="d-flex align-items-center">
                    <div> Thrift Saving Plan R</div>
                    <div className="d-flex align-items-center mx-2">
                      <div className="d-flex mx-2 fw-bold">
                        <div>
                          <input
                            id="thriftSavingcheck3"
                            type="checkbox"
                            name="pay_s2_100"
                            checked={
                              formData["pay_s2_100"] == "$" ? true : false
                            }
                            onChange={(event) => {
                              handleOnchangeCheckbox(event, "$", setformData);
                            }}
                          />
                        </div>
                        <label className="mx-1" htmlFor="thriftSavingcheck3">
                          Fixed
                        </label>
                      </div>
                      <div className="mx-2 d-flex align-items-center  fw-bold">
                        <div>
                          <input
                            type="checkbox"
                            name="pay_s2_100"
                            value="Custome"
                            checked={formData["pay_s2_100"] == "%"}
                            onChange={(event) => {
                              handleOnchangeCheckbox(event, "%", setformData);
                            }}
                          />
                        </div>

                        <div className="mx-2 d-flex align-items-center highlightHalfNumber w-60">
                          {" "}
                          {formData["pay_s2_100"] == "%" ? (
                            <Form.Control
                              autoComplete="off"
                              id="thriftSavingcheck2"
                              type="number"
                              placeholder="0"
                              step="1"
                              name="pay_s2_tsp_r_percent"
                              value={formData.pay_s2_tsp_r_percent}
                              className="text-end required-input"
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                              isInvalid={
                                validated &&
                                (!formData.pay_s2_tsp_r_percent ||
                                  formData.pay_s2_tsp_r_percent == "0")
                              }
                              required
                            />
                          ) : (
                            <Form.Control
                              autoComplete="off"
                              id="thriftSavingcheck2"
                              type="number"
                              placeholder="0"
                              step="1"
                              min="0"
                              name="pay_s2_tsp_r_percent"
                              value={formData.pay_s2_tsp_r_percent}
                              className="text-end"
                              onChange={(event) =>
                                onChangeHandler(event, setformData)
                              }
                            />
                          )}{" "}
                        </div>
                        <div className="mx-1">%</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text-end transperentNumber d-flex justify-content-end">
                      <Form.Control
                        autoComplete="off"
                        type="text"
                        placeholder="0.00"
                        name="pay_tsp_r_1"
                        value={formData.pay_tsp_r_1}
                        onBlur={(event) => handleBlur(event, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        className="text-end"
                        onFocus={(event) => handleFocus(event, setformData)}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                      />
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center transperentNumber">
                      <div>Retire</div>
                      <div>
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          placeholder="12345"
                          maxlength="5"
                          name="pay_retire"
                          value={formData.pay_retire}
                          className="mx-1 text-start w-60"
                          onChange={(event) =>
                            onChangeHandler(event, setformData)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td className="transperentNumber text-end d-flex justify-content-end">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_retire_amount"
                      value={formData.pay_retire_amount}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                {formData["lite_pay_agency"] == "USPS" && (
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div>FEGLI: IN</div>
                        <div className="mx-2 transperentNumber w-60">
                          <Form.Control
                            autoComplete="off"
                            type="text"
                            placeholder="2Z3"
                            name="pay_s2_15"
                            value={formData.pay_s2_15}
                            onChange={handlePayStub}
                          />
                        </div>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                <tr>
                  <td>
                    <div className="d-flex">
                      <label className="mx-1">Basic Life </label>
                      <div>
                        <input
                          type="checkbox"
                          name="checkboxBasicLife"
                          checked={
                            formData.checkboxBasicLife == "1" ? true : false
                          }
                          onChange={(event) => {
                            handlePaystubCheckbox(
                              event,
                              event.target.checked ? "1" : "0"
                            );
                          }}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="transperentNumber  d-flex justify-content-end">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="cost_basic_life"
                      value={formData.cost_basic_life}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end "
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex">
                      <label className="mx-1" htmlFor="optionACheck">
                        Option A
                      </label>
                      <div>
                        <input
                          id="optionACheck"
                          type="checkbox"
                          name="checkboxOptionA"
                          checked={
                            formData.checkboxOptionA == "1" ? true : false
                          }
                          onChange={(event) => {
                            handlePaystubCheckbox(
                              event,
                              event.target.checked ? "1" : "0"
                            );
                          }}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="transperentNumber text-end d-flex justify-content-end">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="fegli_cost_option_a"
                      value={formData.fegli_cost_option_a}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex">
                      <div className="mx-1">Option B</div>
                      <div className="transperentNumber text-end d-flex justify-content-end">
                        <select
                          className="invisibleSelectBox"
                          name="fegli_line9"
                          value={formData.fegli_line9}
                          onChange={handlePaystubSelect}
                          disabled={
                            formData["pay_s2_15"] &&
                            formData["pay_s2_15"] != "0"
                              ? true
                              : false
                          }
                        >
                          <option value=""></option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="transperentNumber text-end d-flex justify-content-end">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="fegli_cost_option_b"
                      value={formData.fegli_cost_option_b}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex">
                      <div className="mx-1">Option C</div>
                      <div className="transperentNumber text-end d-flex justify-content-end">
                        <select
                          className="invisibleSelectBox"
                          name="selectOptionCMultiple"
                          value={formData.selectOptionCMultiple}
                          disabled={
                            formData["pay_s2_15"] &&
                            formData["pay_s2_15"] != "0"
                              ? true
                              : false
                          }
                          onChange={handlePaystubSelect}
                        >
                          <option value=""></option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="transperentNumber text-end d-flex justify-content-end">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="fegli_cost_option_c"
                      value={formData.fegli_cost_option_c}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>FED-V</td>
                  <td className="transperentNumber text-end d-flex justify-content-end">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_28"
                      value={formData.pay_s2_28}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>FED-D</td>
                  <td
                    className={`text-end d-flex justify-content-end ${
                      formData["pay_s2_30"] ? "transperentNumber" : "greyNumber"
                    }
                        `}
                  >
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_30"
                      value={formData.pay_s2_30 ? formData.pay_s2_30 : ""}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>FEHB</td>
                  <td
                    className={`text-end d-flex justify-content-end ${
                      formData["pay_s2_32"] ? "transperentNumber" : "greyNumber"
                    }
                        `}
                  >
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_32"
                      value={formData.pay_s2_32 ? formData.pay_s2_32 : ""}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Allotment</td>
                  <td
                    className={`text-end d-flex justify-content-end ${
                      formData["pay_s2_20"] ? "transperentNumber" : "greyNumber"
                    }
                        `}
                  >
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_20"
                      value={formData.pay_s2_20 ? formData.pay_s2_20 : ""}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Allotment</td>
                  <td
                    className={`text-end d-flex justify-content-end ${
                      formData["pay_s2_22"] ? "transperentNumber" : "greyNumber"
                    }
                        `}
                  >
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_22"
                      value={formData.pay_s2_22 ? formData.pay_s2_22 : ""}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Allotment</td>
                  <td
                    className={`text-end d-flex justify-content-end  ${
                      formData["pay_s2_24"] ? "transperentNumber" : "greyNumber"
                    }
                        `}
                  >
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_24"
                      value={formData.pay_s2_24 ? formData.pay_s2_24 : ""}
                      onBlur={(event) => handleBlur(event, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className="text-end"
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>Net Pay (Net To Bank): </b>
                  </td>
                  <td className="transperentNumber text-end d-flex justify-content-end">
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="0.00"
                      name="pay_s2_36"
                      value={formData.pay_s2_36}
                      className="text-end fw-bold w-100 remove-disableBackgeound"
                      disabled
                      isInvalid={validated && !formData.pay_s2_36}
                    />
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
          {validated && (
            <div className="red-text text-center mt-2">
              *Complete required fields to continue
            </div>
          )}
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default PaystubForm2;
