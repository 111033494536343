import React from "react";
import { Card, Form, Image } from "react-bootstrap";
import Images from "../../CRMs/crm2.0";

const Welcome = ({ submit }) => {
  return (
    <Form onSubmit={submit}>
      <Card.Body className="retirement subform">
        <div className="qs-box single-screen">
          <div className="text-center">
            <div className="welcome-title">
              Federal Employee{" "}
              <span className="text-blue">Benefits Planning Guide</span>
            </div>
          </div>

          <div className="text-center">
            <Image src={Images.myfedbenefitsLogo} />
          </div>
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                Start
              </button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Form>
  );
};

export default Welcome;
