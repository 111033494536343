import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import BenefitService from "../../services/BenefitService";

const Report = () => {
  const [loader, setLoader] = useState("");
  const [pdf, setPdf] = useState(null);

  let { id } = useParams();

  useEffect(() => {
    (async function () {
      try {
        setLoader(true);

        if (id) {
          const payload = window.atob(id);
          const pdfDetails = await BenefitService.plannerReport(payload);

          if (pdfDetails && pdfDetails["data"]) {
            setPdf(pdfDetails["data"]);
          }
        }
        setLoader(false);
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    })();
  }, []);

  return (
    <div>
      {loader ? (
        <div className="loader-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div>
          {id && pdf ? (
            <iframe
              src={pdf}
              frameborder="0"
              className="pdf-viewer"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          ) : (
            <div>
              <h1 className="red text-center">Not be able to load pdf</h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Report;
