import GenericService from "./GenericService";

class SalesService extends GenericService {
  getSales = (id) => this.get(`getSales/${id}`);
  deleteSales = (id) => this.post(`deleteSale/${id}`);
  addSales = (data) => this.post("createSale", data);
  updateSale = (data) => this.post("updateSale", data);
}

let salesService = new SalesService();
export default salesService;
