import React from "react";

const Pay = () => {
  return (
    <div className="article-section">
      <div className="container">
        <p>
          <strong>
            <em>
              <u>SECTION II</u> -{" "}
            </em>
          </strong>
          <strong>
            <em>Transition to Paycheck Stub </em>
          </strong>
        </p>
        <p>
          <strong>
            <em>
              You'll need to collect some basic information from the Employee,
              then press <span className="green">CONTINUE</span> on the BPG.
            </em>
          </strong>{" "}
        </p>
      </div>
    </div>
  );
};

export default Pay;
